const languageObject = {
  en: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',
    baq: 'Basque',

    'dashboard.hello': 'Hello',
    'dashboard.lastLoggedIn': 'Last logged in at',

    'deviceList.myUnits': 'My Units',
    'deviceList.newAlarms': 'New alarms',
    'deviceList.pendingAlarms': 'Pending alarms',
    'deviceList.pendingAlarm': 'pending alarm',
    'deviceList.warnings': 'Warnings in the last 24h',
    'deviceList.tileView': 'Tile View',
    'deviceList.mapView': 'Map View',
    'deviceList.viewOnMap': 'VIEW ON MAP',
    'deviceList.viewOnTile': 'TILE VIEW',
    'deviceList.addUnit': 'Add New Unit',
    'deviceList.uploadUnit': 'Upload New Unit',

    'taskList.myTasks': 'My Tasks',
    'taskList.markAsComplete': 'MARK AS COMPLETE',
    'taskList.seeAlltasks': 'See All Tasks',

    'deviceTile.redAlert': 'Red alert',
    'deviceTile.yellowAlert': 'Yellow alert',
    'deviceTile.offline': 'Offline',
    'deviceTile.online': 'Online',
    'deviceTile.newAlarms': 'New Alarms',
    'deviceTile.warnings': 'Warnings in the last 24h',
    'deviceTile.schedule': 'Unit has a schedule',

    'sidebar.organisationHierarchy': 'Organisation Hierarchy',
    'sidebar.home': 'Home',
    'sidebar.units': 'Units',
    'sidebar.tasksChecklists': 'Tasks & Checklists',
    'sidebar.adminControls': 'Admin Controls',
    'sidebar.accountSettings': 'Account Settings',
    'sidebar.help': 'Help',
    'sidebar.logout': 'Log out',
    'sidebar.switchProfiles': 'Switch profiles',
    'sidebar.switchOrganisations': 'Switch organisations',
    'sidebar.parts': 'Parts',

    'organisationHierarchy.organisationHierarchy': 'Organisation Hierarchy',
    'organisationHierarchy.messageSelectOrganisation': 'Use select button to switch organisation',

    'common.yes': 'Yes',
    'common.no': 'No',
    'common.showAll': 'SHOW ALL',
    'common.showLess': 'SHOW LESS',
    'common.search': 'Search',
    'common.export': 'Export',
    'common.name': 'Name',
    'common.description': 'Description',
    'common.change': 'CHANGE',
    'common.manage': 'MANAGE',
    'common.enable': 'enable',
    'common.disable': 'disable',

    'common.requiredParamsMissing': 'Required parameter missing',
    'common.createdSuccessfully': 'Record created successfully',
    'common.updatedSuccessfully': 'Record updated successfully',
    'common.creationFailed': 'Record creation failed',
    'common.updationFailed': 'Record updating failed',
    'common.deletionSuccessfull': 'Record deleted successfully',
    'common.deletionFailed': 'Record deletion failed ',
    'common.duplicateName': 'Duplicate entry found',
    'common.fetchFailed': 'Record fetch failed',
    'common.fetchSuccessfull': 'Record fetch successful',
    'common.isRefered': "Record is being referred at other places, can't be removed",
    'common.configure': 'Configure',
    'common.selectOne': 'Please select one',
    'common.searchBy': 'Search By',
    'common.uploadFailed': 'Upload failed',
    'common.update': 'UPDATE',

    'layout.tagline': 'At Water Source Australia we believe clean drinking water should be available for all.',

    'processFlow.eventLog': 'Event Log',
    'processFlow.allEventsLog': 'View All Event Logs',

    'commandPanel.iotCommands': 'IoT Commands',
    'commandPanel.quickCommands': 'Quick commands',

    'settings.language': 'Language  ',
    'settings.timezone': 'Timezone',

    'layout.noticeBoard': 'NOTICE BOARD',

    'deviceHeader.back': '< BACK TO UNITS',
    'deviceHeader.switchLocalTimezone': 'Switch to local time zone',
    'deviceHeader.switchUnitTimezone': 'Switch to unit time zone',
    'deviceHeader.offline': 'Offline',
    'deviceHeader.online': 'Online',
    'deviceHeader.localTime': 'Local time',
    'deviceHeader.unitTime': 'Unit time',
    'deviceHeader.notAvailable': 'Not Available',

    'tabNavigation.processFlow': 'Process flow',
    'tabNavigation.eventLog': 'Event Log',
    'tabNavigation.dataTrendAnalysis': 'Data trend analysis',
    'tabNavigation.thresholds': 'Thresholds',
    'tabNavigation.reports': 'Reports',
    'tabNavigation.unitConfiguration': 'Unit Configuration',
    'tabNavigation.calibration': 'Calibration',
    'tabNavigation.schedule': 'Schedule',
    'tabNavigation.unitService': 'Unit Service',
    'tabNavigation.summaryData': 'Summary Data',
    'tabNavigation.unitSettings': 'Unit Settings',

    'sequence.diagram': 'Diagram View',
    'sequence.table': 'Table View',

    'toggleSwitch.lableView': 'Switch to label view',

    'sequenceThresholds.thresholds': 'THRESHOLDS',
    'sequenceThresholds.allThresholds': 'All thresholds',

    'sensorTable.realTimeSensor': 'Real time Sensor',
    'sensorTable.ct01': 'Conductivity transmitter (+ temperature)',
    'sensorTable.ft01': 'Post-pump flowrate',
    'sensorTable.ft02': 'UV Flowrate',
    'sensorTable.pt01': 'Post-pump pressure transmitter',
    'sensorTable.pt01-pt02': 'Pressure diff across pre-filters',
    'sensorTable.pt02': 'Pre-membrane pressure transmitter',
    'sensorTable.pt02-pt03': 'Pressure diff across membrane',
    'sensorTable.pt03': 'Post-membrane pressure transmitter',
    'sensorTable.tb01': 'Incoming Turbidity transmitter',
    'sensorTable.tb02': 'Pre-membrane Turbidity transmitter',
    'sensorTable.tb03': 'Post-membrane turbidity transmitter',
    'sensorTable.uvi01': 'UV Intensity',
    'sensorTable.uvt01': 'UV Transmissivity',
    'sensorTable.lt01': 'Tank 1 analogue level transmitter',
    'sensorTable.lt02': 'Tank 2 analogue level transmitter',
    'sensorTable.lsl01': 'Tank 1, low level switch',
    'sensorTable.cs01': 'SBC Current Sensor',
    'sensorTable.cs02': 'System Current Sensor',
    'sensorTable.tk01': 'Tank 1 analogue level transmitter',
    'sensorTable.tk02': 'Tank 2 analogue level transmitter',

    'deviceSchematic.diagramView': 'Hide sensors',
    'deviceSchematic.sensorView': 'Show sensors',

    'command.initialise': 'initialise',
    'command.normalprocessing': 'Normal Processing',
    'command.membraneforwardflush': 'Forward flush',
    'command.membranebackflush': 'Back flush',
    'command.flush': 'Flush',
    'command.tank1drain': 'T1 drain',
    'command.prefilterbackflush': 'Pre-filter back flush',
    'command.dit': 'Direct integrity test',
    'command.run': 'Run',
    'command.wait': 'Wait',
    'command.emergencystop': 'Emergency stop',
    'command.firmwareUsbReset': 'Firmware Usb Reset',
    'command.usbReset': 'Usb Reset',
    'command.transition': 'Transition',
    'command.stop': 'Stop',
    'command.start': 'Start',
    'command.waitforaws': 'Wait for AWS',
    'command.hardwarefault': 'Hardware fault',
    'command.alarm': 'Alarm',
    'command.none': 'None',
    'command.commandfailedtosend': 'Command could not be sent. Try again.',
    'command.commandsuccessfullysent': 'Command successfully sent',
    'command.areyousureyouwanttoactionthecommand': 'Are you sure you want to action the command?',
    'command.ditp': 'Direct integrity test pass',

    'settings.accountSettings': 'Account Settings',
    'accountSettingsTab.settings': 'Settings',
    'accountSettingsTab.profile': 'Profile',
    'accountSettings.generalSettings': 'General Account Settings',
    'accountSettings.resetCacheConfirmation': 'Are you sure you want to reset the cache?',
    'accountSettings.resetCache': 'RESET CACHE',
    'accountSettings.resetCacheNote': 'Please note resetting the cache would also reset all your trend analysis charts on this device',
    'accountSettings.clearCookiesConfirmation': 'Are you sure you want to clear the cookies?',
    'accountSettings.clearCookies': 'CLEAR COOKIES',
    'accountSettings.clearCookiesNote': 'Please note clearing the cookies would log you out of the application',
    'AdminControlsTabs.manageUsers': 'Users',
    'AdminControlsTabs.manageProfiles': 'Profiles',
    'AdminControlsTabs.manageOrganisations': 'Organisations',
    'AdminControls.adminControls': 'Admin Controls',
    'AdminControlsTabs.manageModel': 'Models',
    'AdminControlsTabs.checklistsTasks': 'Checklists & Tasks',
    'AdminControlsTabs.calibrations': 'Calibrations',
    'AdminControlsTabs.globalSettings': 'Global Settings',
    'AdminControlsTabs.notifications': 'Notifications',
    'AdminControls.NoAccess': 'You do not have access to Admin Controls. Please contact your administrator.',
    'AdminControlsTabs.faq': 'FAQ',
    'AdminControlsTabs.releaseNotes': 'Release Notes',

    'profile.alreadyExists': 'A profile with this name already exists',
    'profile.defaultProfileError': 'The default profile cannot be edited!',
    'profile.saveSuccessfull': 'Profile sucessfully saved',
    'profile.unableToSave': 'Profile saving failed',
    'profile.errorInFetching': 'Unable to retrieve the data',
    'profile.invalidData': 'Unable to save. Invalid data format!',
    'profile.fillRequiredData': 'Please fill the required data!',
    'profile.validationNameLength': 'Profile Name must be atleast 2 characters long!',
    'profile.validationName': 'Profile Name is required!',
    'profile.validationDescriptionLength': 'Profile Description is required!',
    'profile.minimumWidget': 'Minumum required profile widget',
    'profile.EnableMFA': 'Multi Factor Authentication (MFA)',

    'settings.settings': 'Settings',
    'settings.profile': 'Profile',
    'settings.appwidgets': 'Application Widgets',
    'settings.addwidget': 'Add Widget',
    'settings.userManagement': 'User Management',
    'settings.applicationusers': 'Application Users',
    'settings.organisation': 'Organisation',

    'profileSettings.personal': 'Personal',
    'profileSettings.name': 'Name',
    'profileSettings.contactNumber': 'Contact Number',
    'profileSettings.emailAddress': 'Username/Email Address',
    'profileSettings.password': 'Password',
    'profileSettings.edit': 'Edit',

    'profile.profiles': 'Profiles',
    'profile.addProfile': 'Add Profile',
    'profile.profileEdit': 'Edit Profile',
    'profile.addNewProfile': 'ADD NEW PROFILE',
    'profile.Edit': 'EDIT',
    'profile.View': 'VIEW',
    'profile.profileName': 'Profile Name',
    'profile.profileDescription': 'Profile Description',
    'profile.profileActive': 'Active',
    'profile.profileSave': 'Save Changes',
    'profile.profileCancel': 'Cancel',
    'profile.selectWidget': 'Select',
    'profile.widgetAssigned': 'Widgets',
    'profile.requiredParamsMissing': 'Missing parameters',
    'profile.fetchFailed': 'Unable to retrieve data',
    'profile.fetchSuccessfull': 'Records fetched successfully',
    'profile.enterProfileName': 'Enter profile name',
    'profile.enterProfileDescription': 'Enter profile Description',
    'profile.search': 'Search',
    'profile.searchclear': 'CLEAR',
    'profile.back': '< BACK TO PROFILES',
    'profile.widgetselection': 'Select the widgets that this profile will have access to.',
    'profile.widgetsCount': 'Widgets',
    'profile.userCount': 'Users',
    'profile.selectPage': 'Select page',
    'profile.selectAllPages': 'Select All Pages',
    'profile.filterBy': 'Filter by',

    'organisation.organisation': 'Organisations',
    'organisation.createdSuccessfully': 'Organisation sucessfully created',
    'organisation.updatedSuccessfully': 'Organisation sucessfully saved',
    'organisation.creationFailed': 'Organisation creation failed',
    'organisation.updationFailed': 'Organisation update failed',
    'organisation.requiredParamsMissing': 'Missing parameters',
    'organisation.fetchFailed': 'Unable to retrieve organisation data',
    'organisation.profilefetchFailed': 'Unable to retrieve organisation profile data',
    'organisation.fetchSuccessfull': 'Records fetched successfully',
    'organisation.users.fetchFailed': 'Unable to retrieve organisation users',
    'organisation.units.fetchFailed': 'Unable to retrieve organisation units',
    'organisation.organisationName': 'Organisation name',
    'organisation.duplicateName': 'An organisation with this name already exists',
    'organisation.location': 'Location',
    'organisation.users': 'Users',
    'organisation.units': 'Units',
    'organisation.units.transferSuccessfull': 'Units were moved sucessfully to the new version',
    'organisation.units.transferFailed': 'Units failed to be moved to the new version',
    'organisation.active': 'Active',
    'organisation.addNewOrganisation': 'Add new organisation',
    'organisation.editOrganisation': 'Edit organisation details',
    'organisation.edit': 'EDIT',
    'organisation.inActiveError': 'Organisation can not be marked as inactive as it may contain units or sub-organisation.',
    'organisationManagement.nameMandatory': 'Organisation name is mandatory field',
    'organisationManagement.parentMandatory': 'Parent group is mandatory field',
    'organisationManagement.defaultProfile': 'Default Profile',
    'organisationManagement.defaultProfileMandatory': 'Default profile is mandatory field',
    'organisationManagement.countryMandatory': 'Location Country is mandatory field',
    'organisationManagement.backToOrganisations': 'BACK TO ORGANISATIONS',
    'organisationManagement.organisationName': 'Organisation name',
    'organisationManagement.organisationLogo': 'Organisation logo',
    'organisationManagement.dragndrop': 'Drag and drop file here',
    'organisationManagement.browse': 'BROWSE FILE',
    'organisationManagement.or': 'or',
    'organisationManagement.acceptedfile': 'Accepted file formats: .jpg, .jpeg, .png, .svg',
    'organisationManagement.organisationLogoType': 'Only files with the following extensions are allowed: .jpg, .jpeg, .png, .svg',
    'organisationManagement.parentGroup': 'Parent group',
    'organisationManagement.location': 'Location',
    'organisationManagement.town': 'Town',
    'organisationManagement.enterTown': 'Enter town',
    'organisationManagement.region': 'Region/Site',
    'organisationManagement.entrrRegion': 'Enter region/site',
    'organisationManagement.country': 'Country',
    'organisationManagement.selectCountry': 'Select country',
    'organisationManagement.postCode': 'Postcode',
    'organisationManagement.enterPostcode': 'Enter postcode',
    'organisationManagement.users': 'Users',
    'organisationManagement.addUsersToOrganisation': 'Add users to your organisation',
    'organisationManagement.units': 'Units',
    'organisationManagement.addUnitsToOrganisation': 'Add units to your organisation',
    'organisationManagement.organisationActive': 'Organisation is active',
    'organisationManagement.saveOrganisation': 'Save Organisation',
    'organisationManagement.userSearch': 'Search',
    'organisationManagement.defaultUnitSupervisor': 'Default Unit Supervisors',
    'organisationManagement.userMandatory': 'Select atleast 1 user for this organisation',
    'organisationManagement.unitManagerId': 'Unit supervising manager is mandatory field',
    'organisationManagement.unitTechnicianId': 'Unit supervising technician is mandatory field',
    'organisationManagement.moveUnits': 'Move Units',
    'organisationManagement.moveUnitsToOrganisation': 'Move Units to organisation model versions',
    'organisationManagement.transferUnitsMandatory': 'Please move at least one unit',
    'organisationManagement.transferOrganisationMandatory': 'Please select organisation',
    'organisationManagement.youCannotMoveUnitsToSameModelVersion': 'You cannot move units to the same model version',
    'organisationManagement.searchInfo': 'Search units by unit name',
    'organisationManagement.transferringOrg': 'Tranfer organisation',
    'organisationManagement.tranferModelVersion': 'Transfer model version',
    'organisationManagement.fromModel': 'Unit Model',

    'modelVersion.createdSuccessfully': 'Model version sucessfully created',
    'modelVersion.updatedSuccessfully': 'Model version sucessfully saved',
    'modelVersion.creationFailed': 'Model version creation failed',
    'modelVersion.updationFailed': 'Model version update failed',
    'modelVersion.requiredParamsMissing': 'Missing parameters',
    'modelVersion.fetchFailed': 'Unable to retrieve Model version data',
    'modelVersion.fetchSuccessfull': 'Records fetched successfully',
    'modelVersion.organisationFetchFailed': 'Organisations fetch failed',
    'modelVersion.duplicateName': 'A version name already exists for this model',
    'modelVersion.organisationFetchSuccessfull': 'Organisation records fetched successfully',
    'modelVersionManagement.selectOrganisation': 'Select Organisation',
    'modelVersionManagement.Organisation': 'Organisation',

    'model.modelFirmwareVersion': 'Model Firmware Versions',
    'model.modelFirmwareVersion.showAll': 'SHOW ALL',
    'model.modelFirmwareVersion.showLess': 'SHOW LESS',

    'modelFirmwareVersion.addNew': 'ADD NEW',
    'modelFirmwareVersion.name': 'Name',
    'modelFirmwareVersion.version': 'Version',
    'modelFirmwareVersion.modelComponentPosition': 'Component',
    'modelFirmwareVersion.publishedDate': 'Published Date',
    'modelFirmwareVersion.publishedBy': 'Published by',
    'modelFirmwareVersion.activeVersion': 'Active Version',
    'modelFirmwareVersion.edit': 'EDIT',
    'modelFirmwareVersion.modelFirmwareVersion': 'Firmware Version',
    'modelFirmwareVersion.pleaseSelectOne': 'Please Select One',
    'modelFirmwareVersion.modelFirmwareVersion': 'Model Firmware Version',
    'modelFirmwareVersion.description': 'Version Description',
    'modelFirmwareVersion.publishFirmwareVersion': 'PUBLISH VERSION',
    'modelFirmwareVersion.saveModelFirmwareVersion': 'SAVE CHANGES',
    'modelFirmwareVersionManagement.cancelModelFirmwareVersion': 'CANCEL',
    'modelFirmwareVersion.modelUnits': 'Model Units',
    'modelFirmwareVersion.createdSuccessfully': 'Model Firmware Version Created Successfully',
    'modelFirmwareVersion.creationFailed': 'Failed to Create Model Firmware Version',
    'modelFirmwareVersion.updatedSuccessfully': 'Model Firmware Version Updated Successfully',
    'modelFirmwareVersion.publishedSuccessfully': 'Model Firmware Version Published Successfully',
    'modelFirmwareVersion.fetchSuccessfull': 'Model Firmware Version Data Fetch Successfull',
    'modelFirmwareVersion.duplicateName': 'Duplicate Model Firmware version Name',
    'modelFirmwareVersion.publisheFailed': 'Model Firmware Version Publish Failed',
    'modelFirmwareVersion.updationFailed': 'Model Firmware Version Updated Failed',
    'modelFirmwareVersion.fetchFailed': 'Model Firmware Version Data Fetch Failed',
    'modelFirmwareVersion.requiredParamsMissing': 'Missing Parameters',
    'modelFirmwareVersion.nameMandatory': 'Version name is a mandatory field',
    'modelFirmwareVersion.versionMandatory': 'Version number is a mandatory field',
    'modelFirmwareVersion.versionInvalid': 'Invalid Version Number! No special characters except .',
    'modelFirmwareVersion.componentPositionMandatory': 'Component is a mandatory field',
    'modelFirmwareVersion.firmwareFiles': 'Firmware Files',

    'calibrationEquation.createdSuccessfully': 'Calibration equation sucessfully created',
    'calibrationEquation.updatedSuccessfully': 'Calibration equation sucessfully saved',
    'calibrationEquation.creationFailed': 'Calibration equation creation failed',
    'calibrationEquation.updationFailed': 'Calibration equation update failed',
    'calibrationEquation.requiredParamsMissing': 'Missing parameters',
    'calibrationEquation.fetchFailed': 'Unable to retrieve Calibration equation data',
    'calibrationEquation.fetchSuccessfull': 'Records fetched successfully',
    'calibrationEquation.duplicateName': 'A Calibration equation name already exists',
    'calibrationEquation.constant.fetchFailed': 'Calibration equation constant fetch failed',
    'calibrationEquation.name': 'Name',
    'calibrationEquation.description': 'Description',
    'calibrationEquation.formula': 'Formula',
    'calibrationEquation.change': 'CHANGE',
    'calibrationEquation.addNew': 'ADD NEW',
    'calibrationEquation.calibrationEquation': 'Calibration Equation',
    'calibrationEquation.headerInfo': 'The following equations are defined and used for the calibration of sensors within the Units.',
    'calibrationEquation.newCalibration': 'New calibration equation configuration',
    'calibrationEquation.backToCalibrations': 'BACK TO CALIBRATIONS',
    'calibrationEquation.cancelCalibrationEquation': 'CANCEL',
    'calibrationEquation.saveCalibrationEquation': 'SAVE CHANGES',
    'calibrationEquation.enterDescription': 'Enter Descriptions',
    'calibrationEquation.active': 'Equation is active',
    'calibrationEquation.nameMandatory': 'Equation name is mandatory field',
    'calibrationEquation.formulaMandatory': 'Formula name is mandatory field',

    'calibrationEquation.constant': 'Calibration Constants',
    'calibrationEquation.constant.name': 'Name',
    'calibrationEquation.constant.description': 'Descriptions',
    'calibrationEquation.constant.change': 'CHANGE',
    'calibrationEquation.constant.addNew': '+ ADD NEW',
    'calibrationEquation.constant.calibrationEquationConstant': 'Enter Descriptions',
    'calibrationEquation.constant.headerInfo': 'Equation is active',
    'calibrationEquation.constant.add': 'ADD',
    'calibrationEquation.constant.update': 'UPDATE',
    'calibrationEquation.constant.cancel': 'CANCEL',
    'calibrationEquation.constant.nameMandatory': 'Constant name is required',
    'calibrationEquation.constant.remove': 'Remove',
    'calibrationEquation.constant.calibrationexists': 'Unable to delete, this constant is still is use in calibrations',

    'calibration.createdSuccessfully': 'Calibration sucessfully created',
    'calibration.updatedSuccessfully': 'Calibration sucessfully saved',
    'calibration.creationFailed': 'Calibration creation failed',
    'calibration.updationFailed': 'Calibration update failed',
    'calibration.requiredParamsMissing': 'Missing parameters',
    'calibration.fetchFailed': 'Unable to retrieve Calibration data',
    'calibration.fetchSuccessfull': 'Records fetched successfully',
    'calibration.constant.fetchFailed': 'Unable to retrieve Calibration data',
    'calibration.result.fetchFailed': 'Unable to retrieve calibration results data',
    'calibration.comment.fetchFailed': 'Unable to retrieve calibration comments data',
    'calibration.comment.requiredParamsMissing': 'Missing parameters',
    'calibration.comment.createdSuccessfully': 'Calibration comments sucessfully added',
    'calibration.comment.creationFailed': 'Unable to add comment',
    'calibration.calibrationComponent': 'Calibration Component',
    'calibration.componentMandatory': 'Calibration component is mandatory',

    'calibration.calibrations': 'Calibrations',
    'calibration.addNew': 'ADD NEW CALIBRATIONS',
    'calibration.sensor': 'Sensor',
    'calibration.status': 'Status',
    'calibration.calibrationDate': 'Calibration Date',
    'calibration.assignee': 'Assignee',
    'calibration.calibrationRequired': 'Calibration Required',
    'calibration.view': 'VIEW',
    'calibration.yes': 'Yes',
    'calibration.no': 'No',

    'calibration.delete': 'DELETE CALIBRATION',
    'calibration.deletionFailed': 'Calibration deletion failed',
    'calibration.deletedSuccessfully': 'Calibration deleted successfully',
    'calibration.sensorRange': 'Sensor Range',
    'calibration.selectSensor': 'Select Sensor',
    'calibration.backToCalibrations': 'BACK',
    'calibration.calibration': 'Calibration',
    'calibration.newCalibration': 'New Calibration',
    'calibration.editCalibration': 'Edit Calibration',
    'calibration.details': 'Details',
    'calibration.unit': 'Unit',
    'calibration.reporter': 'Reporter',
    'calibration.calibrationDatetime': 'Calibration Date Time',
    'calibration.tolerance': 'Tolerance',
    'calibration.low': 'Low',
    'calibration.high': 'High',
    'calibration.unitOfMeasure': 'Unit Of Measure',
    'calibration.results': 'Results',
    'calibration.addResults': '+ ADD NEW',
    'calibration.controlRange': 'Control Range',
    'calibration.desiredResults': 'Desired Results',
    'calibration.preCalResults': 'Pre-Cal Results',
    'calibration.error': 'Error',
    'calibration.postCalResults': 'Post-Cal Results',
    'calibration.preCalError': 'Pre-Cal Error',
    'calibration.postCalError': 'Post-Cal Error',
    'calibration.remove': 'Remove',
    'calibration.calibrationConstants': 'Calibration Constants',
    'calibration.calibrationEquation': 'Calibration equation',
    'calibration.calibrationFormula': 'Calibration formula',
    'calibration.calibrationRequired': 'Calibration required',
    'calibration.calibrationRequired.yes': 'Yes',
    'calibration.calibrationRequired.no': 'No',
    'calibration.calibrationConstantsName': 'Name',
    'calibration.calibrationConstantsDescription': 'Description',
    'calibration.calibrationConstantsValue': 'Value',
    'calibration.comments': 'Comments',
    'calibration.save': 'SAVE CHANGES',
    'calibration.delete.confirmation': 'Are you sure you want to delete this calibration?',
    'calibration.cancel': 'CANCEL',
    'calibration.finalizeNote': 'Please finalize before uploading the calibration',
    'calibration.finalize': 'FINALIZE',
    'calibration.upload': 'UPLOAD',
    'calibration.sensorMandatory': 'Sensor is mandatory',
    'calibration.calibrationDateMandatory': 'Calibration Date is mandatory',
    'calibration.changeCalibration': 'Change',
    'unitComponent.datafetchsucccessfull': 'Records fetched successfully',
    'unitComponent.datafetchfailed': 'Unable to retrieve unit components data',
    'unitComponent.requiredParamsMissing': 'Missing parameters',
    'modelVersion.modelName': 'Model',
    'modelVersion.version': 'Version',
    'modelVersion.description': 'Description',
    'modelVersion.dateCreated': 'Date created',
    'modelVersion.dateModified': 'Date modified',
    'modelVersion.units': 'Units',
    'modelVersion.edit': 'Edit',
    'modelVersion.configure': 'Configure',
    'modelVersion.duplicate': 'Duplicate',
    'modelVersion.viewSummary': 'View Summary',
    'modelVersion.modelVersion': 'Model Version',
    'modelVersion.modelVersionName': 'Model Version Name',
    'modelVersion.addNewModelVersion': 'Add',
    'modelVersion.duplicateModelVersion': 'Duplicate',
    'modelVersion.modelMandatory': 'No model is assigned',
    'modelVersion.nameMandatory': 'Version name is a mandatory field',
    'modelVersion.backToModels': 'BACK TO MODELS',
    'modelVersion.backToModelConfiguration': 'BACK TO MODEL CONFIGURATION',
    'modelVersion.editModelVersion': 'Edit',
    'modelVersionManagement.versionName': 'Version Name',
    'modelVersionManagement.active': 'Version is active',
    'modelVersionManagement.saveModelVersion': 'SAVE CHANGES',
    'modelVersionManagement.cancelModelVersion': 'CANCEL',
    'modelVersionManagement.description': 'Version Description',
    'modelVersionManagement.enterDescription': 'Enter description',
    'modelVersionManagement.ValueMustBeWithinRange': 'Value must be within Min and Max range',
    'modelVersion.configureModelVersion': 'Model Version Configuration:',
    'modelVersion.globalSettings': 'Global Settings',
    'modelVersion.threasholds': 'Thresholds',
    'modelVersion.search': 'Search',
    'modelVersion.addNew': 'Add New',
    'modelVersion.showAll': 'Show All',
    'modelVersion.showLess': 'Show Less',
    'modelVersion.settings': 'Settings',
    'modelVersion.value': 'Value',
    'modelVersion.change': 'Change',
    'modelVersion.remove': 'Remove',
    'modelVersion.filterBy': 'Filter By',
    'modelVersion.allFlowSequence': 'All flow sequences',
    'modelVersion.confirmDeleteThreshold': 'Are you sure you want to delete the threshold?',
    'modelVersion.allSensor': 'All sensors',
    'modelVersion.flowSequence': 'Flow Sequence',
    'modelVersion.sensor': 'Sensor',
    'modelVersion.thresholdSensor': 'Sensor',
    'modelVersion.thresholdUnitOfMeasure': 'Unit of Measure',
    'modelVersion.thresholdLowLow': 'Low Low',
    'modelVersion.thresholdOpLow': 'Op Low',
    'modelVersion.thresholdLow': 'Low',
    'modelVersion.thresholdHigh': 'High',
    'modelVersion.thresholdOpHigh': 'Op High',
    'modelVersion.thresholdHighHigh': 'High High',
    'modelVersionThreshold.FlowSequenceMandotory': 'Flow Sequence is a mandatory field',
    'modelVersionThreshold.sensorMandatory': 'Sensor is a mandatory field',
    'modelVersionThreshold.selectFlowSequence': 'Select Flow Sequence',
    'modelVersionThreshold.selectSensor': 'Select Sensor',
    'modelVersionThreshold.createdSuccessfully': 'Threshold created successfully',
    'modelVersionThreshold.updatedSuccessfully': 'Threshold updated successfully',
    'modelVersionThreshold.creationFailed': 'Threshold creation failed',
    'modelVersionThreshold.updationFailed': 'Threshold updation failed',
    'modelVersionThreshold.requiredParamsMissing': 'Required data missing',
    'modelVersionThreshold.fetchFailed': 'Threshold data fetch failed',
    'modelVersionThreshold.fetchSuccessfull': 'Threshold fetch successful',
    'modelVersionThreshold.duplicateName': 'A threshold with the same setting already exists',
    'modelVersionThreshold.deletedSuccessfully': 'Threshold deleted successfully',
    'modelVersionThreshold.deletetionFailed': 'Threshold deletion failed',
    'modelVersionThreshold.minThresholdRange': 'Min Range',
    'modelVersionThreshold.maxThresholdRange': 'Max Range',
    'modelVersionThreshold.thresholdRangeInvalid': 'Invalid threshold range, please use the min and max ranges indicated',
    'modelVersionManagement.selectSetting': 'Select setting',
    'modelVersionManagement.settingValue': 'Select value',
    'modelVersionManagement.cancelModelVersionThreshold': 'Cancel',
    'modelVersionManagement.saveModelVersionThreshold': 'Save',
    'modelVersionManagement.globalSettingRequired': 'Setting is a mandatory field',
    'modelVersionManagement.ValueRequired': 'Value is a mandatory field',
    'modelVersionManagement.defaultValue': 'Default',
    'modelVersionManagement.updateUnitShadow': 'UPDATE UNITS SHADOW',

    'modelVersionGlobalSettings.createdSuccessfully': 'Global Setting created successfully',
    'modelVersionGlobalSettings.updatedSuccessfully': 'Global Setting updated successfully',
    'modelVersionGlobalSettings.creationFailed': 'Global Setting creation failed',
    'modelVersionGlobalSettings.updationFailed': 'Global Setting update failed',
    'modelVersionGlobalSettings.deletedSuccessfully': 'Global Setting deleted successfully',
    'modelVersionGlobalSettings.deletetionFailed': 'Global Setting deletion failed',
    'modelVersionGlobalSettings.requiredParamsMissing': 'Global Setting required data missing',
    'modelVersionGlobalSettings.fetchFailed': 'Global Setting fetch failed',
    'modelVersionGlobalSettings.fetchSuccessfull': 'Global Setting fetch successful',
    'modelVersionGlobalSettings.duplicateName': 'A global setting with the same name already exists',
    'modelVersion.confirmDeleteGlobalSettings': 'Are you sure you want to delete the global setting?',

    'generalglobalsettings.datafetchsucccessfull': 'Global Setting fetched successfully',
    'generalglobalsettings.datafetchfailed': 'Global Settings ftech failed',
    'globalSettings.airscourflushfirstmembranepercentage': 'Air Scour Flush First Membrane Percentage',
    'globalSettings.airscourflushsecondmembranepercentage': 'Air Scour Flush Second Membrane Percentage',
    'globalSettings.airscourstandalonepercentage': 'Airs Scour Standalone Percentage',
    'globalSettings.ballvalvedelay': 'The number of seconds to wait for a Ball Valve to open or close',
    'globalSettings.closevalvedelaytimer': 'The number of seconds to wait to ensure valves have closed.',
    'globalSettings.directionalvalvedelay': 'The number of seconds needed for a directional valve to change position.',
    'globalSettings.ditdrainagemaxpressure': 'DIT Drainage Max Pressure',
    'globalSettings.ditdrainagetargetpressure': 'DIT Drainage Target Pressure',
    'globalSettings.ditdrainagetimeout': 'DIT Drainage Timeout',
    'globalSettings.ditdrainagetimer': 'DIT Drainage Timer',
    'globalSettings.ditmeasurementdrop': 'DIT Measurement Drop',
    'globalSettings.ditmeasurementduration': 'DIT Measurement Duration',
    'globalSettings.ditmeasurementstartpressuremax': 'DIT Measurement Start Pressure Max',
    'globalSettings.ditmeasurementstartpressuremin': 'DIT Measurement Start Pressure Min',
    'globalSettings.ditmeasurementtimeout': 'DIT Measurement Timeout',
    'globalSettings.ditrewettingvolume': 'DIT Rewetting Volume',
    'globalSettings.flushfirstmembraneffvolume': 'Flush First Membrane FF Volume',
    'globalSettings.flushmembranebfvolume': 'Flush Membrane BF Volume',
    'globalSettings.flushpumpdelaytimer': 'Flush Pump Delay Timer',
    'globalSettings.flushsecondmembraneffvolume': 'Flush Second Membrane FF Volume',
    'globalSettings.normalprocessingtimebeforeflush': 'Normal Processing Time Before Flush',
    'globalSettings.numberofsamples': 'Number Of Samples',
    'globalSettings.prefilterf010203volume': 'Prefilter F010203 Volume',
    'globalSettings.prefilterf04volume': 'Prefilter F04 Volume',
    'globalSettings.standalonemembraneffvolume': 'Standalone Membrane FF Volume',
    'globalSettings.staticerrortank1': 'Static Error Tank 1',
    'globalSettings.staticerrortank2': 'Static Error Tank 2',
    'globalSettings.BufferTankDumpTimer': 'Buffer Tank Dump Timer',
    'globalSettings.code': 'Code',

    'globalSettingsManagement.details': 'Details',
    'globalSettingsManagement.nameMandatory': 'Name is a mandatory field',
    'globalSettingsManagement.edit': 'Edit',
    'globalSettingsManagement.addNewGlobalSettings': 'Add new global settings',
    'globalSettingsManagement.code': 'Code',
    'globalSettingsManagement.defaultValue': 'Default value',
    'globalSettingsManagement.save': 'Save',
    'globalSettingsManagement.cancel': 'Cancel',
    'globalSettingsManagement.type': 'Type',
    'globalSettingsManagement.globalSetting': 'Global Setting',
    'globalSettingsManagement.flowSequence': 'Flow Sequence',
    'globalSettingsManagement.sequenceCode': 'Sequence code',
    'globalSettingsManagement.showCommand': 'Show command',
    'globalSettingsManagement.backToGlobalSettings': 'BACK TO GLOBAL SETTINGS',
    'globalSettingsManagement.active': 'Active',
    'globalSettingsManagement.showAll': 'Show: All',
    'globalSettingsManagement.globalSettings': 'Global Settings',
    'globalSettingsManagement.search': 'Search',
    'globalSettingsManagement.addNew': 'ADD NEW',
    'globalSettingsManagement.codeMandatory': 'Code is a mandatory field',
    'globalSettingsManagement.sequenceMandatory': 'Sequence code is a mandatory field',
    'globalSettingsManagement.sequenceNumeric': 'Sequence code must be numeric value',
    'globalSettingsManagement.minValue': 'Min Value',
    'globalSettingsManagement.maxValue': 'Max Value',
    'globalSettingsManagement.min': 'Min',
    'globalSettingsManagement.max': 'Max',
    'globalSettingsManagement.maxValuemustbeGrater': 'Max value must be grater than Min value',
    'globalSettingsManagement.defaultValueWithinRange': 'Default value must be within Min and Max range',
    'generalGlobalSettings.createdSuccessfully': 'Global setting created successfully',
    'generalGlobalSettings.updatedSuccessfully': 'Global setting updated successfully',
    'generalGlobalSettings.creationFailed': 'Global setting creation failed',
    'generalGlobalSettings.updationFailed': 'Global setting updation failed',
    'generalGlobalSettings.requiredParamsMissing': 'Required paramter missing',
    'generalGlobalSettings.fetchFailed': 'Data fetch failed',
    'generalGlobalSettings.fetchSuccessfull': 'Data fetch successful',
    'generalGlobalSettings.duplicateName': 'Duplicate Name',
    'generalGlobalSettings.deletedSuccessfully': 'Global setting deleted successfully',
    'generalGlobalSettings.deletetionFailed': 'Global setting deletion failed',
    'generalGlobalSettings.parameterInUse': 'Parameter in Use',
    'globalSettingsManagement.confirmDeleteGlobalSetting': 'Are you sure you want to delete the global setting?',
    'globalSettingsManagement.name': 'Name',
    'globalSettingsManagement.description': 'Description',
    'globalSettingsManagement.unitOfMeasure': 'Unit of measure',
    'globalSettingsManagement.change': 'Change',
    'globalSettingsManagement.delete': 'Delete',
    'globalSettingsManagement.duplicate': 'Duplicate',
    'globalSettingsManagement.predefined': 'Predefined',
    'globalSettingsManagement.isuninterruptible': 'Is Uninterruptible',
    'alarms.alarms': 'Alarms',
    'alarms.allEvenCodes': 'All Event Codes',
    'alarms.allSensors': 'All Sensors',
    'alarms.allStatus': 'All Status',
    'alarms.allUnits': 'All Units',
    'alarms.allAssignee': 'All Assignee',
    'alarms.dateReceived': 'Date Received',
    'alarms.eventCode': 'Event Code',
    'alarms.alarmName': 'Name',
    'alarms.status': 'Status',
    'alarms.unit': 'Unit',
    'alarms.assignee': 'Assignee',
    'alarms.alarmSearchName': 'Alarm Name',
    'alarms.sensor': 'Sensor',
    'alarms.search': 'Search',
    'alarms.filterBy': 'Filter By',
    'alarms.fetchFailed': 'Alarms fetch failed',
    'alarms.fetchSuccessfull': 'Alarms fetch successful',
    'alarms.requiredParamsMissing': 'Alarms required data missing',
    'alarms.filter.fetchFailed': 'Alarms filter fetch failed',
    'alarms.filter.fetchSuccessfull': 'Alarms filter fetch successful',
    'alarms.filter.requiredParamsMissing': 'Alarms filter required data missing',
    'alarms.linkedAlarms': 'Linked Alarms',

    'appwidget.id': 'Id',
    'appwidget.name': 'Widget Name',
    'appwidget.description': 'Description',
    'appwidget.status': 'Status',
    'appwidgets.syncdata': 'Sync Data',

    'thresholdTable.sensor': 'Sensor',
    'thresholdTable.units': 'Units',
    'thresholdTable.min': 'Min',
    'thresholdTable.low': 'Low',
    'thresholdTable.criticallow': 'Critical Low',
    'thresholdTable.criticalhigh': 'Critical High',
    'thresholdTable.high': 'High',
    'thresholdTable.max': 'Max',
    'thresholdTable.value': 'Value',
    'thresholdTable.timestamp': 'Time',
    'thresholdTable.event': 'Event',
    'thresholdTable.message': 'Message',
    'thresholdTable.eventInfo': 'Event Info',

    'units.unitsinoperation': 'Units in operation',
    'units.location': 'location',

    'chart.removeChart': 'Remove Chart',
    'chart.chart': 'Chart',
    'chart.nodataavailable': 'No data currently available',
    'chart.selectoreditchart': 'Select / edit variables for chart',
    'chart.sensors': 'Sensor(s)',
    'chart.datetimefrom': 'Date/Time from',
    'chart.datetimeto': 'Date/Time to',
    'chart.lowerthreshold': 'Lower Threshold',
    'chart.upperthreshold': 'Upper Threshold',
    'chart.addchart': 'Add chart',
    'chart.addanotherchart': 'Add another chart',
    'chart.duration': 'Duration',
    'chart.prevNextDay': 'Prev/Next Day',
    'chart.prevNext': 'Prev/Next',
    'chart.updateChart': 'Update Chart',

    'usermanagement.userList': 'User List',
    'usermanagement.users': 'Users',
    'usermanagement.addUser': 'Add User',
    'usermanagement.addnewuser': 'ADD NEW USER',
    'usermanagement.updateUser': 'Update User',
    'usermanagement.email': 'Email',
    'usermanagement.enteremail': 'Enter email',
    'usermanagement.name': 'Name',
    'usermanagment.email': 'Email',
    'usermanagment.contactno': 'Contact number',
    'usermanagement.edit': 'EDIT',
    'usermanagment.firstname': 'First Name',
    'usermanagment.enterfirstname': 'Enter First Name',
    'usermanagment.enterlastname': 'Enter Last Name',
    'usermanagment.enterphoneno': 'Enter Phone No',
    'usermanagment.enteremail': 'Enter Email',
    'usermanagment.profilename': 'Profile Name',
    'usermanagement.edituserdetails': 'Edit user details',
    'usermanagement.linkaddnewuser': 'Add new user',
    'usermanagment.select': 'Select',
    'usermanagement.repeatPassword': 'Repeat password',
    'usermanagement.status': 'Status',
    'usermanagement.search': 'Search',
    'usermanagement.add': 'Add',
    'usermanagement.unitname': 'Unit Name',
    'usermanagement.selectprofilethisuser': '1. Select a profile(s) for this user',
    'usermanagement.selectunits': '2. Select a unit(s) for ',
    'usermanagment.firstnamemandatory': 'First name is mandatory',
    'usermanagment.emailmandatory': 'Email is mandatory',
    'usermanagment.entervalidemail': 'Please enter valid email',
    'usermanagment.entervalidphoneno': 'Please enter valid phone number (minimum 7 digits including country code)',
    'usermanagment.addatleaseoneprofile': 'Please add at least one profile',
    'usermanagement.preferredContactMethod': 'Preferred Contact Method',
    'usermanagment.contactNameMandatory': 'Preferred Contact Method is mandatory',
    'user.createdSuccessfully': 'User created successfully',
    'user.updatedSuccessfully': 'User updated successfully',
    'user.creationFailed': 'User creation failed',
    'user.updationFailed': 'User updation failed',
    'user.emailAlreadyExist': 'User email already exists',
    'user.couldNotbeLocated': 'User could not be located',
    'user.requiredParamsMissing': 'Required data missing',
    'user.fetchFailed': 'User data fetch failed',
    'user.backtousers': 'BACK TO USERS',
    'user.passwordUpdatedSuccessfully': 'Password changed successfully',
    'user.passwordUpdateFailed': 'Error updating password',

    'userprofile.fetchFailed': 'User profile fetch failed',
    'userprofile.fetchSuccessfull': 'User profile fetch successful',
    'userprofile.updatefailed': 'User Profile update failed',

    'unit.unitffetchfailed': 'Unit fetch failed',
    'unit.unitffetchsuccessful': 'Unit fetch successful',

    'unitupload.back': '< BACK TO UNITS',
    'unitupload.addUnit': 'Add New Unit',
    'unitupload.browse': 'BROWSE FILE',
    'unitupload.dragndrop': 'Drag and drop file here',
    'unitupload.acceptedfile': 'Accepted file formats: .JSON',
    'unitupload.hasBeenUploaded': 'has been uploaded',
    'unitupload.delete': 'DELETE',
    'unitupload.remove': 'REMOVE',
    'unitupload.unitupload.addunit': 'ADD UNIT',
    'unitupload.unitupload.viewUnit': 'VIEW UNIT',
    'unitupload.unitupload.tryAgain': 'TRY AGAIN',
    'unitupload.unitupload.savefailed': 'The unit could not be added. Please check the file and try again.',
    'unitupload.unitupload.saveSuccess': 'The unit has been successfully added',
    'unitupload.unitupload.addingUnit': 'Adding Unit',
    'unitupload.unitupload.approxtime': 'This will take approximately 15 seconds',
    'unitupload.invalidfileformat': 'File format is invalid. Please upload a valid file',

    'pagination.next': '>',
    'pagination.prev': '<',
    'pagination.showing': 'Showing',
    'pagination.of': 'of',

    'unitreports.selectedateandtimerange': 'Select date and time range',
    'unitreports.fromdatetime': 'From date/time',
    'unitreports.todatetime': 'To date/time',
    'unitreports.selectdatapoints': 'Select data points',
    'unitreports.downloadreport': 'click here to download',
    'unitreports.generateReport': 'GENERATE REPORT',
    'unitreports.reportReady': 'Your report is now ready to be downloaded',
    'unitreports.reportinEmail': 'Your report will be sent to your registered email id ({}) when it is ready.',

    'unitreports.selectReportTypes': 'Select report type',
    'unitreports.selecttimeinterval': 'Select time interval',
    'unitreports.selectall': 'Select all',
    'unitreport.requiredParamsMissing': 'Required parameter missing',
    'unitreport.timerangenotvalid': 'Time range not valid',
    'unitreport.datafetchsuccessful': 'Report fetch successful',
    'unitreport.datafetchfailed': 'Report fetch failed',
    'unitreport.returnedemptydata': 'Empty data',
    'unitreport.selectfromtime': 'Please select from time',
    'unitreport.selecttotime': 'Please select to time',
    'unitreport.selectdatapoint': 'Please select at least one data point',
    'unitreport.invalidDateRange': 'Invalid date range! Range should be less than or equal to two days (48 hours)',
    'unitreports.messageDownloadSize': 'Reports with data more two days (48 hours) may take longer due to its size limitation',
    'unitreports.dailySummaryDownloadSize': 'Reports with data more two days (48 hours) may not be available due to its size limitation',
    'unitreports.notes': '* Telemetry report data is only available in UTC date time',
    'unitreports.downloadUTCDateTime': 'Download in UTC date time',
    'unitreports.downloadLocalDateTime': 'Download in Local date time',
    'passwordUpdate.errorUpdatePassword': 'Error updating password',
    'passwordUpdate.currentpassword': 'Current password',
    'passwordUpdate.passwordError': 'Password must contain at least 1 uppercase, 1 lowercase and 10 characters',
    'passwordUpdate.newPassword': 'New password',
    'passwordUpdate.confirmPassword': 'Confirm password',
    'passwordUpdate.confirmPasswordError': 'Password does not match',

    'unitlistmap.enterlocation': 'Enter location',
    'unitlistmap.enterUnitAddress': 'Enter unit address',

    'modelVersionShadow.updatedSuccessfully': 'Shadow Updated successfully',
    'modelVersionShadow.updationFailed': 'Shadow update Failed',
    'modelVersionShadow.requiredParamsMissing': 'Required Parameter Missing',
    'modelVersionShadow.modelUnitMissing': 'Missing  Units in model',
    'modelVersionShadow.s3ConfigSaveFailed': 'Failed to save config file in s3 ',
    'modelVersionShadow.globalSettingMissing': 'Missing global settings',
    'modelVersionShadow.sensorThresholdSettingMissing': 'Sensor Theshold settings Missing',
    'modelVersionShadow.iotEndPointMissing': 'IOT end point missing',
    'modelVersionShadow.unitCertificateMissing': 'Unit Certificate Missing',
    'modelVersionShadow.accessDenied': 'User does not have permission to access organisation',
    'contentTemplate.newAlarm': 'New alarm',

    'eventAction.alarm': 'Alarm',
    'eventAction.markAsResolved': 'MARK AS RESOLVED',
    'eventAction.status': 'Status',
    'eventAction.assignee': 'Assignee',
    'eventLog.insert': 'Insert',
    'eventLog.update': 'Update',
    'eventLog.create': 'CREATE',
    'eventAction.dates': 'Dates',
    'eventAction.backToEventLog': 'BACK TO EVENT LOG',
    'eventAction.unit': 'Unit',
    'eventAction.recieved': 'Received',
    'eventAction.resolved': 'Resolved',
    'eventAction.name': 'Name',
    'eventAction.id': 'I.D.',
    'eventAction.addAComment': 'Add a comment',
    'eventAction.activity': 'Activity',
    'eventAction.commentsRequired': 'Required comment',
    'eventAction.noActivityToShow': 'No acitivity to show',
    'eventAction.ifExistsFailed': 'Event Action status load failed',
    'eventAction.ifExistsSuccessfull': 'Event Action status load successful',
    'eventAction.requiredParamsMissing': 'Required parameter missing',
    'eventAction.updatedSuccessfully': 'Event action Update successfully',
    'eventAction.updationFailed': 'Event action update failed',
    'eventAction.createdSuccessfully': 'Event action created successfully',
    'eventAction.creationFailed': 'Event action creation failed',
    'eventAction.invalidEventCode': 'Invalid event code',
    'eventAction.fetchFailed': 'Event action fetch failed',
    'eventAction.fetchSuccessfull': 'Event action fetch successful',
    'eventAction.unitInfoMissing': 'Unit Information missing',
    'eventAction.backToalarms': 'BACK TO ALARMS',
    'eventAction.activeTasks': 'Few tasks are still active, can not mark as resolved.',
    'eventActionActivity.createdSuccessfully': 'Event action activity created successfully',
    'eventActionActivity.creationFailed': 'Event action activity creattion failed',
    'eventAction.linkAlarm': 'Link Alarm',
    'eventAction.linkedAlarm': 'Linked Alarm',
    'eventActionActitivity.linkedAlarm': 'linked Alarm',
    'eventActionActitivity.unlinkedAlarm': 'unlinked Alarm',
    'eventAction.unlinkAlarm': 'Unlink Alarm',
    'eventAction.datatrendAnalysis': 'Data trend analysis',
    'eventAction.telemetry': 'Telemetry',
    'eventAction.downloadSuccessfull': 'Telemetry url save successful',
    'eventAction.downloadFailed': 'Telemetry url save failed',
    'eventAction.downloadedReportLink': 'Downloaded Link',
    'eventAction.reportDownloadAnalysis': 'Your previously analysed report(s)',
    'eventAction.reportAddCustomDownload': 'Add custom report link',
    'eventAction.saveDownloadLink': 'Save Link',
    'eventAction.unableToDownloadReport': 'Unable to download report',

    'checklistTasksTemplates.view': 'VIEW',
    'checklistTaskTemplateList.checklistTemplates': 'Checklist Templates',
    'checklistTaskTemplateList.tasksTemplates': 'Tasks Templates',
    'checklistTaskTemplateList.showAll': 'SHOW ALL',
    'checklistTaskTemplateList.showLess': 'SHOW LESS',
    'checklistTaskTemplateList.addNew': 'ADD NEW',
    'checklistTemplate.checklistTemplate': 'Checklist Template',
    'checklistTemplateManagement.back': 'BACK',
    'checklistTemplateManagement.name': 'Name',
    'checklistTemplateManagement.description': 'Description',
    'checklistTemplateManagement.cancel': 'Cancel',
    'checklistTemplateManagement.saveChanges': 'Save Changes',
    'checklistTemplateManagement.Search': 'Search',
    'checklistTemplateManagement.change': 'Change',
    'checklistTemplate.newChecklistTemplate': 'New Checklist Template',
    'checklistTemplate.edit': 'Edit',
    'checklistTemplate.createdSuccessfully': 'Checklist template created successfully',
    'checklistTemplate.updatedSuccessfully': 'Checklist template updated successfully',
    'checklistTemplate.creationFailed': 'Checklist template creation failed',
    'checklistTemplate.updationFailed': 'Checklist template updation failed',
    'checklistTemplate.requiredParamsMissing': 'Required parameter missing',
    'checklistTemplate.fetchFailed': 'Data fetch failed',
    'checklistTemplate.fetchSuccessfull': 'Data fetch successful',
    'checklistTemplate.duplicateName': 'Duplicate template name',
    'checklistTemplateManagement.nameMandatory': 'Checklist Name is a mandatory field',
    'checklistTemplateManagement.taskTemplateMandatory': 'Task template is mandatory',

    'taskTemplate.createdSuccessfully': 'Task Template created successfully',
    'taskTemplate.updatedSuccessfully': 'Task Template updated successfully',
    'taskTemplate.creationFailed': 'Task Template creation failed',
    'taskTemplate.updationFailed': 'Task Template updation failed',
    'taskTemplate.requiredParamsMissing': 'Required parameters missing',
    'taskTemplate.fetchFailed': 'Task Template fetch failed',
    'taskTemplate.fetchSuccessfull': 'Task Template fetch successful',
    'taskTemplate.duplicateName': 'Duplicate Task Template Name',
    'taskTemplate.serviceTemplateMandatory': 'Task Template belongs to a service checklist template has to be a service task',
    'taskTemplateManagement.back': 'BACK',
    'taskTemplateManagement.activities': 'Activities',
    'taskTemplateManagement.role': 'Role',
    'taskTemplateManagement.effort': 'Effort',
    'taskTemplateManagement.estimatedEffort': 'Estimated effort',
    'taskTemplateManagement.change': 'Change',
    'taskTemplateManagement.task': 'Task',
    'taskTemplateManagement.enterTask': 'Enter task',
    'taskTemplateManagement.description': 'Description / Objective',
    'taskTemplateManagement.enterDescription': 'Enter description',
    'taskTemplateManagement.enterActivities': 'Enter activities',
    'taskTemplateManagement.newTaskTemplate': 'New Task Template',
    'taskTemplateManagement.edit': 'Edit',
    'taskTemplateManagement.saveChanges': 'Save changes',
    'taskTemplateManagement.cancel': 'Cancel',
    'taskTemplateManagement.nameMandatory': 'Task Name is a mandatory field',
    'taskTemplateManagement.serviceTask': 'Service task',
    'tasksManagement.nonServiceTask': 'Non-service task',

    'checklistTemplateTasks.addNew': 'ADD NEW',
    'checklistTemplateTasks.search': 'Search',
    'checklistTemplateTasks.view': 'VIEW',
    'checklistTemplateTasks.remove': 'REMOVE',
    'checklistTemplateTasks.task': 'Task',
    'checklistTemplateTasks.role': 'Role',
    'checklistTemplateTasks.estimatedEffort': 'Estimated Effort',
    'checklistTemplateTasks.tasks': 'Tasks',
    'checklistTemplateTasks.selectTaskFromTaskTemplate': 'Select task from a task template',
    'checklistTemplateTasks.select': 'Select',
    'checklistTemplateTasks.add': 'ADD',
    'checklistTemplateTasks.cancel': 'CANCEL',
    'checklistTemplateTasks.createNewTask': 'CREATE NEW TASK',
    'checklistTemplateTasks.hours': 'hours',
    'checklistTemplateTasks.noData': 'No Data',

    'tasksAndChecklistsManagement.tasksChecklists': 'Tasks & Checklists',
    'tasksAndChecklistsManagement.myTasks': 'My Tasks',
    'tasksAndChecklistsManagement.myChecklists': 'My Checklists',

    'checklist.createdSuccessfully': 'Checklist created successfully',
    'checklist.updatedSuccessfully': 'Checklist updated successfully',
    'checklist.creationFailed': 'Checklist creation failed',
    'checklist.updationFailed': 'Checklist updation failed',
    'checklist.requiredParamsMissing': 'Required parameter missing',
    'checklist.fetchFailed': 'Data fetch failed',
    'checklist.fetchSuccessfull': 'Data fetch successful',
    'checklist.duplicateName': 'Duplicate template name',
    'checklist.deletedSuccessfully': 'Checklist deleted successfully',
    'checklist.schedulingServicePlanChecklistFailed': 'Service plan checklist scheduling failed',
    'checklist.servicePlanMissingFromModelVersion': 'Unable to create next service plan as no service plan is associated with this unit',
    'checklist.checklistUpdateAndServicePlanChecklistScheduled':
      'Checklist has been updated and Service plan checklist scheduled successfully',
    'checklistDetail.selectFromChecklistTemplates': 'Select from checklist templates',
    'checklistDetail.dueDateTime': 'Due date & time',
    'checklistDetail.edit': 'Edit',
    'checklistDetail.checklist': 'Checklist',
    'checklistDetail.newChecklist': 'New Checklist',
    'checklistDetail.back': 'BACK',
    'checklistDetail.details': 'Details',
    'checklistDetail.name': 'Name',
    'checklistDetail.description': 'Description',
    'checklistDetail.markAsComplete': 'MARK AS COMPLETE',
    'checklistDetail.change': 'Change',
    'checklistDetail.delete': 'Delete',
    'checklistDetail.tasksCompleted': 'tasks completed',
    'checklistDetail.progress': 'Progress',
    'checklistDetail.createServiceRecord': 'Create service record',
    'checklistDetail.viewServiceRecord': 'View service record',
    'checklistDetail.checklistComplete': 'CHECKLIST IS COMPLETE',
    'checklist.activeTaskExistInChecklist': 'Active task exist in checklist',
    'checklistTask.showAll': 'Show:All',
    'checklistTask.showActive': 'Active',
    'checklistTask.showComplete': 'Complete',
    'checklistTasks.status': 'Status',
    'checklistTasks.dueDate': 'Due Date',
    'checklistManagement.name': 'Name',
    'checklistManagement.tasksCompleted': 'Tasks Completed',
    'checklistManagement.duedate': 'Due Date',
    'checklistManagement.status': 'Status',
    'checklistManagement.view': 'VIEW',
    'checklistManagement.backtoChecklist': 'BACK TO CHECKLIST',
    'checklistManagement.confirmDeleteChecklist': 'Are you sure you want to delete the checklist?',
    'checklistManagement.unit': 'Unit',
    'checklistManagement.role': 'Role',
    'checklistManagement.assignTo': 'Assign to',
    'checklistDetail.assignedTo': 'Assigned to',
    'checklistDetail.assignedBy': 'Assigned by',

    'checklistTemplateManagement.serviceChecklist': 'Service checklist',
    'checklistDetail.serviceChecklist': 'Service checklist',
    'checklistTemplateManagement.serviceChecklistTemplateIsInUse':
      'Checklist template used in model version service plan must be service checklist template.',
    'checklistTemplateManagement.confirmServiceTask':
      'Tasks attached to the checklist will be converted to service tasks. Do you want to continue?',
    'checklistTemplateManagement.confirmUnitTask': 'Units attached to the checklist will be inherited by tasks. Do you want to continue?',
    'checklistTemplateTasks.serviceTask': 'Service Task',
    'checklistTasks.servcieTask': 'Service Task',
    'checklistManagement.showAll': 'Show: All',
    'checklistManagement.serviceChecklist': 'Service checklist',
    'checklistManagement.nonServiceChecklist': 'Non-service checklist',
    'task.taskUnitShouldBeSameAsChecklistUnit': 'Selected unit on task should be same as checklist unit',

    'tasksManagement.showAll': 'Show: All',
    'tasksManagement.search': 'Search',
    'tasksManagement.task': 'Task',
    'tasksManagement.duedate': 'Due Date',
    'tasksManagement.status': 'Status',
    'tasksManagement.addNew': 'Add New',
    'tasksManagement.view': 'VIEW',
    'tasksManagement.edit': 'Edit',
    'tasksManagement.newTask': 'New Task',
    'tasksManagement.active': 'Active',
    'tasksManagement.complete': 'Complete',
    'tasksManagement.linkedAlarms': 'This task is linked to the following alarms',
    'tasksManagement.people': 'People',
    'tasksManagement.assignedTo': 'Assigned to',
    'tasksManagement.assignedBy': 'Assigned by',
    'tasksManagement.dates': 'Dates',
    'tasksManagement.dueDate': 'Due Date',
    'tasksManagement.completedOn': 'Completed On',
    'tasksManagement.actualEffort': 'Actual Effort',
    'taskManagement.dates': 'Dates',
    'tasksManagement.unit': 'Unit',
    'tasksManagement.unitName': 'Name',
    'tasksManagement.delete': 'Delete',
    'tasksManagement.markAsComplete': 'Mark As Complete',
    'tasksManagement.selectTask': 'Select from task templates',
    'tasksManagement.assignTo': 'Assign to',
    'tasksManagement.linkToAlarms': 'Link to alarms',
    'tasksManagement.remove': 'REMOVE',
    'tasksManagement.createTask': 'Create Task',
    'tasksManagement.selectAlarms': 'Select current alarms for',
    'tasksManagement.dueDateTime': 'Due date & time',
    'tasksManagement.confirmDeleteTask': 'Are you sure you want to delete the task?',
    'tasksManagement.backtoTasks': 'BACK TO TASKS',
    'tasksManagement.backtoAlarm': 'BACK TO ALARM',
    'tasksManagement.backtoDashboard': 'BACK TO DASHBOARD',
    'tasksManagement.bulkActions': 'Bulk Actions',
    'tasksManagement.selectTask': 'Select at least one task',
    'task.createdSuccessfully': 'Task created successfully',
    'task.updatedSuccessfully': 'Task updated successfully',
    'task.creationFailed': 'Task creation failed',
    'task.updationFailed': 'Task updation failed',
    'task.requiredParamsMissing': 'Required parameters missing',
    'task.fetchFailed': 'Tasks fetch failed',
    'task.fetchSuccessfull': 'Tasks fetch successful',
    'task.duplicateName': 'Duplicate Task Name',
    'task.serviceTaskMandatory': 'Task belongs to a service checklist has to be a service task',
    'task.alarms.creationFailed': 'Task alarm creation failed',
    'task.alarms.updationFailed': 'Task alarm updation failed',
    'task.deletionFailed': 'Task deletion failed',
    'task.deletedSuccessfully': 'Task deleted successfully',
    'tasksManagement.save': 'Save',
    'tasksManagement.comments': 'Comments',
    'tasksManagement.addedAComment': 'added a comment',
    'tasksManagement.actualEffortMandatory': 'Actual effort is mandatory',
    'task.comment.requiredParamsMissing': 'Missing parameters',
    'task.comment.createdSuccessfully': 'Task comment sucessfully added',
    'task.comment.creationFailed': 'Unable to add comment',
    'task.comment.fetchFailed': 'Task comment fetch failed',

    'eventCodes.createdSuccessfully': 'Created event code successfully',
    'eventCodes.updatedSuccessfully': 'Updated event code successfully',
    'eventCodes.creationFailed': 'Creating event code failed',
    'eventCodes.updationFailed': 'Updating event code failed',
    'eventCodes.duplicateCode': 'An event code with the same code already exists',
    'eventCodes.requiredParamsMissing': 'Event code required data missing',
    'eventCodes.fetchFailed': 'Failed fetching event codes',
    'eventCodes.fetchSuccessfull': 'Fetching event codes successfully',
    'eventCodes.deletionFailed': 'Failed to delete event code',
    'eventCodes.deletionSuccessfully': 'Successfully deleted event code',
    'eventCodes.eventCodes': 'Event Codes',
    'eventCodes.eventCode': 'Event Code',
    'eventCodes.code': 'Code',
    'eventCodes.eventCategory': 'Event Category',
    'eventCodes.eventCodeType': 'Event Code Type',
    'eventCodes.description': 'Description',
    'eventCodes.name': 'Name',
    'eventCodes.flowSequence': 'Flow Sequence',
    'eventCodes.sensor': 'Sensor',
    'eventCodes.threshold': 'Threshold',
    'eventCodes.eventCodeActive': 'Event code is active',
    'eventCodes.nextActionDetails': 'Next Action Details',
    'eventCodes.addNew': 'ADD NEW',
    'eventCodes.back': 'Back',
    'eventCodes.edit': 'Edit',
    'eventCodes.delete': 'Delete',
    'eventCodes.save': 'SAVE CHANGES',
    'eventCodes.cancel': 'CANCEL',
    'eventCodes.enterDescription': 'Enter Description',
    'eventCodes.enterNextActionDetail': 'Enter Next Action',
    'eventCodes.codeMandatory': 'Code is a mandatory field',
    'eventCodes.codeInvalidCode': 'Code has to be of a 4 digit code',
    'eventCodes.eventCategoryMandatory': 'Event category is a mandatory field',
    'eventCodes.nameMandatory': 'Name is a mandatory field',
    'eventCodes.eventCodeTypeMandatory': 'Event code type is a mandatory field',
    'eventCodes.flowsequenceMandatory': 'Flow Sequence is a mandatory field',
    'eventCodes.sensorMandatory': 'Sensor is a mandatory field',
    'eventCodes.selectFlowSequence': 'Select flow sequence',
    'eventCodes.selectSensor': 'Select sensor',
    'eventCodes.delete.confirmation': 'Are you sure you want to delete this event code?',
    'eventCodes.allFlowSequence': 'All Flow Sequences',
    'eventCodes.allSensor': 'All Sensors',
    'eventCodes.searchLabelName': 'Search Name',
    'eventCodes.searchLabelCode': 'Search Code',
    'eventCodes.filterBy': 'Filter By',
    'eventCodes.search': 'Search',
    'eventCodes.selectEventCategory': 'Select event category',
    'model.createdSuccessfully': 'Model sucessfully created',
    'model.updatedSuccessfully': 'Model sucessfully saved',
    'model.creationFailed': 'Model creation failed',
    'model.updationFailed': 'Model update failed',
    'model.requiredParamsMissing': 'Missing parameters',
    'model.fetchFailed': 'Unable to retrieve Model data',
    'model.fetchSuccessfull': 'Records fetched successfully',
    'model.duplicateName': 'A model name already exists',
    'model.model': 'Model',
    'model.modelName': 'Model name',
    'model.description': 'Model Description',
    'model.dateCreated': 'Date created',
    'model.dateModified': 'Date modified',
    'model.versions': 'Versions',
    'model.configuration': 'Configuration',
    'model.duplicate': 'Duplicate',
    'model.delete': 'Delete',
    'model.edit': 'Edit',
    'model.duplicateModel': 'Duplicate',
    'model.editModel': 'Edit',
    'model.nameMandatory': 'Model name is a mandatory field',
    'model.backToModels': 'BACK TO MODELS',
    'model.enterDescription': 'Enter model description',
    'model.active': 'Model is active',
    'model.saveModel': 'SAVE CHANGES',
    'model.cancelModel': 'CANCEL',
    'model.duplicateOptions': 'Duplicate options',
    'model.duplicateComponentPositionLinks': 'Component Position Links',
    'model.duplicateTelemetryLinks': 'Telemetry Links',
    'model.duplicateModelVersionInfo': 'Please select the model version(s) that require to be duplicated',
    'model.versionSelected': 'Select',
    'model.duplicateGobalSettings': 'Duplicate Gobal Settings',
    'model.duplicateThresholds': 'Duplicate Thresholds',
    'model.duplicateUnitGlobalSettings': 'Duplicate Unit Global Settings',
    'model.schematicDiagram': 'Schematic Diagram',

    'componentParts.allBrands': 'All Brands',
    'componentParts.allModels': 'All Models',
    'componentParts.allComponentTypes': 'All Types',
    'componentParts.allUnits': 'All',
    'componentParts.parts': 'Parts',
    'componentParts.search': 'Search',
    'componentParts.filterBy': 'Filter by',
    'componentParts.brand': 'Brand',
    'componentParts.model': 'Model',
    'componentParts.componentType': 'Type',
    'componentParts.unitAllocated': 'Allocated',
    'componentParts.serialNumber': 'Serial Number',
    'componentParts.unitSerialNumber': 'Unit Serial Number',
    'componentParts.view': 'View',
    'componentParts.addNew': 'Add New',
    'componentParts.uploadJson': 'Upload .JSON File',
    'componentParts.serialNumberMandatory': 'Serial Number is mandatory',
    'componentParts.approvedComponentMandatory': 'Approved Component is mandatory',
    'componentParts.unitMandatory': 'Unit Serial Number is mandatory if Model Component Position is selected',
    'componentParts.modelComponentPositionMandatory': 'Model Component Position is mandatory if Unit Serial Number is selected',
    'componentParts.backToParts': 'BACK TO PARTS',
    'componentParts.change': 'Change',
    'componentParts.location': 'Location',
    'componentParts.faulty': 'Faulty',
    'componentParts.isFaulty': 'Is Faulty',
    'componentParts.active': 'Active',
    'componentParts.isActive': 'Is Active',
    'componentParts.organisation': 'Organisation',
    'componentParts.approvedComponent': 'Approved Component',
    'componentParts.approvedComponentRef': 'Approved Component Ref',
    'componentParts.componentType': 'Component Type',
    'componentParts.modelComponentPosition': 'Model Component Position',
    'componentParts.position': 'Position',
    'componentParts.addNewPart': 'Add new Part',
    'componentParts.existingNewPart': 'Add existing part',
    'componentParts.editPart': 'Edit Part',
    'componentParts.save': 'Save',
    'componentParts.replace': 'Replace',
    'componentParts.cancel': 'Cancel',
    'componentParts.no': 'No',
    'componentParts.yes': 'Yes',
    'componentParts.createdSuccessfully': 'Component part created successfully',
    'componentParts.updatedSuccessfully': 'Component part updated successfully',
    'componentParts.creationFailed': 'Component part creation failed',
    'componentParts.updationFailed': 'Component part updation failed',
    'componentParts.requiredParamsMissing': 'Required  parameter missing',
    'componentParts.fetchFailed': 'Component parts fetch failed',
    'componentParts.fetchSuccessful': 'Component parts fetch successful',
    'componentParts.duplicateSerialNumber': 'Duplicate Serial Number',
    'componentPartsFilters.requiredParamsMissing': 'Required  parameter missing fir filters',
    'componentPartsFilter.fetchSuccessful': 'Filters fetch successful',
    'componentPartsFilter.fetchFailed': 'Filters fetch failed',
    'componentParts.addedSuccessfully': 'Component Part added successfully',
    'componentParts.showAll': 'Show All',
    'componentParts.showLess': 'Show Less',
    'componentParts.selectApprovedComponent': 'Select Approved Component',
    'componentParts.selectUnitSerialNumber': 'Select Unit Serial Number',
    'componentParts.selectModleComponentPosition': 'Select Model Component Position',
    'componentParts.back': 'BACK',
    'componentParts.details': 'Details',
    'componentParts.historicalTelemetryData': 'Historical Telemetry Data',
    'componentParts.historicalFirmwareupdateData': 'Historical Firmware Update Data',
    'componentParts.currentTelemetryData': 'Current Telemetry Data',
    'componentParts.eventDate': 'Event Date',
    'componentParts.eventType': 'Event Type',
    'componentParts.telemetryType': 'Telemetry Type',
    'componentParts.telemetryValue': 'Value',
    'componentParts.thingNameSerialNumberForSBC': 'Serial number must be IoT Thing name for SBC',
    'unitConfigurationComponentParts.modelComponentPositionMandatory': 'Model Component Position is mandatory',
    'unitConfigurationComponentParts.componentPartMandatory': 'Component Part is mandatory',
    'unitConfigurationComponentParts.selectPart': 'Select Part',
    'unitSettings.saveSuccessfull': 'Update successfull',
    'unitSettings.unableToSave': 'Failed to update!',
    'unitSettings.datafetchsucccessfull': 'Data fetched successfull!',
    'unitSettings.datafetchfailed': 'Data fetched failed!',
    'unitSettings.requiredParamsMissing': 'Missing parameters',
    'unitsettings.saveAllConfirmation': 'Do you wish to update all the changes?',
    'unitsettings.search': 'Search for unit settings',
    'unitsettings.unitsettings': 'Unit Settings',
    'unitsettings.invalidValue': 'Invalid value',
    'unitsettings.invalidMinRange': 'Value below minimum range',
    'unitsettings.invalidMaxRange': 'Value above maximum range',

    'schedule.schedules': 'Schedules',
    'schedule.addNew': 'Add New Schedule',
    'schedule.newSchedule': 'New Schedule',
    'schedule.command': 'Command',
    'schedule.startDateTime': 'Start sending command at',
    'schedule.day': 'Day',
    'schedule.endDate': 'Do not send command after',
    'schedule.scheduleFrequency': 'Command Frequency',
    'schedule.by': 'By',
    'schedule.end': 'End',
    'schedule.select': 'Select',
    'schedule.back': 'BACK',
    'schedule.commandMandatory': 'Command is a mandatory field',
    'schedule.frequencyMandatory': 'Command Frequency is a mandatory field',
    'schedule.startDateMandatory': "'Start sending command' at is a mandatory field",
    'schedule.endDateMandatory': 'Do-not-send-command-after is a mandatory field',
    'schedule.endDateGreater': 'Do-not-send-command-after should be greater than start date',
    'schedule.endDateLimit': 'Do-not-send-command-after can only be selected up to 12 weeks from the Start-sending-command-at',
    'schedule.pastStartTime': 'Start Time can be selected from next 15 minutes',
    'schedule.pastEndTime': 'End Time can be selected from next 15 minutes',
    'scheduler.createdSuccessfully': 'Schedule created successfully',
    'scheduler.endedSuccessfully': 'Schedule ended successfully',
    'scheduler.creationFailed': 'Schedule creation failed',
    'scheduler.endFailed': 'Ending a Schedule failed',
    'scheduler.requiredParamsMissing': 'Required parameters missing',
    'scheduler.fetchFailed': 'Schedule fetch failed',
    'scheduler.fetchSuccessful': 'Schedule fetch successful',
    'scheduler.duplicateSchedule': 'Schedule already exists for same command at same time for this unit',
    'schedule.save': 'Save',
    'schedule.cancel': 'Cancel',
    'schedule.finalize': 'Finalize',
    'schedule.confirmEndSchedule': 'Are you sure you want to end the schedule?',

    'modelComponentTypes.createdSuccessfully': 'Model component type created successfully',
    'modelComponentTypes.updatedSuccessfully': 'Model component type updated successfully',
    'modelComponentTypes.creationFailed': 'Model component type creation failed',
    'modelComponentTypes.updationFailed': 'Model component type updation failed',
    'modelComponentTypes.duplicateCode': 'Duplicate model component type name',
    'modelComponentTypes.requiredParamsMissing': 'Required  parameter missing',
    'modelComponentTypes.fetchFailed': 'Data fetch failed',
    'modelComponentTypes.fetchSuccessfull': 'Fetch succesful',
    'modelComponentTypes.deletionFailed': 'Deletion Failed',
    'modelComponentTypes.deletionSuccessful': 'Deleted successfully',
    'modelComponentTypes.confirmDelete': 'Are you sure you want to delete model component type?',
    'modelComponentTypes.showAll': 'SHOW ALL',
    'modelComponentTypes.showLess': 'SHOW LESS',
    'modelComponentTypes.componentTypes': 'Component types',
    'modelComponentTypes.search': 'Search',
    'modelComponentTypes.addNew': 'ADD NEW',
    'modelComponentTypes.name': 'Name',
    'modelComponentTypes.description': 'Description',
    'modelComponentTypes.change': 'CHANGE',
    'modelComponentTypes.remove': 'REMOVE',
    'modelComponentTypes.nameRequired': 'Name is a mandatory field',
    'modelComponentTypes.save': 'SAVE',
    'modelComponentTypes.cancel': 'CANCEL',
    'modelComponentTypes.parameterInUse': 'Parameter in use',
    'modelComponentTypes.back': 'BACK',
    'modelComponentTypes.addNewComponentType': 'Add new component type',
    'modelComponentTypes.edit': 'Edit',
    'modelComponentTypes.sububComponentTypes': 'Subcomponent Types',
    'modelComponentTypes.addNew': 'ADD NEW',
    'modelComponentTypes.search': 'Search',
    'modelComponentTypes.noData': 'No Data',
    'modelComponentTypes.add': 'Add',
    'modelComponentTypes.cancel': 'Cancel',
    'modelComponentTypes.subComponentTypeId': 'Subcomponent Type Id',
    'modelComponentTypes.Edit': 'Edit',
    'modelComponentTypes.subComponentType': 'Subcomponent Type',
    'modelSubcomponentTypes.name': 'Name',
    'modelComponentTypes.typeRequired': 'Type Name is a mandatory field',
    'modelComponentTypes.maxThresholdLimit': 'Max Threshold Limit',
    'modelComponentTypes.minThresholdLimit': 'Min Threshold Limit',
    'modelComponentTypes.invalidMinThresholdLimit': 'Invalid min threshold',
    'modelComponentTypes.invalidMaxThresholdLimit': 'Invalid max threshold',
    'modelComponentTypes.minThresholdGreaterThanMax': 'Min threshold cannot be greater than max threshold',
    'modelComponentTypes.submit': 'Submit',
    'modelComponentPositions.componentSubTypeRequired': 'Subcomponent type is a mandatory field',

    'model.modelversion': 'Versions',
    'model.componentpositions': 'Component Positions',
    'model.configureModel': 'Configure',
    'model.componentposition.saveSuccessfull': 'Component position added successfully',
    'model.componentposition.saveFailed': 'Component position adding failed',
    'model.componentposition.deletefailed': 'Component position deleted successfully',
    'model.componentposition.deleteSuccessfull': 'Component position deleted successfully',
    'model.componentposition.isUsed': 'Unable to deleted component position is be used in other places',
    'model.componentpositions.showAll': 'SHOW ALL',
    'model.componentpositions.showLess': 'SHOW LESS',
    'model.componentpositions.confirmDeleteComponentPosition': 'Are you sure you want to delete the component position?',
    'model.componentpositions.search': 'Search',
    'model.componentpositions.addNew': 'ADD NEW',
    'model.componentpositions.uploadComponentsToUnit': 'UPLOAD COMPONENTS',
    'model.componentpositions.positionName': 'Position',
    'model.componentpositions.positionDescription': 'Description',
    'model.componentpositions.componentType': 'Component Type',
    'model.componentpositions.state': 'State',
    'model.componentpositions.feedback': 'Feedback',
    'model.componentpositions.unitOfMeasure': 'Unit of Measure',
    'model.componentpositions.active': 'Active',
    'model.componentpositions.selectModelComponentPosition': 'Select model component position',
    'model.componentpositions.modelComponentPositionRequired': 'Model component position is requried',
    'model.componentpositions.save': 'SAVE',
    'model.componentpositions.cancel': 'CANCEL',
    'model.componentpositions.edgeDevice': 'Edge Device',
    'model.componentpositions.multipleEdgeDevicesDetected': 'Multiple edge devices detected',
    'model.componentposition.saveSBCDuplicate': 'Duplicate Edge Setting found',
    'model.componentpositions.unitShdowUpdatedWithLatestSettings': 'Device shadow will be updated with latest model version setttings.',
    'model.componentpositions.uploadComponentsToUnitParamMissing': 'Missing required parameter',
    'model.componentpositions.uploadCalibrationToUnitParamMissing': 'Missing required parameter',
    'model.componentpositions.uploadComponentsToUnitFailed': 'Failed to upload components file to the unit',
    'model.componentpositions.uploadComponentsToUnitSuccessful': 'File uploaded to the unit successfully',
    'model.componentpositions.uploadCalibrationToUnitSuccessful': 'Calibration file uploaded to the unit successfully',
    'model.componentpositions.uploadCalibrationToUnitFailed': 'Failed to upload calibration file to the unit',
    'modelVersion.showAll': 'SHOW ALL',
    'modelVersion.showLess': 'SHOW LESS',
    'model.telemetryLinks.addNew': 'ADD NEW',
    'model.telemetryLinks.position': 'Position',
    'model.telemetryLinks.description': 'Description',
    'model.telemetryLinks.telemetryType': 'Telemetry Type',
    'model.telemetryLinks.sourcePosition': 'Source Position',
    'model.telemetryLinks.change': 'Change',
    'model.telemetryLinks.remove': 'Remove',
    'model.telemetryLinks.title': 'Component Position Telemetry Links',
    'model.telemetryLinks.positionMandatory': 'Position is mandatory field',
    'model.telemetryLinks.telemetryTypeMandatory': 'Telemetry Type is mandatory field',
    'model.telemetryLinks.sourcePositionMandatory': 'Source Position is mandatory field',
    'model.telemetryLinks.sourceTargetSame': 'Position and Source Position can not be same',
    'model.telemetryLinks.addNewTelemetryLink': 'Add New Telemetry Link',
    'model.telemetryLinks.save': 'Save',
    'model.telemetryLinks.cancel': 'Cancel',
    'model.telemetryLinks.selectOne': 'Please select one',
    'model.telemetryLinks.areYouSureYouWantToRemoveTheTelemetryLink': 'Are you sure you want to remove the Telemetry link?',

    'telemetryLinks.createdSuccessfully': 'Telemetry Links created successfully',
    'telemetryLinks.updatedSuccessfully': 'Telemetry Links updated successfully',
    'telemetryLinks.creationFailed': 'Telemetry Links creation failed',
    'telemetryLinks.updationFailed': 'Telemetry Links update failed',
    'telemetryLinks.requiredParamsMissing': 'Required  parameters missing',
    'telemetryLinks.fetchFailed': 'Telemetry Links fetch failed',
    'telemetryLinks.fetchSuccessfull': 'Telemetry Links fetch successful',
    'telemetryLinks.deleteSuccessful': 'Telemetry link deleted successfully',
    'telemetryLinks.deleteFailed': 'Telemetry link deletion failed',

    'approvedComponents.createdSuccessfully': 'Approved component created successfully',
    'approvedComponents.updatedSuccessfully': 'Approved component updated successfully',
    'approvedComponents.creationFailed': 'Approved component creation failed',
    'approvedComponents.updationFailed': 'Approved component updation failed',
    'approvedComponents.duplicateCode': 'Duplicate approved component name',
    'approvedComponents.requiredParamsMissing': 'Required  parameter missing',
    'approvedComponents.fetchFailed': 'Data fetch failed',
    'approvedComponents.fetchSuccessfull': 'Fetch succesful',
    'approvedComponents.deletionFailed': 'Deletion Failed',
    'approvedComponents.deletionSuccessful': 'Deleted successfully',
    'approvedComponents.confirmDelete': 'Are you sure you want to delete approved component?',
    'approvedComponents.showAll': 'SHOW ALL',
    'approvedComponents.showLess': 'SHOW LESS',
    'approvedComponents.approvedComponents': 'Approved components',
    'approvedComponent.search': 'Search',
    'approvedComponent.addNew': 'ADD NEW',
    'approvedComponents.type': 'Type',
    'approvedComponents.brand': 'Brand',
    'approvedComponents.model': 'Model',
    'approvedComponents.view': 'VIEW',
    'approvedComponents.edit': 'Edit',
    'approvedComponents.addNewApprovedComponent': 'Add new approved component',
    'approvedComponents.back': 'BACK',
    'approvedComponents.modelRequired': 'Model is a mandatory field',
    'approvedComponents.brandRequired': 'Brand is a mandatory field',
    'approvedComponents.uOM': 'Unit of measure',
    'approvedComponents.pleaseSelectOne': 'Please select one',
    'approvedComponents.componentType': 'Component type',
    'approvedComponents.componentTypeRequired': 'Component type is a mandatory field',
    'approvedComponents.calibrationFrequency': 'Calibration frequency',
    'approvedComponents.calibrationTolerance': 'Calibration tolerance',
    'approvedComponents.calibrationEquation': 'Calibration equation',
    'approvedComponents.calibrationRequired': 'Calibration required',
    'approvedComponents.yes': 'Yes',
    'approvedComponents.output': 'Output',
    'approvedComponents.outputUOM': 'Output unit of measure',
    'approvedComponents.outputLowRange': 'Output low range',
    'approvedComponents.outputHighRange': 'Output high range',
    'approvedComponents.hasState': 'Has state',
    'approvedComponents.hasFeedback': 'Has feedback',
    'approvedComponents.serviceable': 'Serviceable',
    'approvedComponents.consumable': 'Consumable',
    'approvedComponents.active': 'Active',
    'approvedComponents.supplierName': 'Supplier name',
    'approvedComponents.supplierAddress': 'Supplier address',
    'approvedComponents.manufacturerAddress': 'Manufacturer address',
    'approvedComponents.manufacturerName': 'Manufacturer name',
    'approvedComponents.dataSheetURL': 'Data sheet URL',
    'approvedComponents.calibrationEquationRequired': 'Calibration equation is a mandatory field',
    'approvedComponents.parameterInUse': 'Parameter in use',
    'approvedComponents.approvedSubComponents': 'Approved Sub Components',
    'approvedComponents.addnewApprovedSubComponents': 'ADD NEW',
    'approvedComponents.subComponentType': 'Sub Component Type',
    'approvedComponents.changeSubComponent': 'Change',
    'approvedComponents.removeSubComponent': 'Remove',
    'approvedComponents.addApprovedSubComponents': 'Add',
    'approvedComponents.updateApprovedSubComponents': 'Update',
    'approvedComponents.subComponentTypeRequired': 'Approved sub component is a mandatory field',
    'approvedComponents.no': 'No',
    'approvedComponents.change': 'CHANGE',
    'approvedComponents.reference': 'Approved Component Reference',
    'approvedComponents.referenceRequired': 'Approved component reference is a mandatory field',
    'modelVersion.units.updatedSuccessfully': 'Units successfully updated to the model version',
    'modelVersion.units.updationFailed': 'Units failed to be updated to the model version',
    'modelVersion.units.requiredParamsMissing': 'Missing parameters',
    'modelVersion.units.fetchFailed': 'Unable to fetch units for model version',
    'modelVersion.units.fetchSuccessfull': 'Successfully fetched units for model version',
    'modelVersion.units.manageUnitVersion': 'Manage Unit Versions',
    'modelVersion.units.unitNumber': 'Unit Serial Number',
    'modelVersion.units.name': 'Name',
    'modelVersion.units.deviceSerialNumber': 'Device Serial Number',
    'modelVersion.units.installationStreetAddress': 'Address',
    'modelVersion.units.installationCity': 'City',
    'modelVersion.units.installationSuburbLocation': 'Suburb/Location',
    'modelVersion.units.installationCountry': 'Country',
    'modelVersion.units.view': 'VIEW',
    'modelVersion.units.showAll': 'SHOW ALL',
    'modelVersion.units.showLess': 'SHOW LESS',
    'modelVersion.units.search': 'Search',
    'modelVersion.units.allocateUnits': 'ALLOCATE UNITS',
    'modelVersion.units.unitsRequire': 'Please select units',
    'model.duplicate.failed': 'Model duplicate failed',
    'model.duplicate.successfull': 'Model duplicate successful',
    'modelVersion.duplicate.failed': 'Model version duplicate failed',
    'modelVersion.duplicate.successfull': 'Model version duplicate successful',
    'model.duplicate.successfull_withoutTelemetryLink': 'Model duplicate successful. However telemetry links was unsuccessful',

    'modelComponentPositions.createdSuccessfully': 'Model component position created successfully',
    'modelComponentPositions.updatedSuccessfully': 'Model component position updated successfully',
    'modelComponentPositions.creationFailed': 'Model component position creation failed',
    'modelComponentPositions.updationFailed': 'Model component position updation failed',
    'modelComponentPositions.duplicateCode': 'Duplicate Model component position name',
    'modelComponentPositions.requiredParamsMissing': 'Required  parameter missing',
    'modelComponentPositions.fetchFailed': 'Data fetch failed',
    'modelComponentPositions.fetchSuccessfull': 'Fetch succesful',
    'modelComponentPositions.deletionFailed': 'Deletion Failed',
    'modelComponentPositions.deletionSuccessfull': 'Deleted successfully',
    'modelComponentPositions.showAll': 'SHOW ALL',
    'modelComponentPositions.showLess': 'SHOW LESS',
    'modelComponentPositions.confirmDelete': 'Are you sure you want to delete model component position?',
    'modelComponentPositions.componentPositions': 'Component positions',
    'modelComponentPositions.search': 'Search',
    'modelComponentPositions.addNew': 'ADD NEW',
    'modelComponentPositions.name': 'Name',
    'modelComponentPositions.description': 'Description',
    'modelComponentPositions.componentType': 'Component type',
    'modelComponentPositions.feedback': 'Feedback',
    'modelComponentPositions.state': 'State',
    'modelComponentPositions.unitOfMeasure': 'Unit of measure',
    'modelComponentPositions.active': 'Active',
    'modelComponentPositions.true': 'True',
    'modelComponentPositions.false': 'False',
    'modelComponentPositions.remove': 'Remove',
    'modelComponentPositions.change': 'Change',
    'modelComponentPositions.nameRequired': 'Name is a mandatory field',
    'modelComponentPositions.edit': 'Edit',
    'modelComponentPositions.addNewModelComponentPosition': 'Add new model component position',
    'modelComponentPositions.pleaseSelectOne': 'Please select one',
    'modelComponentPositions.derived': 'Derived',
    'modelComponentPositions.ccp': 'CCP',
    'modelComponentPositions.ocp': 'OCP',
    'modelComponentPositions.parameterInUse': 'Parameter in use',
    'modelComponentPositions.periodicSummaryCycle': 'Periodic Summary Cycle',
    'modelComponentPositions.needPeriodicSummaryMin': 'Need Periodic Summary Min',
    'modelComponentPositions.needPeriodicSummaryMax': 'Need Periodic Summary Max',
    'modelComponentPositions.periodicSummaryVolume': 'Periodic Summary Volume',
    'modelComponentPositions.periodicSummaryRunHours': 'Periodic Summary Run Hours',
    'modelComponentPositions.stepAdjustable': 'Step Adjustable',
    'modelComponentPositions.stepValue': 'Step Value',
    'modelComponentPositions.Operator': 'Operator',
    'modelComponentPositions.expression': 'Expression',
    'modelComponentPositions.isVirtual': 'Is Virtual',
    'modelComponentPositions.displayComponent': 'Display Component',
    'modelComponentPositions.isRaw': 'Is Raw Component',
    'modelComponentPositions.hasRaw': 'Has a Raw Component',
    'modelComponentPositions.rawComponentName': 'Raw Component Name',
    'modelComponentPositions.parentComponent': 'Parent Component',
    'modelComponentPositions.noData': 'No Data',
    'modelComponentPositions.requireCalibration': 'Require Calibration',
    'modelComponentPositions.subcomponents': 'Subcomponents',
    'modelComponentPositions.title': 'Title',
    'modelComponentPositions.titleRequired': 'Title is a mandatory field',
    'modelComponentPositions.maximumAllowedLength25': 'Exceeded maximum length (25 characters)',

    'unitConfiguration.unitModel': 'Unit Model',
    'TasksAndChecklists.NoAccess': 'You do not have access to checklist and tasks. Please contact your administrator.',
    'command.accessDenied': 'You do not have access to this command. Please contact your administrator.',
    'command.unableToSendCommand': 'Unable to send command.',
    'datatrend.analysis.unableToFetchData': 'Could not retrieve a list of telemetry for specified parameters.',
    'datatrend.analysis.invalidTimestamp': 'Not valid timestamp.',
    'datatrend.analysis.missingMetric': 'At least one metric needs to be supplied.',
    'datatrend.analysis.invalidTimestampRange': 'Invalid date range! Range should be 24 hours only',

    'unauthorised.youAreNotAuthorised': 'You are not authorised to view this page',
    'unauthorised.error401': 'Error 401',
    'unauthorised.returnHome': 'Return Home',

    'datatrend.analysis.invalidLimit': 'Invalid plot points! The range should be between 1 and 1000',
    'datatrend.analysis.minimum': 'Minimum',
    'datatrend.analysis.average': 'Average',
    'datatrend.analysis.maximum': 'Maximum',

    'maintenance.maintenance': 'Maintenance',
    'maintenance.tagout.NoAccess': 'You do not have access to maintainence. Please contact your administrator.',
    'maintenance.tagout.telemetry': 'Telemetry',
    'maintenance.parts': 'Parts',
    'maintenance.tagout.service': 'Service',
    'maintenance.tagout': 'Tag Out',
    'maintenance.tagout.unitisUnlocked': 'Unit is unlocked',
    'maintenance.tagout.unitisLockedby': 'Unit is locked by ',
    'maintenance.tagout.reason': 'Reason for Tagout',
    'maintenance.tagout.tagOutUnit': 'TAG OUT UNIT',
    'maintenance.tagout.tagInUnit': 'TAG IN UNIT',
    'maintenance.tagout.confirm': 'CONFIRM',
    'maintenance.tagout.cancel': 'CANCEL',
    'maintenance.tagout.reasonIsRequired': 'Reason is required',
    'maintenance.tagInConfirmation': 'Are you sure you want to tag in this unit?',
    'maintenance.tagout.requiredParamsMissing': 'Required  parameter missing',
    'maintenance.tagout.failed': 'Unable to tag the unit. Please contact your administrator.',
    'maintenance.tagout.noPermission': 'You do not have permission to tag units. Please contact your administrator.',
    'maintenance.tagout.alreadyTaggedOut': 'Unit already has been tagged out. Please try refreshing your page',
    'maintenance.tagout.alreadyTaggedIn': 'Unit already has been tagged in. Please try refreshing your page',
    'maintenance.tagout.tagoutSuccessful': 'Unit tagged out successfully',
    'maintenance.tagout.taginSuccessful': 'Unit tagged in successfully',
    'maintenance.tagout.isTaggedoutBy': 'Tagged out by ',
    'command.unitTaggedOut': 'Unable to send command as this unit is tagged out! Try refreshing your screen',
    'command.successfull': 'Command sent successfully',
    'maintenance.tagoutHistory': 'Tag Out History',
    'maintenance.tagoutHistory.statusAll': 'All Tags',
    'maintenance.tagoutHistory.statusTaggedOut': 'Tag Out',
    'maintenance.tagoutHistory.statusTaggedIn': 'Tag In',
    'maintenance.tagoutHistory.fetchfailed': 'Unable to fetch tag out history',
    'maintenance.tagoutHistory.fetchSuccessful': 'Tag out history fetched successfully',
    'maintenance.tagoutHistory.unit': 'Unit',
    'maintenance.tagoutHistory.outDateTime': 'Out Date Time',
    'maintenance.tagoutHistory.tagoutUser': 'Tagged Out By',
    'maintenance.tagoutHistory.inDateTime': 'In Date Time',
    'maintenance.tagoutHistory.taginUser': 'Tagged In By',
    'maintenance.tagoutHistory.description': 'Description',
    'maintenance.tagoutHistory.taggoutStatus': 'Tag Status',
    'maintenance.tagoutHistory.allTaggedOutUsers': 'All Tagged Out Users',
    'maintenance.tagoutHistory.allUnits': 'All Units',
    'maintenance.tagoutHistory.filterBy': 'Filter By',
    'maintenance.tagoutHistory.tagoutDate': 'Tagged Out Date',
    'maintenance.tagoutHistory.taginDate': 'Tagged In Date',
    'chart.messageDownloadSize': 'Only 2 days (48 hours) of data can only be viewed at a time due to the size limitation',
    'forgotpassword.requiredParamsMissing': 'Invalid request',
    'forgotpassword.unabletoChangePassword': 'Unable to change your password. Please contact your administrator',
    'forgotpassword.invalidEmail': 'Please provide a valid email',
    'forgotpassword.verificationSent': 'A verification code has been sent',
    'forgotpassword.changeConfirmed': 'You password has been changed successfully',
    'forgotpassword.invalidConfirmationCode': 'Invalid verification code',
    'forgotpassword.codeExpire': 'Your verification has expired. Please request a new code',
    'forgotpassword.tooManyAttempts': 'You have exceeded the maximum attempts for the code. Please contact your administrator',
    'forgotpassword.invalidPassword': 'Invalid password',
    'forgotpassword.codeVerificationFailed': 'Verification code failed. Please contact your administrator',
    'forgotpassword.codeVerificationCodeSendFailed': 'The verification code could not be sent. Please contact your administrator',
    'forgotpassword.invalidUser': 'Your requested email is invalid',

    'forgotpassword.forgotyourpassword': 'Forgot your password?',
    'forgotpassword.iForgotMyPassword': 'I FORGOT MY PASSWORD',
    'forgotpassword.pleaseEnterVerificationEmail': 'Please enter your verification email address',
    'forgotpassword.invalidEmail': 'Unable to verify your email. Please contact your administrator',
    'forgotpassword.resetMyPassord': 'RESET MY PASSWORD',
    'forgotpassword.verificatiionEmailSentEnterCode':
      'A verification code has been sent to your inbox. Please enter the verification code below.',
    'forgotpassword.enterCode': 'Enter Code',
    'forgotpassword.codeRequirement': 'Invalid verification code',
    'forgotpassword.resendCode': 'RESEND CODE',

    'general.passwordRequirement': 'Password must contain at least 1 uppercase, 1 lowercase and 10 characters',
    'general.passwordDoNotMatch': 'Password does not match',
    'general.enterpassword': 'Enter password',
    'general.ConfirmPassword': 'Confirm password',

    'general.contactUsNow': 'CONTACT US NOW',
    'general.needHelp': 'Need help?',
    'login.login': 'Log In',
    'login.error': 'Error logging in',
    'login.forgotPasswordSuccess':
      'Your password has been successfully reset. You can now use your new password to log in to your account.',
    'login.emailAddress': 'Email address',
    'login.enterValidEmail': 'Please enter a valid email',
    'login.password': 'Password',
    'login.enterValidPassword': 'Please enter a valid password',
    'login.logInButton': 'LOG IN',
    'mfa.verificationSentEnterCode': 'A verification code has been sent to your mobile. Please enter the verification code below.',
    'mFa.codeRequirement': 'Invalid verification code',
    'mFa.confirm': 'Confirm',
    'mFA.resendCode': 'RESEND CODE',
    'mFA.enterCode': 'Enter Code',
    'login.missingRequiredFields': 'Missing required fields in request body',
    'login.errorInLoginAttempt': 'Error when attempting to login with supplied username and password.',
    'login.errorInMFAAttempt': 'Error when attempting to authenticate code. Please try log in again!',
    'login.unableToFetchData': 'Unable to fetch data',
    'login.unableToFetchProfiles': 'Unable to fetch profiles',
    'login.unableToFetchOrganisations': 'Unable to fetch organisations',
    'login.unableToLoginContactAdmin': 'Unable to login! Please contact the administrator',
    'login.expiredCodeException': 'Authentication code is expired, Please try log in again',
    'login.errorCodeMismatchException': 'Authentication code could not be verified, Please try log in again',
    'login.userNotConfirmedException': 'User not confirmed, Please contact the administrator',
    'unitModel.cancel': 'Cancel',
    'unitModel.save': 'Save',
    'unit.unitNameMandatory': 'Unit name is mandatory field',
    'unit.unitGpsLatitude': 'Unit GPS latitude is mandatory field',
    'unit.unitGpsLongitude': 'Unit GPS longitude is mandatory field',
    'unit.unableToSave': 'Unable to save data, Try again.',
    'unit.saveSuccessfull': 'Unit details saved successfully',
    'unit.duplicateUnit': 'A unit already exists wit this name',
    'unit.invalidData': 'Invalid Data',
    'unit.latitude': 'Latitude',
    'unit.longitude': 'Longitude',
    'unit.invalidModel': 'Invalid Model',
    'unit.deleteSuccessful': 'Delete successfull',
    'unit.deleteFailed': 'Delete failed',
    'unit.unableToCreateCertificate': 'Unable to create certificate',
    'unit.maximumAttemptMadeAndFail': 'Maximum attempt made',
    'unit.maximumAttemptMadeAndFail': 'Maximum attempt made',
    'unit.addAddressFromGps': 'Add Address From GPS ',
    'unit.description': 'Description',
    'unit.enterDescription': 'Enter description',
    'unit.manufacture': 'Manufacture Details',
    'unit.manufactureid': 'Manufacture Id',
    'unit.manufactureDate': 'Manufacture Date',
    'unit.manufactureDateError': 'Manufacture date must be later than installation date',
    'unit.installationDate': 'Installation Date',
    'unit.installationEngineer': 'Engineer',
    'unit.installationDateError': 'Invalid installation date',
    'unit.installation': 'Installation Details',
    'unit.installationAddress': 'Address',
    'unit.installationStreetAddress': 'Street Address',
    'unit.streetAddressException': 'Street Address is mandatory field',
    'unit.installationCity': 'City',
    'unit.cityException': 'City is mandatory field',
    'unit.installationSuburbLocation': 'Suburb/Location',
    'unit.suburbLocationException': 'Suburb/Location is mandatory field',
    'unit.installationPostCode': 'Post code',
    'unit.postCodeException': 'Post code is mandatory field',
    'unit.installationCountry': 'Country',
    'unit.countryException': 'Country is mandatory field',
    'unit.warrantyStartDate': 'Warranty start date',
    'unit.warrantyEndDate': 'Warranty end date',
    'unit.warrantyError': 'Warrany end date must be grater than start date',
    'unit.region': 'Region/Site',
    'unit.commission': 'Commission Details',
    'unit.commissionDate': 'Commission Date',
    'unit.commissionDateError': 'Commission date cannot be before the installation date/manufacture date',
    'unit.commissionStatus': 'Commission Completed',
    'unit.commissionPowerOnTest': 'Power On Test',
    'unit.commissionWaterSample': 'Water Sample',
    'unit.commissionCallHome': 'Call Home',
    'unit.billOfMaterials': 'Bill Of Materials',
    'unit.update': 'UPDATE',
    'unit.add': 'ADD',
    'unit.remove': 'REMOVE',
    'unit.cancel': 'CANCEL',
    'unit.modelIsMandatory': 'Model is mandatory',
    'unit.modelVersionIsMandatory': 'Model version is mandatory',
    'unit.unitNumberMandatory': 'Unit serial number is mandatory',
    'unit.warrantyStartDateIsMandatory': 'Warranty start date is required',
    'unit.warrantyEndDateIsInvalid': 'Warranty end date cannot be earlier than start date',
    'unit.manufactureDateIsInvalid': 'Manufacture date cannot be after installation date',
    'unit.commissionDateIsInvalid': 'Commission date cannot be before installation date/manufacture date',
    'unit.unitSupervisingManager': 'Unit Supervising Manager',
    'unit.unitSupervisingTechnician': 'Unit Supervising Technician',
    'iotThing.datafetchsucccessfull': 'Data fetch successful',
    'iotThing.datafetchfailed': 'Data fetch failed',
    'iotThing.requiredParamsMissing': 'Required parameter is missing',
    'unit.description': 'Description',
    'unit.invalidCommissionDateError': 'Commission date cannot be before the installation date and Manufacture Date',
    'unit.invalidInstallationDateError': 'Installation date should be grater than Manufacturing Date',
    'unitServiceDetail.editServiceRecord': 'Edit Service Record',
    'unitServiceDetail.createServiceRecord': 'Create a Service Record',
    'unitServiceDetail.backTo': 'BACK TO',
    'unitServiceDetail.back': 'BACK',
    'unitServiceDetail.unitDetails': 'Unit Details',
    'unitServiceDetail.checklist': 'Checklist',
    'unitServiceDetail.dateTime': 'Date and time',
    'unitServiceDetail.user': 'User',
    'unitServiceDetail.unit': 'Unit',
    'unitServiceDetail.warrantyCost': 'Warranty Cost',
    'unitServiceDetail.warrantyHours': 'Warranty Hours',
    'unitServiceDetail.nonWarrantyCost': 'Non-Warranty Cost',
    'unitServiceDetail.nonWarrantyHours': 'Non-Warranty Hours',
    'unitServiceDetail.unitMandatory;': 'Unit is a mandatory field',
    'unitServiceDetail.saveServiceRecord': 'Save service record',
    'unitServiceDetail.cancel': 'Cancel',
    'unitServiceDetail.finalize': 'Finalize',
    'unitServiceDetail.confirmFinalize': 'Do you wish to finalize this service?',
    'unitServiceDetail.edit': 'Edit',
    'unitServiceDetail.unitWarningMsg': 'Unit can not be changed once finalized',
    'unitServiceDetail.taskAndComponentPositionWarningMsg': 'Task and Component Position can not be changed once finalized',
    'unitServiceDetail.serviceStatus': 'Service Status',
    'partService.linkedTasks': 'Linked Tasks',
    'partService.addNew': 'ADD NEW',
    'partService.task': 'Task',
    'partService.component': 'Component',
    'partService.serialNumber': 'Serial Number',
    'partService.noData': 'No tasks have been linked to a service record',
    'partService.edit': 'EDIT',
    'partService.viewMore': 'VIEW MORE',
    'partService.componentPositions': 'Component Positions',
    'partService.warrantyCost': 'Warranty Cost',
    'partService.warrantyHours': 'Warranty Hours',
    'partService.nonWarrantyCost': 'Non-Warranty Cost',
    'partService.nonWarrantyHours': 'Non-Warranty Hours',
    'partService.serviceType': 'Service Type',
    'partService.save': 'SAVE',
    'partService.cancel': 'CANCEL',
    'partService.selecteTask': 'Please select task',
    'partService.selectCompPosition': 'Please select component position',
    'partService.selectServiceType': 'Please select service type',

    'partService.viewLess': 'VIEW LESS',
    'partService.showAll': 'SHOW ALL',
    'partService.showLess': 'SHOW LESS',

    'unitService.requiredParamsMissing': 'Required parameter missing',
    'unitService.fetchFailed': 'Data fetch failed',
    'unitService.fetchSuccessfull': 'Data fetch successful',
    'unitService.createdSuccessfully': 'Unit Service created successfully',
    'unitService.updatedSuccessfully': 'Unit Service updated successfully',
    'unitService.creationFailed': 'Unit Service creation failed',
    'unitService.updationFailed': 'Unit Service updation failed',

    'maintenance.service': 'Service',
    'maintenance.unitService': 'Unit Service',
    'maintenance.unitService.fromDate': 'From Date',
    'maintenance.unitService.toDate': 'To Date',
    'maintenance.unitService.allUnits': 'All Units',
    'maintenance.unitService.allServicedUsers': 'Serviced By All',
    'maintenance.unitService.filterBy': 'Filter By',
    'maintenance.unitService.unit': 'Unit',
    'maintenance.unitService.serviceDate': 'Service Date',
    'maintenance.unitService.servicedBy': 'Serviced By',
    'maintenance.unitService.checklist': 'Checklist',
    'maintenance.unitService.view': 'View',
    'maintenance.unitService.export': 'Export',
    'maintenance.unitService.noData': 'No data is available',
    'maintenance.unitService.unableToExport': 'Unable to export the data please check your filters or try again',

    'filter.fetchFailed': 'Filter data fetch failed',
    'filter.fetchSuccessfull': 'Filter data fetch successful',
    'filter.requiredParamsMissing': 'Filter data missing parameters',

    'dailySummaryReport.unableToExport': 'Unable to download data',
    'dailySummaryReport.noData': 'No data is available',
    'dailySummaryReport.requiredParamsMissing': 'Required parameters missing',
    'dailySummaryReport.timerangenotvalid': 'Search time range is not valid',
    'dailySummaryReport.datafetchsuccessful': 'Summary data fetch successful',
    'dailySummaryReport.datafetchfailed': 'Summary data fetch failed',
    'dailySummaryReport.downloadReport': 'DOWNLOAD REPORT',

    'unitStatistics.requiredParamsMissing': 'Required parameters missing',
    'unitStatistics.fetchFailed': 'Data fetch failed',
    'unitStatistics.fetchSuccessfull': 'Data fetch successful',
    'unitStatistics.createdSuccessfully': 'Statistic settings created successfully',
    'unitStatistics.updatedSuccessfully': 'Statistic settings updated successfully',
    'unitStatistics.creationFailed': 'Statistic settings creation failed',
    'unitStatistics.updationFailed': 'Statistic settings updation failed',

    'unitStatistics.select': 'Select',
    'unitStatistics.statisticType': 'Statistic Type',
    'unitStatistics.save': 'Save',
    'unitStatistics.cancel': 'Cancel',

    'unitStatus.telemtryDataFetchFailed': 'Telemetry data fetch failed',

    'unitStatistics.fetchFailed': '',
    'unitStatistics.last24Hours': 'Last 24 hours',
    'unitStatistics.yesterday': 'Yesterday',
    'unitStatistics.weekToDate': 'Week to date',
    'unitStatistics.monthToDate': 'Month to date',
    'unitStatistics.yearToDate': 'Year to date',
    'unitStatistics.pumpRunHours': 'Pump Run Hours',
    'unitStatistics.pumpRunHoursDescription': 'The number of hours the pump has been running.',
    'unitStatistics.uVLampHours': 'UV lamp hours',
    'unitStatistics.hours': 'hours',
    'unitStatistics.uVLampHoursDescription': 'The number of hours the UV lamp has been running.',
    'unitStatistics.totWaterProduced': 'Total water produced',
    'unitStatistics.litres': 'litres',
    'unitStatistics.totWaterProducedDescription': 'Total water through Flow Meter FT02.',
    'unitStatistics.totWaterFiltered': 'Total water filtered',
    'unitStatistics.totWaterFilteredDescription': 'Total water through Flow Meter FT01',
    'unitStatistics.avgLampRuntime': 'Average lamp run time per hour',
    'unitStatistics.avgLampRuntimeDescription': 'Average lamp run time per hour',
    'unitStatistics.avgPumpRuntime': 'Average pump run time per hour',
    'unitStatistics.avgPumpRuntimeDescription': 'Average pump run time per hour',
    'unitStatistics.averageMinHumidity': 'Average Min Humidity',
    'unitStatistics.averageMaxHumidity': 'Average Max Humidity',
    'unitStatistics.bar': '%',
    'unitStatistics.averageMinHumidityDescription': 'Average minimum humidity',
    'unitStatistics.averageMaxHumidityDescription': 'Average maximum humidity',

    'maintenance.telemetryParts.brand': 'Brand',
    'maintenance.telemetryParts.model': 'Model',
    'maintenance.telemetryParts.unit': 'Unit',
    'maintenance.telemetryParts.componentType': 'Component Type',
    'maintenance.telemetryParts.position': 'Position',
    'maintenance.telemetryParts.type': 'Type',
    'maintenance.telemetryParts.value': 'Value',
    'maintenance.telemetryParts.status': 'Status',
    'maintenance.telemetryParts.filterBy': 'Filter By',
    'maintenance.telemetryParts.search': 'Search',
    'maintenance.telemetryParts.export': 'Export',
    'maintenance.telemetryParts.noData': 'No data is available',
    'maintenance.telemetryParts.unableToExport': 'Unable to export the report',

    'telemetryParts.requiredParamsMissing': 'Required parameter missing',
    'telemetryParts.fetchFailed': 'Data fetch failed',
    'telemetryParts.fetchSuccessfull': 'Data fetch successful',

    'notifications.notificationTypes': 'Notification Types',
    'notifications.userMandatory': 'Please add at least one user',
    'notifications.backtoNotifications': 'BACK TO NOTIFICATIONS',
    'notifications.editNotificationUsers': 'Edit users for notification type ',
    'notifications.selectProfile': '1. Select a profile',
    'notifications.selectUser': '2. Select a user(s) for profile ',
    'notifications.userName': 'User Name',
    'notifications.profileName': 'Profile Name',
    'notifications.notifications': 'Notifications',
    'notifications.noticeBoard': 'Notice Board',
    'notifications.noAccess': 'You do not have access to notice board. Please contact your administrator.',
    'notifications.numberOfAlerts': 'You have number of alerts. Please check your notice board',
    'notifications.notificationType': 'Notification Type',
    'notifications.notificationDateTime': 'Notification Date & Time',
    'notifications.notification': 'Notification',

    'help.help': 'Help',
    'help.faq': 'FAQ',
    'help.notifications': 'Notifications',

    'filters.allUnits': 'All Units',
    'filters.allBrands': 'All Brands',
    'filters.allpositions': 'All Positions',
    'filters.allComponentTypes': 'All Component Types',
    'filters.allTelemetryTypes': 'All Types',
    'filters.allstatus': 'All Status',
    'unitListing.myUnits': 'My units',
    'unitListing.addUnit': 'Add Unit',
    'unitListing.unitName': 'Unit Name',
    'unitListing.anyStatus': 'Any status',
    'unitListing.sortByName': 'Sort by: Name',
    'unitListing.addNewUnit': 'ADD NEW UNIT',
    'unitListing.serialNumber': 'Serial number',
    'unitListing.location': 'Location',
    'unitListing.status': 'Status',
    'unitListing.connection': 'Connection',
    'unitListing.dataNotAvaialable': 'Data not available',
    'unitListing.newAlarms': 'New alarms',
    'unitListing.pendingAlarms': 'Pending alarms',
    'unitListing.warnings': 'Warnings in the last 24h',
    'unitListing.none': 'None',
    'unitListing.sortBySerialNumber': 'Sort by: Serial Number',
    'unitListing.allModels': 'All models',
    'unitListing.allRegions': 'All regions',
    'unitListing.model': 'Model',
    'sidebar.safetyPlan': 'Safety Plan',
    'deviceList.sortByName': 'Sort by: Name',
    'deviceList.sortByStatus': 'Sort by: Status',
    'faqCategories.confirmDelete': 'Are you sure you want to delete this category?',
    'faqCategorys.addFAQCategory': 'Add new FAQ Category',
    'faQuestions.faQuestions': 'FAQ Questions',
    'faQuestions.confirmDelete': 'Are you sure you want to delete this question?',
    'faQuestions.allFAQCategory': 'All FAQ Category',
    'faQuestions.selectFAQCategory': 'Select FAQ Category',
    'faQuestions.faqCategory': 'FAQ Category',
    'faQuestions.question': 'Question',
    'faQuestions.answer': 'Answer',
    'faQuestions.faqCategoryRequired': 'FAQ category is a mandatory field',
    'faQuestions.questionRequired': 'Question is a mandatory field',
    'faQuestions.answerRequired': 'Answer is a mandatory field',
    'faQuestions.addFAQuestion': 'Add new question',

    'organisationRegions.confirmDelete': 'Are you sure you want to delete this region/site?',
    'organisationRegions.organisationRegions': 'Organisation Regions/Sites',
    'organisationControl.backToOrganisations': 'BACK TO ORGANISATIONS',
    'organisationRegions.addOrganisationRegion': 'Add Organisation Region/Sites',

    'modelVersionServicePlan.createdSuccessfully': 'Service plan created successfully',
    'modelVersionServicePlan.updatedSuccessfully': 'Service plan updated successfully',
    'modelVersionServicePlan.creationFailed': 'Service plan creation failed',
    'modelVersionServicePlan.updationFailed': 'Service plan update failed',
    'modelVersionServicePlan.requiredParamsMissing': 'Required Parameter Missing',
    'modelVersionServicePlan.fetchFailed': 'Service plan fetch failed',
    'modelVersionServicePlan.fetchSuccessfull': 'Service plan fetch successful',
    'modelVersionServicePlan.duplicateName': 'A Service plan with the same template already exists for this version',
    'modelVersionServicePlan.confirmApplySchedule':
      'Do you wish to create the service schedules to all units for this version from the starting date?',
    'modelVersionServicePlan.name': 'Service Name',
    'modelVersionServicePlan.description': 'Description',
    'modelVersionServicePlan.checklistName': 'Checklist Template',
    'modelVersionServicePlan.scheduleByPeriod': 'Schedule Period ',
    'modelVersionServicePlan.startDateTime': 'Start Date',
    'modelVersionServicePlan.scheduleEvery': 'Schedule Every',
    'modelVersionServicePlan.applyScheduleModelVersionThreshold': 'APPLY SCHEDULES',
    'modelVersionServicePlan.schedulePlanSuccessfull': 'Schedule successfully created for all units',
    'modelVersionServicePlan.schedulePlanFailed': 'Failed to create schedules',

    'modelVersion.servicePlan': 'Service Plans',

    'modelVersion.name': 'Service name',
    'modelVersion.description': 'Description',
    'modelVersion.startDateTime': 'Start Date',
    'modelVersion.scheduleEvery': 'Schedule Every',
    'modelVersion.scheduleByPeriod': 'Schedule Period',
    'modelVersion.checklistName': 'Checklist Template',

    'modelVersion.servicePlanNameRequired': 'Service Name is required',
    'modelVersion.servicePlanDesriptionRequired': 'Service description is required',
    'modelVersion.servicePlanCheckListTemplateRequired': 'Checklist template is required',
    'modelVersion.servicePlanStartDateRequired': 'Valid start date is required',
    'modelVersion.servicePlanStartDateShouldBeGreaterThanOrEqualToTodaysDate': "Start date must be greater than or equal to today's date",
    'modelVersion.servicePlanSchedulePeriodRequired': 'Schedule Period',
    'modelVersion.servicePlanSchedulePeriodValidate': 'Schedule Period has to be more than 0',
    'unitSettings.enableSerialLogs': 'Enable Serial Logs',
    'unitSettings.rebootFirmware': 'Reboot Firmware',
    'unitSettings.reboot': 'Reboot',
    'unitSettings.emergencyStop': 'Emergency Unit Stop',
    'unitSettings.maintenanceSelfService': 'Maintenance (SelfService)',
    'unitSettings.maintenanceMode': 'Maintenance',

    'releaseNotes.name': 'Name',
    'releaseNotes.version': 'Version',
    'releaseNotes.dateCreated': 'Date created',
    'releaseNotes.published': 'Published',
    'releaseNotes.nameRequired': 'Name is a mandatory field',
    'releaseNotes.versionNoRequired': 'Version number is a mandatory field',
    'releaseNotes.noteRequired': 'Note is a mandatory field',
    'releaseNotes.addReleaseNote': 'Add release note',
    'releaseNotes.notes': 'Notes',
    'releaseNotes.remove': 'Remove',
    'releaseNotes.change': 'Change',
    'releaseNotes.confirmDelete': 'Are you sure you want to delete release note?',

    'unitGlobalSettings.createdSuccessfully': 'Unit global setting created successfully',
    'unitGlobalSettings.updatedSuccessfully': 'Unit global setting updated successfully',
    'unitGlobalSettings.creationFailed': 'Unit global setting creation failed',
    'unitGlobalSettings.updationFailed': 'Unit global setting update failed',
    'unitGlobalSettings.requiredParamsMissing': 'Required Parameter Missing',
    'unitGlobalSettings.fetchFailed': 'Unit global setting fetch failed',
    'unitGlobalSettings.fetchSuccessfull': 'Unit global setting fetch successful',
    'unitGlobalSettings.duplicateCode': 'Unit global setting with the same code already exists',
    'unitGlobalSettings.deletedSuccessfully': 'Record deleted successfully',
    'unitGlobalSettings.deletetionFailed': 'Record deletion failed',

    'unitGlobalSettings.name': 'Name',
    'unitGlobalSettings.description': 'Description',
    'unitGlobalSettings.nameRequired': 'Name is required',
    'unitGlobalSettings.codeMandatory': 'Code is a mandatory field',
    'unitGlobalSettings.translationCodeMandatory': 'Translation code is a mandatory field',
    'unitGlobalSettings.defaultValueMandatory': 'Default value is a mandatory field',
    'unitGlobalSettings.maxValuemustbeGrater': 'Max value must be grater than Min value',
    'unitGlobalSettings.defaultValueWithinRange': 'Default value must be within Min and Max range',
    'unitGlobalSettings.change': 'Change',
    'unitGlobalSettings.delete': 'Delete',

    'unitGlobalSettings.code': 'Code',
    'unitGlobalSettings.defaultValue': 'Default value',
    'unitGlobalSettings.defaultValue': 'Default value',
    'unitGlobalSettings.minValue': 'Min value',
    'unitGlobalSettings.maxValue': 'Max value',
    'unitGlobalSettings.translationCode': 'Translation Code',
    'unitGlobalSettings.save': 'Save',
    'unitGlobalSettings.cancel': 'Cancel',
    'unitGlobalSettings.uOM': 'Unit of Measure',
    'unitGlobalSettings.confirmDeleteUnitGlobalSettings': 'Do you want to remove settings from all the units linked to model version?',

    'modelVersion.sequenceVersion': 'Sequence Version',
    'sequenceVersionManagement.search': 'Search',
    'sequenceVersionManagement.addNew': 'Add New',
    'sequenceVersionManagement.name': 'Name',
    'sequenceVersionManagement.versionNo': 'Version No',
    'sequenceVersionManagement.verifyErrorCode': 'Verify Error Code',
    'sequenceVersionManagement.verifyWarningCode': 'Verify Warning Code',
    'sequenceVersionManagement.startSequence': 'Start Sequence',
    'sequenceVersionManagement.published': 'Published',
    'sequenceVersionManagement.datePublished': 'Date Published',
    'sequenceVersionManagement.publishedUser': 'Published User',
    'sequenceVersionManagement.active': 'Active',
    'sequenceVersionManagement.change': 'CHANGE',
    'sequenceVersionManagement.sequenceVersion': 'Sequence Version',
    'sequenceVersionManagement.confirmDelete': 'Are you sure you want to delete this sequence file?',
    'sequenceVersionManagement.edit': 'Edit',
    'sequenceVersionManagement.newSequenceVersion': 'New Sequence Version',
    'sequenceVersionManagement.nameMandatory': 'Version name is a mandatory field',
    'sequenceVersionManagement.versionNoMandatory': 'Version number is a mandatory field',
    'sequenceVersionManagement.verifyErrorCodeMandatory': 'Error code is a mandatory field',
    'sequenceVersionManagement.verifyErrorCodeMandatory': 'Warning code is a mandatory field',
    'sequenceVersionManagement.startStateMandatory': 'Sequence start state is a mandatory field',
    'sequenceVersionManagement.back': 'BACK',
    'sequenceVersionManagement.view': 'View',
    'sequenceVersionManagement.publish': 'publish',
    'sequenceVersionManagement.details': 'Details',
    'sequenceVersionManagement.name': 'Name',
    'sequenceVersionManagement.versionNo': 'Version Number',
    'sequenceVersionManagement.description': 'Description',
    'sequenceVersionManagement.verifyErrorCode': 'Verify Error Code',
    'sequenceVersionManagement.verifyWarningCode': 'Verify Warning Code',
    'sequenceVersionManagement.sequenceStartState': 'Sequence Start State',
    'sequenceVersionDetailFile.showAll': 'Show:All',
    'sequenceVersionDetailFiles.sequenceFiles': 'Sequence Files',
    'sequenceVersionDetailFiles.subState': 'Sub State',
    'sequenceVersionDetailFiles.addNew': 'Add new',
    'sequenceVersionDetailFiles.sequenceName': 'Sequence Name',
    'sequenceVersionDetailFiles.hasSuperState': 'Super State',
    'sequenceVersionDetailFiles.hasSuperState': 'Super State',
    'sequenceVersionDetailFiles.superStateName': 'Super State Name',
    'sequenceVersionDetailFiles.noData': 'No Data',
    'sequenceVersionDetailFiles.remove': 'Remove',
    'sequenceVersionDetailFiless.view': 'View',
    'sequenceVersionDetailFiless.change': 'Change',
    'sequenceVersionManagement.saveChanges': 'Save Changes',
    'sequenceVersionManagement.cancel': 'Cancel',
    'sequenceVersionManagement.sequenceState': 'Sequence State',
    'sequenceVersionFilesManagement.sequenceStateMandatory': 'Sequence State is mandatory',
    'sequenceVersionFilesManagement.backtoSequenceVersion': 'BACK TO SEQUENCE VERSION',
    'sequenceVersionFilesManagement.jsonTextMandatory': 'Sequence JSON is mandatory',
    'sequenceVersionFilesManagement.editSequenceVersionFile': 'Edit Sequence Version File',
    'sequenceVersionManagement.newSequenceVersionFile': 'New Sequence Version File',
    'sequenceVersionFilesManagement.newSequenceVersionFile': 'New Sequence Version File',
    'sequenceVersionFilesManagement.sequenceState': 'Sequence State',
    'sequenceVersionFilesManagement.subState': 'Sub State',
    'sequenceVersionFilesManagement.superState': 'Super State',
    'sequenceVersionFilesManagement.waitFor': 'Wait For',
    'sequenceVersionFilesManagement.jsonText': 'JSON Text',
    'sequenceVersionFilesManagement.saveChanges': 'Save Changes',
    'sequenceVersionFilesManagement.cancel': 'Cancel',
    'sequenceVersionManagement.versionNoFomatError': 'Version number format should be 1.0.0',
    'sequenceVersionFilesManagement.sampleSequenceFile': 'Sample sequence file',
    'modelVersionManagement.sequenceVersion': 'Sequence Version',
    'sequenceVersion.createdSuccessfully': 'Sequence version created successfully',
    'sequenceVersion.updatedSuccessfully': 'Sequence version udpated successfully',
    'sequenceVersion.creationFailed': 'Sequence version creation failed',
    'sequenceVersion.updationFailed': 'Sequence version updation failed',
    'sequenceVersion.invalidVersionNo': 'Invalid sequence version number',
    'sequenceVersion.duplicateVersionNo': 'Duplicate sequence version',
    'sequenceVersion.requiredParamsMissing': 'Missing parameter for your request',
    'sequenceVersion.fetchFailed': 'Sequence version fetch failed',
    'sequenceVersion.fetchSuccessfull': 'Sequence version fetch successfull',
    'sequenceVersion.fileDeletionSuccessfull': 'Sequence version deleted successfully',
    'sequenceVersion.fileDeletionFailed': 'Sequence version deletion failed',
    'sequenceVersion.publishSuccessfull': 'Sequence version published successfully',
    'sequenceVersion.publishFailed': 'Sequence version publish failed',
    'sequenceVersion.copySuccessfull': 'Sequence version copied successfully',
    'sequenceVersion.copyFailed': 'Sequence version copy failed',
    'sequenceVersion.fileCreatedSuccessfully': 'Sequence version file created successfully',
    'sequenceVersion.fileUpdatedSuccessfully': 'Sequence version file updated successfully',
    'sequenceVersion.fileCreationFailed': 'Sequence version file creation failed',
    'sequenceVersion.fileUpdationFailed': 'Sequence version file updation failed',
    'sequenceVersion.fileDuplicateSequence': 'Sequence version file is a duplicate',
    'sequenceVersion.alreadyPublishedSequence': 'Sequence version already published',
    'sequenceVersionManagement.predefinedSequenceVersionFileMandatory': 'Sequence version file missing for predefined states',
    'sequenceVersionManagement.duplicate': 'Duplicate',
    'sequenceVersionManagement.confirmCopy': 'Are you sure you want to duplicate this sequence version?',
    'tabNavigation.staticData': 'Static Data',

    'modelVersion.periodicSummarySettings': 'Periodic SummarySettings',
    'modelVersion.periodicSummarySettingsConfirmDelete': 'Are you sure you want to delete this periodic summary setting?',
    'periodicSummarySettings.name': 'Name',
    'periodicSummarySettings.code': 'Code',
    'periodicSummarySettings.expression': 'Expression Value',
    'periodicSummarySettings.description': 'Description',
    'periodicSummarySettings.active': 'Active',
    'periodicSummarySettings.change': 'Change',
    'periodicSummarySettings.delete': 'Delete',
    'periodicSummarySettings.nameRequired': 'Name is required',
    'periodicSummarySettings.codeRequired': 'Code is required',
    'periodicSummarySettings.expressionRequired': 'Expression value is required',
    'periodicSummarySettings.createdSuccessfully': 'Periodic summary setting created successfully',
    'periodicSummarySettings.updatedSuccessfully': 'Periodic summary setting updated successfully',
    'periodicSummarySettings.creationFailed': 'Periodic summary setting creation failed',
    'periodicSummarySettings.updationFailed': 'Periodic summary setting update failed',
    'periodicSummarySettings.requiredParamsMissing': 'Required Parameter Missing',
    'periodicSummarySettings.fetchFailed': 'Periodic summary setting fetch failed',
    'periodicSummarySettings.fetchSuccessfull': 'Periodic summary setting fetch successful',
    'periodicSummarySettings.duplicateCode': 'Periodic summary setting with the same code already exists',
    'periodicSummarySettings.deletedSuccessfully': 'Record deleted successfully',
    'periodicSummarySettings.deletetionFailed': 'Record deletion failed',

    'staticData.addNew': 'ADD STATIC DATA',
    'staticData.staticData': 'Static Data',
    'staticData.edit': 'Edit',
    'staticData.name': 'Name',
    'staticData.description': 'Description',
    'staticData.isPublished': 'Is Published',
    'staticData.publishedDate': 'Published Date',
    'staticData.lastUpdated': 'Last Updated',
    'staticData.nameMandatory': 'Name',
    'staticData.enterDescription': 'Enter static data description',
    'staticData.isActive': 'Is Data Active',
    'staticData.': 'Static Data',
    'staticData.addNewTelemetryData': 'ADD TELEMETRY DATA',
    'staticData.componentPosition': 'Component Position',
    'staticData.telemetryValue': 'Telemetry Value',
    'staticData.telemetryStartDate': 'Start Date',
    'staticData.telemetryEndDate': 'End Date',
    'staticData.isPublishable': 'Is Publishable',
    'staticData.telemetryData': 'Telemetry Data',
    'staticData.remove': 'Remove',
    'staticData.edit': 'Edit',
    'staticData.add': 'Add',
    'staticData.cancel': 'Cancel',
    'staticData.update': 'Update',
    'staticData.yes': 'Yes',
    'staticData.no': 'No',
    'staticData.save': 'SAVE',
    'staticData.componentRequired': 'Component is required',
    'staticData.telemetryValueRequired': 'Telemetry value is required',
    'staticData.telemetryStartDateRequired': 'Telemetry start date is required',
    'staticData.telemetryEndDateInvalid': 'Telemetry end date is invalid',
    'staticData.componentName': 'Component Name',
    'unitStaticData.requiredParamsMissing': 'Missing parameters',
    'unitStaticData.fetchFailed': ' Failed to fetch data',
    'unitStaticData.fetchSuccessfull': 'Fetch successful',
    'unitStaticData.createdSuccessfully': 'Static data created successfully',
    'unitStaticData.updatedSuccessfully': 'Static data updated successfully',
    'unitStaticData.creationFailed': 'Static data creation failed',
    'unitStaticData.updationFailed': 'Static data update failed',
    'modelComponentPositions.staticComponent': 'Is Static Component',
    null: ' ',
    save: 'Save'
  },
  es: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',
    baq: 'Basque',

    'common.yes': 'si',
    'common.no': 'No',

    'processFlow.eventLog': 'REGISTRO DE EVENTOS',
    'processFlow.allEventsLog': 'Todos los registros de eventos.',

    'commandPanel.iotCommands': 'IoT COMANDOS',
    'commandPanel.quickCommands': 'Comandos rápidos',

    'settings.language': 'Idioma  ',

    'deviceHeader.back': 'atrás',
    'deviceHeader.offline': 'Desconectada',
    'deviceHeader.online': 'En línea',
    'deviceHeader.location': 'Ubicación',
    'deviceHeader.switchLocalTimezone': 'Cambiar a zona horaria local',
    'deviceHeader.switchUnitTimezone': 'Cambiar a zona horaria de la unidad',

    'tabNavigation.processFlow': 'Flujo del proceso',
    'tabNavigation.dataTrendAnalysis': 'Análisis de tendencia de datos',

    'sequence.diagram': 'DIAGRAMA',

    'toggleSwitch.lableView': 'Cambiar a vista de etiqueta',

    'sequenceThresholds.thresholds': 'UMBRALES',
    'sequenceThresholds.allThresholds': 'Todos los umbrales',

    'sensorTable.realTimeSensor': 'SENSOR DE TIEMPO REAL',
    'sensorTable.ct01': 'Transmisor de conductividad (+ temperatura)',
    'sensorTable.ft01': 'Flujo post-bomba',
    'sensorTable.ft02': 'Flujo UV',
    'sensorTable.pt01': 'Transmisor de presión posterior a la bomba',
    'sensorTable.pt01pt02': 'Diferencia de presión en prefiltros',
    'sensorTable.pt02': 'Transmisor de presión premembrana',
    'sensorTable.pt02pt03': 'La presión difiere a través de la membrana',
    'sensorTable.pt03': 'Transmisor de presión postmembrana',
    'sensorTable.tb01': 'Transmisor de turbidez entrante',
    'sensorTable.tb02': 'Transmisor de turbidez premembrana',
    'sensorTable.tb03': 'Transmisor de turbidez postmembrana',
    'sensorTable.uvi01': 'Intensidad UV',
    'sensorTable.uvt01': 'Transmisividad UV',
    'sensorTable.lt01': 'Transmisor de nivel analógico tanque 1',
    'sensorTable.lt02': 'Transmisor de nivel analógico tanque 2',
    'sensorTable.lsl01': 'Tanque 1, interruptor de bajo nivel',
    'sensorTable.cs01': 'Sensor de corriente SBC',
    'sensorTable.cs02': 'Sensor de corriente del sistema',
    'sensorTable.tk01': 'Transmisor de nivel analógico tanque 1',
    'sensorTable.tk02': 'Transmisor de nivel analógico tanque 2',

    'deviceSchematic.diagramView': 'Cambiar a vista de diagrama',
    'deviceSchematic.sensorView': 'Cambiar a la vista del sensor',

    'command.initialise': 'inicializar',
    'command.normalprocessing': 'Procesamiento normal',
    'command.membraneforwardflush': 'Descarga directa',
    'command.membranebackflush': 'Lavado de espalda',
    'command.flush': 'Rubor',
    'command.tank1drain': 'Drenaje T1',
    'command.prefilterbackflush': 'Prefiltro de lavado a contracorriente',
    'command.dit': 'Prueba de integridad directa',
    'command.run': 'correr',
    'command.wait': 'Espere',
    'command.emergencystop': 'Parada de emergencia',
    'command.transition': 'Transición',
    'command.stop': 'Detener',
    'command.start': 'comienzo',
    'command.waitforaws': 'Espera a AWS',
    'command.hardwarefault': 'Falla de hardware',
    'command.alarm': 'Alarma',
    'command.none': 'Ninguno',
    'command.commandfailedtosend': 'Comando - Error al enviar',
    'command.commandsuccessfullysent': 'Comando: enviado correctamente',
    'command.areyousureyouwanttoactionthecommand': '¿Estás seguro de que quieres activar el comando?',

    'settings.settings': 'ajustes',
    'settings.profile': 'Perfil',

    'thresholdTable.sensor': 'Sensor',
    'thresholdTable.units': 'Unidades',
    'thresholdTable.min': 'mínimo',
    'thresholdTable.low': 'bajo',
    'thresholdTable.criticallow': 'Crítico bajo',
    'thresholdTable.criticalhigh': 'Crítico alto',
    'thresholdTable.high': 'alto',
    'thresholdTable.max': 'máximo',
    'thresholdTable.value': 'Valor',
    'thresholdTable.timestamp': 'Marca de tiempo',
    'thresholdTable.event': 'Estado',

    'units.unitsinoperation': 'Unidades en operación',
    'units.location': 'ubicación',

    'chart.removeChart': 'Eliminar gráfico',
    'chart.chart': 'Gráfico',
    'chart.nodataavailable': 'No hay datos disponibles actualmente',
    'chart.selectoreditchart': 'Seleccionar / editar variables para gráfico',
    'chart.sensors': 'Sensor (es)',
    'chart.datetimefrom': '`Fecha / Hora desde',
    'chart.datetimeto': '`Fecha / hora hasta',
    'chart.lowerthreshold': 'Umbral inferior',
    'chart.upperthreshold': 'Umbral superior',
    'chart.addchart': 'Agregar gráfico',
    'chart.addanotherchart': 'Agregar otro gráfico'
  },
  de: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',

    'common.yes': 'Ja',
    'common.no': 'Nein',

    'processFlow.eventLog': 'EREIGNISPROTOKOLL',
    'processFlow.allEventsLog': 'Alle Ereignisprotokolle',

    'commandPanel.iotCommands': 'IoT-BEFEHLE',
    'commandPanel.quickCommands': 'Schnelle Befehle',

    'settings.language': 'Sprache  ',

    'deviceHeader.back': 'Zurück',
    'deviceHeader.offline': 'Offline',
    'deviceHeader.online': 'Online',
    'deviceHeader.location': 'Ort',
    'deviceHeader.switchLocalTimezone': 'Zur lokalen Zeitzone wechseln',
    'deviceHeader.switchUnitTimezone': 'Zur Zeitzone wechseln',

    'tabNavigation.processFlow': 'Prozessablauf',
    'tabNavigation.dataTrendAnalysis': 'Datentrendanalyse',

    'sequence.diagram': 'DIAGRAMM',

    'toggleSwitch.lableView': 'Zur Etikettenansicht wechseln',

    'sequenceThresholds.thresholds': 'SCHWELLEN',
    'sequenceThresholds.allThresholds': 'Alle Schwellenwerte',

    'sensorTable.realTimeSensor': 'ECHTZEIT-SENSOR',
    'sensorTable.ct01': 'Leitfähigkeitsmessumformer (+ Temperatur)',
    'sensorTable.ft01': 'Durchflussmenge nach der Pumpe',
    'sensorTable.ft02': 'UV-Flussrate',
    'sensorTable.pt01': 'Drucktransmitter nach der Pumpe',
    'sensorTable.pt01pt02': 'Druckunterschied zwischen den Vorfiltern',
    'sensorTable.pt02': 'Pre-Membrandrucktransmitter',
    'sensorTable.pt02pt03': 'Druckunterschied über die Membran',
    'sensorTable.pt03': 'Druckmessumformer nach der Membran',
    'sensorTable.tb01': 'Messumformer für ankommende Trübung',
    'sensorTable.tb02': 'Trübungsmessumformer vor der Membran',
    'sensorTable.tb03': 'Trübungsmessumformer nach der Membran',
    'sensorTable.uvi01': 'UV-Intensität',
    'sensorTable.uvt01': 'UV-Durchlässigkeit',
    'sensorTable.lt01': 'Analoger Füllstandstransmitter für Tank 1',
    'sensorTable.lt02': 'Analoger Füllstandstransmitter für Tank 2',
    'sensorTable.lsl01': 'Tank 1, Schalter für niedrigen Füllstand',
    'sensorTable.cs01': 'SBC-Stromsensor',
    'sensorTable.cs02': 'Systemstromsensor',
    'sensorTable.tk01': 'Analoger Füllstandstransmitter für Tank 1',
    'sensorTable.tk02': 'Analoger Füllstandstransmitter für Tank 2',

    'deviceSchematic.diagramView': 'Zur Diagrammansicht wechseln',
    'deviceSchematic.sensorView': 'Wechseln Sie in die Sensoransicht',

    'command.initialise': 'initialisieren',
    'command.normalprocessing': 'Normale Verarbeitung',
    'command.membraneforwardflush': 'Forward Flush',
    'command.membranebackflush': 'Zurück spülen',
    'command.flush': 'Spülen',
    'command.tank1drain': 'T1 ablassen',
    'command.prefilterbackflush': 'Rückspülung vorfiltern',
    'command.dit': 'Direkte Integritätsprüfung',
    'command.run': 'Lauf',
    'command.wait': 'Warten',
    'command.emergencystop': 'Not-Halt',
    'command.transition': 'Überleitung',
    'command.stop': 'Halt',
    'command.start': 'Start',
    'command.waitforaws': 'Warten Sie auf AWS',
    'command.hardwarefault': 'Hardwarefehler',
    'command.alarm': 'Alarm',
    'command.none': 'Keiner',
    'command.commandfailedtosend': 'Befehl - Senden fehlgeschlagen',
    'command.commandsuccessfullysent': 'Befehl - Erfolgreich gesendet',
    'command.areyousureyouwanttoactionthecommand': 'Möchten Sie den Befehl wirklich ausführen?',

    'settings.settings': 'Die Einstellungen',
    'settings.profile': 'Profil',

    'thresholdTable.sensor': 'Sensor',
    'thresholdTable.units': 'Einheiten',
    'thresholdTable.min': 'Min',
    'thresholdTable.low': 'Niedrig',
    'thresholdTable.criticallow': 'Kritisches Tief',
    'thresholdTable.criticalhigh': 'Kritisches Hoch',
    'thresholdTable.high': 'Hoch',
    'thresholdTable.max': 'Maximal',
    'thresholdTable.value': 'Wert',
    'thresholdTable.timestamp': 'Zeitstempel',
    'thresholdTable.event': 'Event',

    'units.unitsinoperation': 'Geräte in Betrieb',
    'units.location': 'Ort',

    'chart.removeChart': 'Diagramm entfernen',
    'chart.chart': 'Diagramm',
    'chart.nodataavailable': 'Derzeit sind keine Daten verfügbar',
    'chart.selectoreditchart': 'Variablen für Diagramm auswählen / bearbeiten',
    'chart.sensors': 'Sensor (en)',
    'chart.datetimefrom': 'Datum / Uhrzeit von',
    'chart.datetimeto': 'Datum / Uhrzeit bis',
    'chart.lowerthreshold': 'Untere Schwellenwert',
    'chart.upperthreshold': 'Oberer Schwellenwert',
    'chart.addchart': 'Diagramm hinzufügen',
    'chart.addanotherchart': 'Fügen Sie ein weiteres Diagramm hinzu'
  },
  da: {
    en: 'English',
    es: 'español',
    fr: 'Française',
    de: 'Deutsche',
    da: 'dansk',
    baq: 'Basque',

    'common.yes': 'Ja',
    'common.no': 'Ingen',

    'processFlow.eventLog': 'HÆNDELSESLOG',
    'processFlow.allEventsLog': 'Alle hændelseslogfiler',

    'commandPanel.iotCommands': 'IoT-kommandoer',
    'commandPanel.quickCommands': 'Hurtige kommandoer',

    'settings.language': 'Sprog  ',

    'deviceHeader.back': 'Tilbage',
    'deviceHeader.offline': 'Offline',
    'deviceHeader.online': 'Online',
    'deviceHeader.location': 'Beliggenhed',
    'deviceHeader.switchLocalTimezone': 'Skift til lokal tidszone',
    'deviceHeader.switchUnitTimezone': 'Skift til enhedens tidszone',

    'tabNavigation.processFlow': 'Procesflow',
    'tabNavigation.dataTrendAnalysis': 'Data Trend analyse',

    'sequence.diagram': 'DIAGRAM',

    'toggleSwitch.lableView': 'Skift til etikettevisning',

    'sequenceThresholds.thresholds': 'tærskler',
    'sequenceThresholds.allThresholds': 'Alle tærskler',

    'sensorTable.realTimeSensor': 'Realtidssensor',
    'sensorTable.ct01': 'Konduktivitetssender (+ temperatur)',
    'sensorTable.ft01': 'Strømningshastighed efter pumpen',
    'sensorTable.ft02': 'UV-strømningshastighed',
    'sensorTable.pt01': 'Tryktransmitter efter pumpen',
    'sensorTable.pt01pt02': 'Trykket adskiller sig over forfiltrene',
    'sensorTable.pt02': 'Pre-membran tryktransmitter',
    'sensorTable.pt02pt03': 'Trykket adskiller sig over membranen',
    'sensorTable.pt03': 'Tryktransmitter efter membran',
    'sensorTable.tb01': 'Indgående turbiditetssender',
    'sensorTable.tb02': 'Pre-membran Turbiditetssender',
    'sensorTable.tb03': 'Post-membran turbiditetssender',
    'sensorTable.uvi01': 'UV-intensitet',
    'sensorTable.uvt01': 'UV-transmission',
    'sensorTable.lt01': 'Tank 1 sender på analogt niveau',
    'sensorTable.lt02': 'Tank 2 sender på analogt niveau',
    'sensorTable.lsl01': 'Tank 1, lavt niveau switch',
    'sensorTable.cs01': 'SBC strømføler',
    'sensorTable.cs02': 'Systemstrømsensor',
    'sensorTable.tk01': 'Tank 1 sender på analogt niveau',
    'sensorTable.tk02': 'Tank 2 sender på analogt niveau',

    'deviceSchematic.diagramView': 'Skift til diagramvisning',
    'deviceSchematic.sensorView': 'Skift til sensorvisning',

    'command.initialise': 'Initialiser',
    'command.normalprocessing': 'Normal behandling',
    'command.membraneforwardflush': 'Spol fremad',
    'command.membranebackflush': 'Ryg flush',
    'command.flush': 'Flush',
    'command.tank1drain': 'T1 afløb',
    'command.prefilterbackflush': 'Forfilter tilbage skyl',
    'command.dit': 'Direkte integritetstest',
    'command.run': 'Løb',
    'command.wait': 'Vente',
    'command.emergencystop': 'Nødstop',
    'command.transition': 'Overgang',
    'command.stop': 'Hold op',
    'command.start': 'Start',
    'command.waitforaws': 'Vent på AWS',
    'command.hardwarefault': 'Hardwarefejl',
    'command.alarm': 'Alarm',
    'command.none': 'Ingen',
    'command.commandfailedtosend': 'Kommando - sendes ikke',
    'command.commandsuccessfullysent': 'Kommando - sendt',
    'command.areyousureyouwanttoactionthecommand': 'Er du sikker på, at du vil handle kommandoen',

    'settings.settings': 'Indstillinger',
    'settings.profile': 'Profil',

    'thresholdTable.sensor': 'Sensor',
    'thresholdTable.units': 'Enheder',
    'thresholdTable.min': 'Minimum',
    'thresholdTable.low': 'Lav',
    'thresholdTable.criticallow': 'Kritisk lav',
    'thresholdTable.criticalhigh': 'Kritisk høj',
    'thresholdTable.high': 'Høj',
    'thresholdTable.max': 'Maksimum',
    'thresholdTable.value': 'Værdi',
    'thresholdTable.timestamp': 'Tidsstempel',
    'thresholdTable.event': 'Event',

    'units.unitsinoperation': 'Enheder i drift',
    'units.location': 'Beliggenhed',

    'chart.removeChart': 'Fjern diagram',
    'chart.chart': 'Diagram',
    'chart.nodataavailable': 'Der er i øjeblikket ingen tilgængelige data',
    'chart.selectoreditchart': 'Vælg / rediger variabler til diagram',
    'chart.sensors': 'Føler (e)',
    'chart.datetimefrom': 'Dato / tid fra',
    'chart.datetimeto': 'Dato / tid til',
    'chart.lowerthreshold': 'Nedre tærskel',
    'chart.upperthreshold': 'Øvre tærskel',
    'chart.addchart': 'Tilføj diagram',
    'chart.addanotherchart': 'Tilføj et andet diagram'
  },
  fr: {
    en: 'English',
    es: 'español',
    fr: 'Française',
    de: 'Deutsche',
    da: 'dansk',
    baq: 'Basque',

    'common.yes': 'Oui',
    'common.no': 'Non',

    'processFlow.eventLog': 'JOURNAL DES ÉVÉNEMENTS',
    'processFlow.allEventsLog': 'Tous les journaux d`événements',

    'commandPanel.iotCommands': 'COMMANDES IoT',
    'commandPanel.quickCommands': 'Commandes rapides',

    'settings.language': 'Langue  ',

    'deviceHeader.back': 'Retour',
    'deviceHeader.switchLocalTimezone': 'Passer au fuseau horaire local',
    'deviceHeader.switchUnitTimezone': 'Passer au fuseau horaire de l`unité',
    'deviceHeader.offline': 'Hors ligne',
    'deviceHeader.online': 'En ligne',
    'deviceHeader.location': 'Emplacement',

    'tabNavigation.processFlow': 'Flux de processus',
    'tabNavigation.dataTrendAnalysis': 'Analyse des tendances des données',

    'sequence.diagram': 'DIAGRAMME',

    'toggleSwitch.lableView': 'Passer en vue d`étiquette',

    'sequenceThresholds.thresholds': 'SEUILS',
    'sequenceThresholds.allThresholds': 'Tous les seuils',

    'sensorTable.realTimeSensor': 'CAPTEUR EN TEMPS RÉEL',
    'sensorTable.ct01': 'Transmetteur de conductivité (+ température)',
    'sensorTable.ft01': 'Débit post-pompe',
    'sensorTable.ft02': 'Débit UV',
    'sensorTable.pt01': 'Transmetteur de pression post-pompe',
    'sensorTable.pt01pt02': 'Différence de pression entre les préfiltres',
    'sensorTable.pt02': 'Transmetteur de pression pré-membrane',
    'sensorTable.pt02pt03': 'Différence de pression à travers la membrane',
    'sensorTable.pt03': 'Transmetteur de pression post-membrane',
    'sensorTable.tb01': 'Émetteur de turbidité entrant',
    'sensorTable.tb02': 'Transmetteur de turbidité pré-membrane',
    'sensorTable.tb03': 'Transmetteur de turbidité post-membrane',
    'sensorTable.uvi01': 'Intensité UV',
    'sensorTable.uvt01': 'Transmissivité UV',
    'sensorTable.lt01': 'Transmetteur de niveau analogique réservoir 1',
    'sensorTable.lt02': 'Transmetteur de niveau analogique Tank 2',
    'sensorTable.lsl01': 'Réservoir 1, interrupteur de bas niveau',
    'sensorTable.cs01': 'Capteur de courant SBC',
    'sensorTable.cs02': 'Capteur de courant du système',
    'sensorTable.tk01': 'Transmetteur de niveau analogique réservoir 1',
    'sensorTable.tk02': 'Transmetteur de niveau analogique Tank 2',

    'deviceSchematic.diagramView': 'Passer en vue diagramme',
    'deviceSchematic.sensorView': 'Passer en vue capteur',

    'command.initialise': 'initialiser',
    'command.normalprocessing': 'Traitement normal',
    'command.membraneforwardflush': 'Flush vers l`avant',
    'command.membranebackflush': 'Rinçage arrière',
    'command.flush': 'Affleurer',
    'command.tank1drain': 'Drain T1',
    'command.prefilterbackflush': 'Préfiltre à contre-courant',
    'command.dit': "Test d'intégrité direct",
    'command.run': 'Courir',
    'command.wait': 'Attendez',
    'command.emergencystop': "Arrêt d'urgence",
    'command.transition': 'Transition',
    'command.stop': 'Arrêtez',
    'command.start': 'Début',
    'command.waitforaws': 'Attendez AWS',
    'command.hardwarefault': 'Défaut matériel',
    'command.alarm': 'Alarme',
    'command.none': 'aucun',
    'command.commandfailedtosend': 'Commande - Échec de l`envoi',
    'command.commandsuccessfullysent': 'Commande - Envoi réussi',
    'command.areyousureyouwanttoactionthecommand': 'Voulez-vous vraiment exécuter la commande',

    'settings.settings': 'Réglages',
    'settings.profile': 'Profil',

    'thresholdTable.sensor': 'Capteur',
    'thresholdTable.units': 'Unités',
    'thresholdTable.min': 'Le minimum',
    'thresholdTable.low': 'Faible',
    'thresholdTable.criticallow': 'Critique faible',
    'thresholdTable.criticalhigh': 'Critique élevé',
    'thresholdTable.high': 'Haut',
    'thresholdTable.max': 'Max',
    'thresholdTable.value': 'Valeur',
    'thresholdTable.timestamp': 'Horodatage',
    'thresholdTable.event': 'Evemt',

    'units.unitsinoperation': 'Unités en fonctionnement',
    'units.location': 'emplacement',

    'chart.removeChart': 'Supprimer le graphique',
    'chart.chart': 'Graphique',
    'chart.nodataavailable': 'Aucune donnée actuellement disponible',
    'chart.selectoreditchart': 'Sélectionner / modifier des variables pour le graphique',
    'chart.sensors': 'Capteur (s)',
    'chart.datetimefrom': 'Date / heure de',
    'chart.datetimeto': 'Date / heure de',
    'chart.lowerthreshold': 'Seuil inférieur',
    'chart.upperthreshold': 'Seuil supérieur',
    'chart.addchart': 'Ajouter un graphique',
    'chart.addanotherchart': 'Ajouter un autre graphique'
  },
  baq: {
    en: 'English',
    es: 'español',
    fr: 'Française',
    de: 'Deutsche',
    da: 'dansk',
    baq: 'Basque',
    'command.ditp': 'Zuzeneko osotasun testaren gainditzea',
    'command.dit': 'Zuzeneko osotasun testa',
    'unitreports.reportReady': 'Zure txostena jaisteko prest dago',
    'unitreports.reportinEmail': 'Zure txostena erregistratutako posta elektronikora bidaliko da',
    'forgotpassword.changeConfirmed': 'Zure pasahitza arrakastaz aldatu da',
    'forgotpassword.invalidUser': 'Zure eskatutako posta elektronikoa baliogabea da',
    'forgotpassword.codeExpire': 'Zure balioztapena iraungi da. Mesedez, eskatu kode berria.',
    'eventAction.reportDownloadAnalysis': 'Zure aurrez aztertutako txostena(k)',
    'tasksManagement.confirmDeleteTask': 'Ziur zeregina ezabatu nahi duzula?',
    'maintenance.tagInConfirmation': 'Ziur zaude unitate hau etiketu nahi duzula?',
    'model.telemetryLinks.areYouSureYouWantToRemoveTheTelemetryLink': 'Ziur Telemetria Esteka ezabatu nahi duzula?',
    'model.componentpositions.confirmDeleteComponentPosition': 'Ziur osagaiaren kokapena ezabatu nahi duzula?',
    'schedule.confirmEndSchedule': 'Ziur ordutegia amaitu nahi duzula?',
    'approvedComponents.confirmDelete': 'Ziur onartutako osagaia ezabatu nahi duzula?',
    'command.areyousureyouwanttoactionthecommand': 'Ziur komandoa exekutatu nahi duzula?',
    'faqCategories.confirmDelete': 'Ziur kategoria hau ezabatu nahi duzula?',
    'accountSettings.resetCacheConfirmation': 'Ziur kašea berrezarri nahi duzula?',
    'calibration.delete.confirmation': 'Ziur kalibraketa hau ezabatu nahi duzula?',
    'eventCodes.delete.confirmation': 'Ziur gertaera-kode hau ezabatu nahi duzula?',
    'faQuestions.confirmDelete': 'Ziur galdera hau ezabatu nahi duzula?',
    'modelVersion.confirmDeleteGlobalSettings': 'Ziur ezarpen globala ezabatu nahi duzula?',
    'globalSettingsManagement.confirmDeleteGlobalSetting': 'Ziur ezarpen globala ezabatu nahi duzula?',
    'organisationRegions.confirmDelete': 'Ziur eskualde hau ezabatu nahi duzula?',
    'modelComponentTypes.confirmDelete': 'Ziur ereduen osagai mota ezabatu nahi duzula?',
    'modelComponentPositions.confirmDelete': 'Ziur eredu osagaiaren kokapena ezabatu nahi duzula?',
    'checklistManagement.confirmDeleteChecklist': 'Ziur egiaztapen-zerrenda ezabatu nahi duzula?',
    'accountSettings.clearCookiesConfirmation': 'Ziur cookie-ak garbitu nahi dituzula?',
    'modelVersion.confirmDeleteThreshold': 'Ziur atalasea ezabatu nahi duzula?',
    'taskTemplate.creationFailed': 'Zereginen txantiloiaren sorrerak huts egin du',
    'taskTemplate.updationFailed': 'Zereginen txantiloiaren eguneraketak huts egin du',
    'checklistTaskTemplateList.tasksTemplates': 'Zereginen Txantiloiak',
    'taskTemplate.serviceTemplateMandatory':
      'Zereginen txantiloia zerbitzu-egiaztapen-zerrendaren txantiloi bati dagozkio eta ezin da ezabatu',
    'taskTemplate.fetchFailed': 'Zereginen txantiloia eskuratzeak huts egin du',
    'checklistTemplateManagement.taskTemplateMandatory': 'Zereginen txantiloia derrigorrezkoa da',
    'taskTemplate.createdSuccessfully': 'Zereginen txantiloia arrakastaz sortu da',
    'taskTemplate.fetchSuccessfull': 'Zereginen txantiloia arrakastaz eskuratu da',
    'taskTemplate.updatedSuccessfully': 'Zereginen txantiloia arrakastaz eguneratu da',
    'task.taskUnitShouldBeSameAsChecklistUnit': 'Zereginean hautatutako unitatea egiaztapen-zerrenda berdina izan behar da',
    'taskTemplateManagement.nameMandatory': 'Zereginaren Izena derrigorrezko eremua da',
    'task.comment.fetchFailed': 'Zereginaren iruzkinaren eskuratzeak huts egin du',
    'task.comment.createdSuccessfully': 'Zereginaren iruzkina arrakastaz gehitu da',
    'task.deletionFailed': 'Zereginaren ezabaketak huts egin du',
    'task.alarms.creationFailed': 'Zereginaren alarmaren sorrerak huts egin du',
    'task.alarms.updationFailed': 'Zereginaren alarmaren eguneraketak huts egin du',
    'task.serviceTaskMandatory': 'Zereginak zerbitzu-egiaztapen-zerrenda bati dagozkio eta lehenik osatu behar da',
    'checklistDetail.tasksCompleted': 'zereginak osatu dira',
    'checklistManagement.tasksCompleted': 'Zereginak Osatu Dir',
    'tasksAndChecklistsManagement.tasksChecklists': 'Zereginak eta Egiaztapen-zerrendak',
    'sidebar.tasksChecklists': 'Zereginak eta egiaztapen zerrendak',
    'checklistTemplateTasks.tasks': 'Zereginak',
    'task.creationFailed': 'Zeregina sortzeko huts egin du',
    'unitServiceDetail.taskAndComponentPositionWarningMsg': 'Zeregina eta Osagaiaren Kokapena ezin dira aldatu behin amaituta',
    'task.fetchFailed': 'Zeregina eskuratzeak huts egin du',
    'task.updationFailed': 'Zeregina eguneratzeko huts egin du',
    'task.createdSuccessfully': 'Zeregina arrakastaz sortu da',
    'task.deletedSuccessfully': 'Zeregina arrakastaz ezabatu da',
    'task.fetchSuccessfull': 'Zeregina arrakastaz eskuratu da',
    'task.updatedSuccessfully': 'Zeregina arrakastaz eguneratu da',
    'taskTemplateManagement.task': 'Zeregina',
    'checklistTemplateTasks.task': 'Zeregina',
    'tasksManagement.task': 'Zeregina',
    'partService.task': 'Zeregina',
    'taskTemplateManagement.newTaskTemplate': 'Zeregin Txantiloi Berria',
    'tasksManagement.linkedAlarms': 'Zeregin hau ondorengo alarmekin lotuta dago',
    'tasksManagement.newTask': 'Zeregin Berria',
    'checklist.activeTaskExistInChecklist': 'Zeregin aktiboak daude egiaztapen-zerrendan',
    'modelVersion.servicePlanNameRequired': 'Zerbitzuaren izena beharrezkoa da',
    'modelVersionServicePlan.name': 'Zerbitzuaren Izena',
    'modelVersion.name': 'Zerbitzuaren izena',
    'modelVersion.servicePlanDesriptionRequired': 'Zerbitzuaren deskribapena beharrezkoa da',
    'maintenance.tagout.service': 'Zerbitzua',
    'maintenance.service': 'Zerbitzua',
    'taskTemplateManagement.serviceTask': 'Zerbitzu-zeregina',
    'checklistTemplateTasks.serviceTask': 'Zerbitzu-zeregina',
    'checklistTasks.servcieTask': 'Zerbitzu-zeregina',
    'modelVersionServicePlan.creationFailed': 'Zerbitzu-planaren sorrerak huts egin du',
    'modelVersionServicePlan.fetchFailed': 'Zerbitzu-planaren eskuratzeak huts egin du',
    'modelVersionServicePlan.updationFailed': 'Zerbitzu-planaren eguneraketak huts egin du',
    'checklist.schedulingServicePlanChecklistFailed': 'Zerbitzu-planaren egiaztapen-zerrendaren programazioak huts egin du',
    'modelVersionServicePlan.createdSuccessfully': 'Zerbitzu-plana arrakastaz sortu da',
    'modelVersionServicePlan.fetchSuccessfull': 'Zerbitzu-plana arrakastaz eskuratu da',
    'modelVersionServicePlan.updatedSuccessfully': 'Zerbitzu-plana arrakastaz eguneratu da',
    'checklistTemplateManagement.serviceChecklist': 'Zerbitzu-egiaztapen-zerrenda',
    'checklistDetail.serviceChecklist': 'Zerbitzu-egiaztapen-zerrenda',
    'checklistManagement.serviceChecklist': 'Zerbitzu-egiaztapen-zerrenda',
    'modelVersion.servicePlan': 'Zerbitzu Planak',
    'partService.serviceType': 'Zerbitzu Mota',
    'unitServiceDetail.confirmFinalize': 'Zerbitzu hau amaitu nahi duzu?',
    'unitServiceDetail.serviceStatus': 'Zerbitzu Egoera',
    'maintenance.unitService.serviceDate': 'Zerbitzu Data',
    'maintenance.unitService.servicedBy': 'Zerbitzatuta',
    'approvedComponents.serviceable': 'Zerbitzagarri',
    'eventAction.activeTasks': 'Zenbait zeregin oraindik aktibo daude, ezin dira ebatzita markatu',
    'deviceList.pendingAlarms': 'Zain dauden alarmak',
    'deviceList.pendingAlarm': 'Zain dagoen alarma',
    'calibration.details': 'Xehetasunak',
    'globalSettingsManagement.details': 'Xehetasunak',
    'checklistDetail.details': 'Xehetasunak',
    'componentParts.details': 'Xehetasunak',
    'appwidget.name': 'Widgetaren izena',
    'profile.widgetAssigned': 'Widgetak',
    'profile.widgetsCount': 'Widgetak',
    'sensorTable.uvt01': 'UV transmisioa',
    'unitStatistics.uVLampHoursDescription': 'UV lanparak funtzionatu duen ordu kopurua.',
    'unitStatistics.uVLampHours': 'UV lanpara orduak',
    'sensorTable.uvi01': 'UV intentsitatea',
    'sensorTable.ft02': 'UV fluxua',
    'profile.profileCancel': 'Utzi',
    'calibrationEquation.cancelCalibrationEquation': 'UTZI',
    'calibrationEquation.constant.cancel': 'UTZI',
    'calibration.cancel': 'UTZI',
    'modelVersionManagement.cancelModelVersion': 'UTZI',
    'modelVersionManagement.cancelModelVersionThreshold': 'Utzi',
    'globalSettingsManagement.cancel': 'Utzi',
    'checklistTemplateManagement.cancel': 'Utzi',
    'taskTemplateManagement.cancel': 'Utzi',
    'checklistTemplateTasks.cancel': 'UTZI',
    'eventCodes.cancel': 'UTZI',
    'model.cancelModel': 'UTZI',
    'componentParts.cancel': 'Utzi',
    'schedule.cancel': 'Utzi',
    'modelComponentTypes.cancel': 'UTZI',
    'model.componentpositions.cancel': 'UTZI',
    'model.telemetryLinks.cancel': 'Utzi',
    'maintenance.tagout.cancel': 'UTZI',
    'unitModel.cancel': 'Utzi',
    'unitServiceDetail.cancel': 'Utzi',
    'partService.cancel': 'UTZI',
    'unitStatistics.cancel': 'Utzi',
    'unitStatistics.yearToDate': 'Urtea gaur arte',
    'unitStatistics.totWaterProducedDescription': 'Ur guztira Flow Meter FT02 bidez.',
    'unitStatistics.totWaterFilteredDescription': 'Ur guztira Flow Meter FT01 bidez',
    'modelVersionShadow.unitCertificateMissing': 'Unitatearen ziurtagiria falta da',
    'tabNavigation.unitService': 'Unitatearen zerbitzua',
    'unit.saveSuccessfull': 'Unitatearen xehetasunak arrakastaz gorde dira',
    'unitServiceDetail.unitDetails': 'Unitatearen Xehetasunak',
    'componentParts.unitMandatory': 'Unitatearen Serie Zenbakia derrigorrezkoa da Ereduaren Osagaia hautatzen bada',
    'componentParts.unitSerialNumber': 'Unitatearen Serie Zenbakia',
    'modelVersion.units.unitNumber': 'Unitatearen Serie Zenbakia',
    'deviceHeader.unitTime': 'Unitatearen ordua',
    'tabNavigation.unitConfiguration': 'Unitatearen konfigurazioa',
    'unit.unitNameMandatory': 'Unitatearen izena derrigorrezko eremua da',
    'usermanagement.unitname': 'Unitatearen Izena',
    'unitListing.unitName': 'Unitatearen Izena',
    'eventAction.unitInfoMissing': 'Unitatearen informazioa falta da',
    'unit.unitGpsLongitude': 'Unitatearen GPS longitudea derrigorrezko eremua da',
    'unit.unitGpsLatitude': 'Unitatearen GPS latitudea derrigorrezko eremua da',
    'unit.unitffetchfailed': 'Unitatearen eskuratzeak huts egin du',
    'unit.unitffetchsuccessful': 'Unitatearen eskuratzea arrakastatsua izan da',
    'modelVersion.units.updationFailed': 'Unitateak huts egin du ereduen bertsiora eguneratzeko',
    'organisation.units.transferFailed': 'Unitateak ezin izan ziren bertsio berriera mugitu',
    'deviceTile.schedule': 'Unitateak egutegia du',
    'organisation.units.transferSuccessfull': 'Unitateak arrakastaz mugitu ziren bertsio berriera',
    'modelVersion.units.updatedSuccessfully': 'Unitateak arrakastaz eguneratu dira ereduen bertsiora',
    'modelVersion.units.fetchSuccessfull': 'Unitateak arrakastaz berreskuratu dira ereduen bertsiorako',
    'sidebar.units': 'Unitateak',
    'organisation.units': 'Unitateak',
    'organisationManagement.units': 'Unitateak',
    'modelVersion.units': 'Unitateak',
    'thresholdTable.units': 'Unitateak',
    'organisationManagement.unitTechnicianId': 'Unitatea zuzentzen duen teknikaria derrigorrezko eremua da',
    'organisationManagement.unitManagerId': 'Unitatea zuzentzen duen kudeatzailea derrigorrezko eremua da',
    'unitupload.unitupload.addingUnit': 'Unitatea gehitzen',
    'unit.unitSupervisingTechnician': 'Unitatea Gainbegiratzen duen Teknikoa',
    'unit.unitSupervisingManager': 'Unitatea Gainbegiratzen duen Kudeatzailea',
    'unitupload.unitupload.savefailed': 'Unitatea ezin izan da gehitu. Mesedez, egiaztatu xehetasunak',
    'unitServiceDetail.unitWarningMsg': 'Unitatea ezin da aldatu behin amaituta',
    'maintenance.tagout.unitisUnlocked': 'Unitatea desblokeatuta dago',
    'unitServiceDetail.unitMandatory;': 'Unitatea derrigorrezko eremua da',
    'maintenance.tagout.alreadyTaggedIn': 'Unitatea dagoeneko etiketatuta dago. Mesedez, saiatu kentzen',
    'maintenance.tagout.alreadyTaggedOut': 'Unitatea dagoeneko etiketatuta dago. Mesedez, saiatu berriro',
    'maintenance.tagout.unitisLockedby': 'Unitatea blokeatuta dago honengatik',
    'unitupload.unitupload.saveSuccess': 'Unitatea arrakastaz gehitu da',
    'maintenance.tagout.taginSuccessful': 'Unitatea arrakastaz etiketatu da barruan',
    'maintenance.tagout.tagoutSuccessful': 'Unitatea arrakastaz etiketatu da',
    'calibration.unit': 'Unitatea',
    'alarms.unit': 'Unitatea',
    'eventAction.unit': 'Unitatea',
    'checklistManagement.unit': 'Unitatea',
    'tasksManagement.unit': 'Unitatea',
    'maintenance.tagoutHistory.unit': 'Unitatea',
    'unitServiceDetail.unit': 'Unitatea',
    'maintenance.unitService.unit': 'Unitatea',
    'maintenance.telemetryParts.unit': 'Unitatea',
    'unitService.creationFailed': 'Unitate Zerbitzuaren sorrerak huts egin du',
    'unitService.updationFailed': 'Unitate Zerbitzuaren eguneraketak huts egin du',
    'unitService.createdSuccessfully': 'Unitate Zerbitzua arrakastaz sortu da',
    'unitService.updatedSuccessfully': 'Unitate Zerbitzua arrakastaz eguneratu da',
    'maintenance.unitService': 'Unitate Zerbitzua',
    'alarms.allUnits': 'Unitate guztiak',
    'maintenance.tagoutHistory.allUnits': 'Unitate Guztiak',
    'maintenance.unitService.allUnits': 'Unitate Guztiak',
    'filters.allUnits': 'Unitate Guztiak',
    'organisationManagement.fromModel': 'Unitate eredua',
    'unitConfiguration.unitModel': 'Unitate Eredua',
    'chart.nodataavailable': 'Unean ez dago eskuragarri daturik',
    'sensorTable.pt01': 'ump pressure transmitter - Ponparen ondorengo presio transmititzailea',
    'sensorTable.ft01': 'ump flowrate - Ponparen ondorengo fluxua',
    'unitreport.datafetchfailed': 'Txostenaren eskuratzeak huts egin du',
    'tabNavigation.reports': 'Txostenak',
    'unitreport.datafetchsuccessful': 'Txostena arrakastaz eskuratu da',
    'eventLog.insert': 'Txertatu',
    'command.transition': 'Trantsizioa',
    'organisationManagement.tranferModelVersion': 'Transferitu eredu bertsioa',
    'organisationManagement.transferringOrg': 'Transferitu erakundea',
    'calibration.tolerance': 'Tolerantzia',
    'deviceHeader.localTime': 'Tokiko ordua',
    'eventAction.downloadFailed': 'Telemetriaren URLa gordetzeko huts egin du',
    'eventAction.downloadSuccessfull': 'Telemetriaren URLa arrakastaz gorde da',
    'model.telemetryLinks.telemetryTypeMandatory': 'Telemetria Mota derrigorrezkoa da',
    'componentParts.telemetryType': 'Telemetria Mota',
    'model.telemetryLinks.telemetryType': 'Telemetria Mota',
    'telemetryLinks.creationFailed': 'Telemetria Esteken sorrerak huts egin du',
    'telemetryLinks.updationFailed': 'Telemetria Esteken eguneraketak huts egin du',
    'telemetryLinks.fetchFailed': 'Telemetria Estekak eskuratzeak huts egin du',
    'telemetryLinks.createdSuccessfully': 'Telemetria Estekak arrakastaz sortu dira',
    'telemetryLinks.fetchSuccessfull': 'Telemetria Estekak arrakastaz eskuratu dira',
    'telemetryLinks.updatedSuccessfully': 'Telemetria Estekak arrakastaz eguneratu dira',
    'model.duplicateTelemetryLinks': 'Telemetria Estekak',
    'telemetryLinks.deleteFailed': 'Telemetria Esteka ezabaketak huts egin du',
    'telemetryLinks.deleteSuccessful': 'Telemetria Esteka arrakastaz ezabatu da',
    'unitStatus.telemtryDataFetchFailed': 'Telemetria datuen eskuratzeak huts egin du',
    'eventAction.telemetry': 'Telemetria',
    'maintenance.tagout.telemetry': 'Telemetria',
    'sequence.table': 'Taula ikuspegia',
    'tasksManagement.bulkActions': 'Taldeko Ekintzak',
    'command.tank1drain': 'T1 hustea',
    'modelVersion.dateCreated': 'Sortze data',
    'model.dateCreated': 'Sortze data',
    'tasksManagement.createTask': 'Sortu Zeregina',
    'checklistTemplateTasks.createNewTask': 'SORTU ZEREGIN BERRIA',
    'checklistDetail.createServiceRecord': 'Sortu zerbitzu-erregistroa',
    'unitServiceDetail.createServiceRecord': 'Sortu Zerbitzu Erregistroa',
    'unitreports.generateReport': 'SORTU TXOSTENA',
    'eventLog.create': 'SORTU',
    'sensorTable.cs02': 'Sistemaren korronte sentsorea',
    'appwidgets.syncdata': 'Sinkronizatu Datuak',
    'componentParts.thingNameSerialNumberForSBC': 'Serie zenbakia IoT Thing izena izan behar da SBCrako',
    'componentParts.serialNumberMandatory': 'Serie Zenbakia derrigorrezkoa da',
    'componentParts.serialNumber': 'Serie Zenbakia',
    'partService.serialNumber': 'Serie Zenbakia',
    'unitListing.serialNumber': 'Serie zenbakia',
    'calibration.sensorRange': 'Sentsorearen Tartea',
    'modelVersionShadow.sensorThresholdSettingMissing': 'Sentsorearen atalaseen ezarpenak falta dira',
    'chart.sensors': 'Sentsoreak',
    'modelVersionThreshold.sensorMandatory': 'Sentsorea derrigorrezko eremua da',
    'eventCodes.sensorMandatory': 'Sentsorea derrigorrezko eremua da',
    'calibration.sensorMandatory': 'Sentsorea beharrezkoa da',
    'calibration.sensor': 'Sentsorea',
    'modelVersion.sensor': 'Sentsorea',
    'modelVersion.thresholdSensor': 'Sentsorea',
    'alarms.sensor': 'Sentsorea',
    'thresholdTable.sensor': 'Sentsorea',
    'eventCodes.sensor': 'Sentsorea',
    'modelVersion.allSensor': 'Sentsore guztiak',
    'alarms.allSensors': 'Sentsore guztiak',
    'eventCodes.allSensor': 'Sentsore Guztiak',
    'globalSettingsManagement.sequenceNumeric': 'Sekuentzia-kodea zenbakizko balioa izan behar da',
    'globalSettingsManagement.sequenceMandatory': 'Sekuentzia-kodea derrigorrezko eremua da',
    'globalSettingsManagement.sequenceCode': 'Sekuentzia-kodea',
    'sidebar.safetyPlan': 'Segurtasun Plana',
    'sensorTable.cs01': 'SBC korronte sentsorea',
    'taskTemplateManagement.enterTask': 'Sartu zeregina',
    'unitlistmap.enterUnitAddress': 'Sartu unitatearen helbidea',
    'usermanagment.enterphoneno': 'Sartu Telefono Zenbakia',
    'profile.enterProfileName': 'Sartu profilaren izena',
    'profile.enterProfileDescription': 'Sartu profilaren deskribapena',
    'organisationManagement.enterPostcode': 'Sartu posta kodea',
    'usermanagement.enteremail': 'Sartu posta elektronikoa',
    'usermanagment.enteremail': 'Sartu posta elektronikoa',
    'general.enterpassword': 'Sartu pasahitza',
    'usermanagment.enterfirstname': 'Sartu Lehen Izena',
    'unitlistmap.enterlocation': 'Sartu kokapena',
    'forgotpassword.enterCode': 'Sartu Kodea',
    'mFA.enterCode': 'Sartu Kodea',
    'taskTemplateManagement.enterActivities': 'Sartu jarduerak',
    'eventCodes.enterNextActionDetail': 'Sartu Hurrengo Ekintza',
    'organisationManagement.enterTown': 'Sartu herria',
    'organisationManagement.entrrRegion': 'Sartu eskualdea',
    'model.enterDescription': 'Sartu ereduaren deskribapena',
    'calibrationEquation.enterDescription': 'Sartu Deskribapenak',
    'calibrationEquation.constant.calibrationEquationConstant': 'Sartu Deskribapenak',
    'modelVersionManagement.enterDescription': 'Sartu deskribapena',
    'taskTemplateManagement.enterDescription': 'Sartu deskribapena',
    'eventCodes.enterDescription': 'Sartu Deskribapena',
    'usermanagment.enterlastname': 'Sartu Abizena',
    'sensorTable.tb01': 'Sarrerako turbiditate transmititzailea',
    'common.duplicateName': 'Sarrera bikoiztua aurkitu da',
    'unitupload.unitupload.tryAgain': 'SAIATU BERRIRO',
    'taskTemplateManagement.role': 'Rol',
    'checklistTemplateTasks.role': 'Rol',
    'checklistManagement.role': 'Rol',
    'tabNavigation.processFlow': 'Prozesu-fluxua',
    'command.normalprocessing': 'Prozesamendu arrunta',
    'profile.validationNameLength': 'Profilaren izenak gutxienez 2 karaktere izan behar ditu!',
    'profile.validationName': 'Profilaren izena beharrezkoa da!',
    'profile.profileName': 'Profilaren Izena',
    'usermanagment.profilename': 'Profilaren Izena',
    'notifications.profileName': 'Profilaren Izena',
    'profile.unableToSave': 'Profilaren gordetzeak huts egin du',
    'profile.validationDescriptionLength': 'Profilaren deskribapena beharrezkoa da!',
    'profile.profileDescription': 'Profilaren Deskribapena',
    'AdminControlsTabs.manageProfiles': 'Profilak',
    'profile.profiles': 'Profilak',
    'profile.saveSuccessfull': 'Profila arrakastaz gorde da',
    'accountSettingsTab.profile': 'Profila',
    'settings.profile': 'Profila',
    'sensorTable.pt02-pt03': 'Presio aldea membranaren artean',
    'unit.postCodeException': 'Posta kodea derrigorrezko eremua da',
    'organisationManagement.postCode': 'Posta kodea',
    'unit.installationPostCode': 'Posta kodea',
    'usermanagment.emailmandatory': 'Posta elektronikoa beharrezkoa da',
    'usermanagement.email': 'Posta elektronikoa',
    'usermanagment.email': 'Posta elektronikoa',
    'login.emailAddress': 'Posta elektroniko helbidea',
    'unitStatistics.avgPumpRuntime': 'Ponparen batez besteko funtzionamendu denbora ordu bakoitzeko',
    'unitStatistics.avgPumpRuntimeDescription': 'Ponparen batez besteko funtzionamendu denbora ordu bakoitzeko',
    'globalSettings.flushpumpdelaytimer': 'Ponparen Atzerapen Tenporizadorea Garbitu',
    'unitStatistics.pumpRunHoursDescription': 'Ponpak funtzionatu duen ordu kopurua.',
    'unitStatistics.pumpRunHours': 'Ponpa Lan Orduak',
    'sidebar.parts': 'Piezak',
    'profileSettings.personal': 'Pertsonala',
    'tasksManagement.people': 'Pertsonak',
    'general.passwordRequirement': 'Pasahitzak gutxienez 1 maiuskula, 1 minuskula eta 1 zenbaki izan behar ditu',
    'passwordUpdate.passwordError': 'Pasahitzak gutxienez 1 letra larri, 1 letra xehe eta 1 zenbaki izan behar ditu',
    'passwordUpdate.confirmPasswordError': 'Pasahitzak ez datoz bat',
    'general.passwordDoNotMatch': 'Pasahitzak ez datoz bat',
    'user.passwordUpdatedSuccessfully': 'Pasahitza arrakastaz aldatu da',
    'profileSettings.password': 'Pasahitza',
    'login.password': 'Pasahitza',
    'passwordUpdate.newPassword': 'Pasahitz berria',
    'forgotpassword.invalidPassword': 'Pasahitz baliogabea',
    'task.comment.requiredParamsMissing': 'Parametroak falta dira',
    'modelVersion.units.requiredParamsMissing': 'Parametroak falta dira',
    'modelComponentTypes.parameterInUse': 'Parametroa erabileran',
    'approvedComponents.parameterInUse': 'Parametroa erabileran',
    'modelComponentPositions.parameterInUse': 'Parametroa erabileran',
    'modelVersion.thresholdLowLow': 'Oso baxua',
    'modelVersion.thresholdHighHigh': 'Oso altua',
    'tasksManagement.completedOn': 'Osatuta',
    'checklistTask.showComplete': 'Osatua',
    'tasksManagement.complete': 'Osatua',
    'model.duplicateComponentPositionLinks': 'Osagaien Kokapen Estekak',
    'componentParts.creationFailed': 'Osagaiaren sorrerak huts egin du',
    'model.componentpositions': 'Osagaiaren Kokapenak',
    'modelComponentPositions.componentPositions': 'Osagaiaren kokapenak',
    'partService.componentPositions': 'Osagaiaren Kokapenak',
    'model.componentposition.saveFailed': 'Osagaiaren kokapena gehitzeak huts egin du',
    'model.componentposition.saveSuccessfull': 'Osagaiaren kokapena arrakastaz gehitu da',
    'model.componentposition.deletefailed': 'Osagaiaren kokapena arrakastaz ezabatu da',
    'model.componentposition.deleteSuccessfull': 'Osagaiaren kokapena arrakastaz ezabatu da',
    'model.telemetryLinks.title': 'Osagaiaren Kokapen Telemetria Estekak',
    'componentParts.updationFailed': 'Osagaiaren eguneraketak huts egin du',
    'componentParts.fetchFailed': 'Osagaiak eskuratzeak huts egin du',
    'componentParts.fetchSuccessful': 'Osagaiak arrakastaz eskuratu dira',
    'componentParts.parts': 'Osagaiak',
    'maintenance.parts': 'Osagaiak',
    'unitConfigurationComponentParts.componentPartMandatory': 'Osagaia derrigorrezkoa da',
    'componentParts.createdSuccessfully': 'Osagaia arrakastaz sortu da',
    'componentParts.addedSuccessfully': 'Osagaia arrakastaz gehitu da',
    'componentParts.updatedSuccessfully': 'Osagaia arrakastaz eguneratu da',
    'partService.component': 'Osagaia',
    'modelComponentTypes.componentTypes': 'Osagai motak',
    'filters.allComponentTypes': 'Osagai Mota Guztiak',
    'approvedComponents.componentTypeRequired': 'Osagai mota derrigorrezkoa da',
    'componentParts.componentType': 'Osagai Mota',
    'model.componentpositions.componentType': 'Osagai Mota',
    'approvedComponents.componentType': 'Osagai mota',
    'modelComponentPositions.componentType': 'Osagai mota',
    'maintenance.telemetryParts.componentType': 'Osagai Mota',
    'scheduler.creationFailed': 'Ordutegiaren sorrerak huts egin du',
    'schedule.schedules': 'Ordutegiak',
    'scheduler.fetchFailed': 'Ordutegia eskuratzeak huts egin du',
    'scheduler.duplicateSchedule': 'Ordutegia dagoeneko existitzen da komando bera eta denbora berean',
    'scheduler.createdSuccessfully': 'Ordutegia arrakastaz sortu da',
    'scheduler.fetchSuccessful': 'Ordutegia arrakastaz eskuratu da',
    'scheduler.endedSuccessfully': 'Ordutegia arrakastaz amaitu da',
    'scheduler.endFailed': 'Ordutegia amaitzeak huts egin du',
    'schedule.frequencyMandatory': 'Ordutegi Maiztasuna derrigorrezkoa da',
    'schedule.scheduleFrequency': 'Ordutegi Maiztasuna',
    'schedule.newSchedule': 'Ordutegi Berria',
    'checklistTemplateTasks.hours': 'orduak',
    'unitStatistics.hours': 'orduak',
    'thresholdTable.timestamp': 'Ordua',
    'settings.timezone': 'Ordu-eremua',
    'componentParts.replace': 'Ordezkatu',
    'componentParts.currentTelemetryData': 'Oraingo Telemetria Datuak',
    'passwordUpdate.currentpassword': 'Oraingo pasahitza',
    'calibrationEquation.headerInfo': 'Ondorengo ekuazioak zehaztu eta erabiltzen dira...',
    'approvedComponents.creationFailed': 'Onartutako osagaiaren sorrerak huts egin du',
    'approvedComponents.duplicateCode': 'Onartutako osagaiaren izen bikoiztua',
    'approvedComponents.updationFailed': 'Onartutako osagaiaren eguneraketak huts egin du',
    'approvedComponents.approvedComponents': 'Onartutako osagaiak',
    'componentParts.approvedComponentMandatory': 'Onartutako Osagaia derrigorrezkoa da',
    'approvedComponents.createdSuccessfully': 'Onartutako osagaia arrakastaz sortu da',
    'approvedComponents.updatedSuccessfully': 'Onartutako osagaia arrakastaz eguneratu da',
    'componentParts.approvedComponent': 'Onartutako Osagaia',
    'unitupload.acceptedfile': 'Onartutako fitxategi formatuak: JSON',
    'AdminControlsTabs.faq': 'OHZ (Ohiko Galderak)',
    'faQuestions.allFAQCategory': 'Ohiko Galderen Kategoria Guztiak',
    'faQuestions.faqCategoryRequired': 'Ohiko Galderen Kategoria derrigorrezko eremua da',
    'faQuestions.faqCategory': 'Ohiko Galderen Kategoria',
    'help.faq': 'Ohiko Galderak',
    'faQuestions.faQuestions': 'Ohiko Galderak',
    'notifications.noticeBoard': 'Oharren Taula',
    'modelComponentPositions.ocp': 'OCP',
    'globalSettings.directionalvalvedelay': 'Norabide aldaketa behar den segundu kopurua',
    'unitreports.fromdatetime': 'Noiztik data/ordua',
    'maintenance.unitService.fromDate': 'Noiztik Data',
    'unitreports.todatetime': 'Noiz arte data/ordua',
    'maintenance.unitService.toDate': 'Noiz arte Data',
    'taskList.myTasks': 'Nire zereginak',
    'tasksAndChecklistsManagement.myTasks': 'Nire Zereginak',
    'deviceList.myUnits': 'Nire unitateak',
    'unitListing.myUnits': 'Nire unitateak',
    'tasksAndChecklistsManagement.myChecklists': 'Nire Egiaztapen-zerrendak',
    'calibration.unitOfMeasure': 'Neurri-unitatea',
    'modelVersion.thresholdUnitOfMeasure': 'Neurketa-unitatea',
    'globalSettingsManagement.unitOfMeasure': 'Neurketa-unitatea',
    'model.componentpositions.unitOfMeasure': 'Neurketa-unitatea',
    'approvedComponents.uOM': 'Neurketa-unitatea',
    'modelComponentPositions.unitOfMeasure': 'Neurketa-unitatea',
    'calibration.desiredResults': 'Nahi Diren Emaitzak',
    'eventAction.id': 'N.A.',
    'organisationManagement.moveUnitsToOrganisation': 'Mugitu Unitateak erakundearen eredu bertsioetara',
    'organisationManagement.moveUnits': 'Mugitu Unitateak',
    'componentParts.allComponentTypes': 'Mota Guztiak',
    'filters.allTelemetryTypes': 'Mota Guztiak',
    'globalSettingsManagement.type': 'Mota',
    'componentParts.componentType': 'Mota',
    'approvedComponents.type': 'Mota',
    'maintenance.telemetryParts.type': 'Mota',
    'globalSettingsManagement.min': 'Minimoa',
    'forgotpassword.pleaseEnterVerificationEmail': 'Mesedez, sartu zure balioztapen posta elektroniko helbidea',
    'usermanagment.entervalidphoneno': 'Mesedez, sartu baliozko telefono zenbakia (gutxienez 7 zenbaki)',
    'usermanagment.entervalidemail': 'Mesedez, sartu baliozko posta elektronikoa',
    'login.enterValidEmail': 'Mesedez, sartu baliozko posta elektronikoa',
    'login.enterValidPassword': 'Mesedez, sartu baliozko pasahitza',
    'organisationManagement.transferUnitsMandatory': 'Mesedez, mugitu gutxienez unitate bat',
    'partService.selecteTask': 'Mesedez, hautatu zeregina',
    'partService.selectServiceType': 'Mesedez, hautatu zerbitzu mota',
    'modelVersion.units.unitsRequire': 'Mesedez, hautatu unitateak',
    'partService.selectCompPosition': 'Mesedez, hautatu osagaiaren kokapena',
    'unitreport.selectfromtime': 'Mesedez, hautatu noiztik',
    'unitreport.selecttotime': 'Mesedez, hautatu noiz arte',
    'unitreport.selectdatapoint': 'Mesedez, hautatu gutxienez datu-puntu bat',
    'model.telemetryLinks.selectOne': 'Mesedez, hautatu bat',
    'approvedComponents.pleaseSelectOne': 'Mesedez, hautatu bat',
    'modelComponentPositions.pleaseSelectOne': 'Mesedez, hautatu bat',
    'usermanagment.addatleaseoneprofile': 'Mesedez, gehitu gutxienez profil bat',
    'notifications.userMandatory': 'Mesedez, gehitu gutxienez erabiltzaile bat',
    'forgotpassword.invalidEmail': 'Mesedez, eman posta elektroniko balioduna',
    'calibration.finalizeNote': 'Mesedez, bukatu kalibraketa kargatu aurretik',
    'profile.fillRequiredData': 'Mesedez, bete beharrezko datuak!',
    'organisationManagement.transferOrganisationMandatory': 'Mesedez hautatu erakundea',
    'model.duplicateModelVersionInfo': 'Mesedez hautatu bikoiztu beharreko ereduaren bertsioa(k)',
    'globalSettings.flushmembranebfvolume': 'Membranaren BF Bolumena Garbitu',
    'taskList.markAsComplete': 'MARKATU OSATUTZAT',
    'checklistDetail.markAsComplete': 'MARKATU OSATUTZAT',
    'tasksManagement.markAsComplete': 'Markatu Osatutzat',
    'eventAction.markAsResolved': 'MARKATU EBATZITA',
    'componentParts.allBrands': 'Marka Guztiak',
    'filters.allBrands': 'Marka Guztiak',
    'approvedComponents.brandRequired': 'Marka derrigorrezkoa da',
    'componentParts.brand': 'Marka',
    'approvedComponents.brand': 'Marka',
    'maintenance.telemetryParts.brand': 'Marka',
    'deviceList.mapView': 'Mapa ikuspegia',
    'maintenance.maintenance': 'Mantentze-lanak',
    'partService.linkedTasks': 'Lotutako Zereginak',
    'alarms.linkedAlarms': 'Lotutako Alarmak',
    'eventAction.linkedAlarm': 'Lotutako Alarma',
    'eventActionActitivity.linkedAlarm': 'lotutako Alarma',
    'eventAction.unlinkAlarm': 'Lotura kendu Alarmari',
    'eventActionActitivity.unlinkedAlarm': 'Lotura gabeko Alarma',
    'tasksManagement.linkToAlarms': 'Lotu alarmekin',
    'eventAction.linkAlarm': 'Lotu Alarma',
    'unit.longitude': 'Longitudea',
    'unitStatistics.litres': 'litroak',
    'deviceTile.offline': 'Lineaz kanpo',
    'deviceHeader.offline': 'Lineaz kanpo',
    'organisationManagement.defaultUnitSupervisor': 'Lehenetsitako Unitateen Zuzendariak',
    'globalSettingsManagement.defaultValueWithinRange': 'Lehenetsitako balioa Minimo eta Gehienezkoaren tartean egon behar du',
    'globalSettingsManagement.defaultValue': 'Lehenetsitako balioa',
    'modelVersionManagement.defaultValue': 'Lehenetsia',
    'globalSettings.flushfirstmembraneffvolume': 'Lehen Membranaren FF Bolumena Garbitu',
    'usermanagment.firstnamemandatory': 'Lehen izena beharrezkoa da',
    'usermanagment.firstname': 'Lehen Izena',
    'deviceList.tileView': 'Lauza ikuspegia',
    'deviceList.viewOnTile': 'LAUZA IKUSPEGIA',
    'unit.latitude': 'Latitudea',
    'command.emergencystop': 'Larrialdi geldialdia',
    'unitStatistics.avgLampRuntime': 'Lanpararen batez besteko funtzionamendu denbora ordu bakoitzeko',
    'unitStatistics.avgLampRuntimeDescription': 'Lanpararen batez besteko funtzionamendu denbora ordu bakoitzeko',
    'general.needHelp': 'Laguntza behar duzu?',
    'sidebar.help': 'Laguntza',
    'help.help': 'Laguntza',
    'globalSettings.numberofsamples': 'Lagin Kopurua',
    'dailySummaryReport.datafetchfailed': 'Laburpen datuen eskuratzeak huts egin du',
    'dailySummaryReport.datafetchsuccessful': 'Laburpen datuen eskuratzea arrakastatsua',
    'tabNavigation.summaryData': 'Laburpen datuak',
    'modelVersion.units.manageUnitVersion': 'Kudeatu Unitate Bertsioak',
    'common.manage': 'KUDEATU',
    'thresholdTable.criticallow': 'Kritikoki Baxua',
    'thresholdTable.criticalhigh': 'Kritikoki Altua',
    'accountSettings.generalSettings': 'Kontuaren orokorreko ezarpenak',
    'sidebar.accountSettings': 'Kontuaren ezarpenak',
    'settings.accountSettings': 'Kontuaren ezarpenak',
    'accountSettings.resetCacheNote': 'Kontuan izan kaxa berrezartzeak erabiltzaileen ezarpenak ere berrezarriko dituela.',
    'accountSettings.clearCookiesNote': 'Kontuan izan cookie-ak garbitzeak saio guztietatik aterako zaituela.',
    'approvedComponents.consumable': 'Kontsumigarria',
    'calibration.controlRange': 'Kontrol Tartea',
    'usermanagment.contactno': 'Kontaktu zenbakia',
    'calibrationEquation.constant.nameMandatory': 'Konstantearen izena beharrezkoa da',
    'model.configuration': 'Konfigurazioa',
    'common.configure': 'Konfiguratu',
    'modelVersion.configure': 'Konfiguratu',
    'model.configureModel': 'Konfiguratu',
    'unitListing.connection': 'Konexioa',
    'deviceTile.online': 'Konektatuta',
    'deviceHeader.online': 'Konektatuta',
    'command.commandfailedtosend': 'Komandoa ezin izan da bidali. Saiatu berriro.',
    'schedule.commandMandatory': 'Komandoa derrigorrezkoa da',
    'command.commandsuccessfullysent': 'Komandoa arrakastaz bidali da',
    'command.successfull': 'Komandoa arrakastaz bidali da',
    'schedule.command': 'Komandoa',
    'commandPanel.quickCommands': 'Komando azkarrak',
    'model.telemetryLinks.sourceTargetSame': 'Kokapena eta Iturriaren Kokapena ezin dira berdinak izan',
    'model.telemetryLinks.positionMandatory': 'Kokapena derrigorrezkoa da',
    'organisation.location': 'Kokapena',
    'organisationManagement.location': 'Kokapena',
    'units.location': 'Kokapena',
    'componentParts.location': 'Kokapena',
    'componentParts.position': 'Kokapena',
    'model.componentpositions.positionName': 'Kokapena',
    'model.telemetryLinks.position': 'Kokapena',
    'maintenance.telemetryParts.position': 'Kokapena',
    'unitListing.location': 'Kokapena',
    'organisationManagement.countryMandatory': 'Kokapen herrialdea derrigorrezko eremua da',
    'filters.allpositions': 'Kokapen Guztiak',
    'eventCodes.codeInvalidCode': 'Kodeak 4 digituko kodea izan behar du',
    'globalSettingsManagement.codeMandatory': 'Kodea derrigorrezko eremua da',
    'eventCodes.codeMandatory': 'Kodea derrigorrezko eremua da',
    'globalSettingsManagement.code': 'Kodea',
    'eventCodes.code': 'Kodea',
    'forgotpassword.tooManyAttempts': 'Kode honetarako gehienezko saiakerak gainditu dituzu.',
    'unitreports.downloadreport': 'klikatu hemen jaisteko',
    'calibrationEquation.constant.remove': 'Kendu',
    'calibration.remove': 'Kendu',
    'modelVersion.remove': 'Kendu',
    'unitupload.remove': 'KENDU',
    'checklistTemplateTasks.remove': 'KENDU',
    'tasksManagement.remove': 'KENDU',
    'modelComponentTypes.remove': 'KENDU',
    'model.telemetryLinks.remove': 'Kendu',
    'modelComponentPositions.remove': 'Kendu',
    'calibration.upload': 'KARGATU',
    'maintenance.tagoutHistory.outDateTime': 'Kanpo Data Ordua',
    'taskTemplateManagement.estimatedEffort': 'Kalkulatutako ahalegin',
    'checklistTemplateTasks.estimatedEffort': 'Kalkulatutako Ahalegin',
    'approvedComponents.calibrationRequired': 'Kalibrazioa beharrezkoa da',
    'approvedComponents.calibrationTolerance': 'Kalibrazio tolerantzia',
    'approvedComponents.calibrationFrequency': 'Kalibrazio maiztasuna',
    'approvedComponents.calibrationEquationRequired': 'Kalibrazio ekuazioa derrigorrezkoa da',
    'calibrationEquation.createdSuccessfully': 'Kalibrazio ekuazioa arrakastaz sortu da',
    'approvedComponents.calibrationEquation': 'Kalibrazio ekuazioa',
    'calibrationEquation.constant': 'Kalibratzeko Konstanteak',
    'calibrationEquation.creationFailed': 'Kalibratzeko ekuazioaren sorrerak huts egin du',
    'calibrationEquation.constant.fetchFailed': 'Kalibratzeko ekuazioaren konstanteak eskuratzeak huts egin du',
    'calibrationEquation.updationFailed': 'Kalibratzeko ekuazioaren eguneraketak huts egin du',
    'calibrationEquation.updatedSuccessfully': 'Kalibratzeko ekuazioa arrakastaz gorde da',
    'calibrationEquation.calibrationEquation': 'Kalibratzeko Ekuazioa',
    'calibrationEquation.newCalibration': 'Kalibratzeko ekuazio berriaren konfigurazioa',
    'calibrationEquation.duplicateName': 'Kalibratzeko ekuazio baten izena dagoeneko existitzen da',
    'calibration.creationFailed': 'Kalibraketaren sorrerak huts egin du',
    'calibration.comment.createdSuccessfully': 'Kalibraketaren iruzkinak arrakastaz gehitu dira',
    'calibration.deletionFailed': 'Kalibraketaren ezabaketak huts egin du',
    'calibration.updationFailed': 'Kalibraketaren eguneraketak huts egin du',
    'calibration.calibrations': 'Kalibraketak',
    'calibration.calibrationDateMandatory': 'Kalibraketa-data beharrezkoa da',
    'calibration.calibrationConstants': 'Kalibraketa Konstanteak',
    'calibration.calibrationEquation': 'Kalibraketa ekuazioa',
    'calibration.calibrationDatetime': 'Kalibraketa Data Ordua',
    'calibration.calibrationDate': 'Kalibraketa Data',
    'calibration.newCalibration': 'Kalibraketa Berria',
    'calibration.calibrationRequired': 'Kalibraketa Beharrezkoa',
    'calibration.calibrationRequired': 'Kalibraketa beharrezkoa',
    'calibration.createdSuccessfully': 'Kalibraketa arrakastaz sortu da',
    'calibration.updatedSuccessfully': 'Kalibraketa arrakastaz gorde da',
    'calibration.deletedSuccessfully': 'Kalibraketa arrakastaz ezabatu da',
    'tabNavigation.calibration': 'Kalibraketa',
    'AdminControlsTabs.calibrations': 'Kalibraketa',
    'calibration.calibration': 'Kalibraketa',
    'unit.streetAddressException': 'Kale Helbidea derrigorrezko eremua da',
    'unit.installationStreetAddress': 'Kale Helbidea',
    'dashboard.hello': 'Kaixo',
    'eventAction.recieved': 'Jasota',
    'alarms.dateReceived': 'Jaso den data',
    'general.contactUsNow': 'JARRI GUREKIN HARREMANETAN ORAIN',
    'taskTemplateManagement.activities': 'Jarduerak',
    'eventAction.activity': 'Jarduera',
    'AdminControlsTabs.notifications': 'Jakinarazpenak',
    'notifications.notifications': 'Jakinarazpenak',
    'help.notifications': 'Jakinarazpenak',
    'notifications.notification': 'Jakinarazpena',
    'notifications.notificationTypes': 'Jakinarazpen Mota',
    'notifications.notificationType': 'Jakinarazpen Mota',
    'notifications.notificationDateTime': 'Jakinarazpen Data eta Ordua',
    'unitreports.downloadUTCDateTime': 'Jaitsi UTC data ordutan',
    'unitreports.downloadLocalDateTime': 'Jaitsi tokiko data ordutan',
    'modelComponentTypes.nameRequired': 'Izena derrigorrezkoa da',
    'modelComponentPositions.nameRequired': 'Izena derrigorrezkoa da',
    'globalSettingsManagement.nameMandatory': 'Izena derrigorrezko eremua da',
    'eventCodes.nameMandatory': 'Izena derrigorrezko eremua da',
    'common.name': 'Izena',
    'profileSettings.name': 'Izena',
    'calibrationEquation.name': 'Izena',
    'calibrationEquation.constant.name': 'Izena',
    'calibration.calibrationConstantsName': 'Izena',
    'globalSettingsManagement.name': 'Izena',
    'alarms.alarmName': 'Izena',
    'usermanagement.name': 'Izena',
    'eventAction.name': 'Izena',
    'checklistTemplateManagement.name': 'Izena',
    'checklistDetail.name': 'Izena',
    'checklistManagement.name': 'Izena',
    'tasksManagement.unitName': 'Izena',
    'eventCodes.name': 'Izena',
    'modelComponentTypes.name': 'Izena',
    'modelVersion.units.name': 'Izena',
    'modelComponentPositions.name': 'Izena',
    'profile.alreadyExists': 'Izen hau duen profila dagoeneko existitzen da',
    'organisation.duplicateName': 'Izen hau duen erakunde bat dagoeneko existitzen da',
    'generalGlobalSettings.duplicateName': 'Izen bikoiztua',
    'tasksManagement.backtoTasks': 'ITZULI ZEREGINETARA',
    'componentParts.backToParts': 'ITZULI OSAGAIETARA',
    'calibrationEquation.backToCalibrations': 'ITZULI KALIBRATZEEI',
    'notifications.backtoNotifications': 'ITZULI JAKINARAZPENETARA',
    'unitServiceDetail.backTo': 'ITZULI HONETARA',
    'eventAction.backToEventLog': 'ITZULI GERTAERA-ERREGISTRORA',
    'globalSettingsManagement.backToGlobalSettings': 'ITZULI EZARPEN GLOBALAKETARA',
    'unauthorised.returnHome': 'Itzuli Etxera',
    'modelVersion.backToModels': 'ITZULI EREDUETARA',
    'model.backToModels': 'ITZULI EREDUETARA',
    'modelVersion.backToModelConfiguration': 'ITZULI EREDUAREN KONFIGURAZIORA',
    'organisationManagement.backToOrganisations': 'ITZULI ERAKUNDEETARA',
    'organisationControl.backToOrganisations': 'ITZULI ERAKUNDEETARA',
    'user.backtousers': 'ITZULI ERABILTZAILEETARA',
    'checklistManagement.backtoChecklist': 'ITZULI EGIAZTAPEN-ZERRENDETARA',
    'eventAction.backToalarms': 'ITZULI ALARMETARA',
    'tasksManagement.backtoAlarm': 'ITZULI ALARMARA',
    'tasksManagement.backtoDashboard': 'ITZULI AGINTERAURA',
    'modelVersionShadow.updationFailed': 'Itzalaren eguneraketak huts egin du',
    'modelVersionShadow.updatedSuccessfully': 'Itzala arrakastaz eguneratu da',
    'command.waitforaws': 'Itxaron AWS',
    'command.wait': 'Itxaron',
    'model.telemetryLinks.sourcePositionMandatory': 'Iturriaren Kokapena derrigorrezkoa da',
    'model.telemetryLinks.sourcePosition': 'Iturriaren Kokapena',
    'calibration.comments': 'Iruzkinak',
    'tasksManagement.comments': 'Iruzkinak',
    'tasksManagement.addedAComment': 'iruzkin bat gehitu du',
    'approvedComponents.outputLowRange': 'Irteera tarte baxua',
    'approvedComponents.outputHighRange': 'Irteera tarte altua',
    'approvedComponents.outputUOM': 'Irteera neurketa-unitatea',
    'approvedComponents.output': 'Irteera',
    'approvedComponents.hasFeedback': 'Iritzia du',
    'model.componentpositions.feedback': 'Iritzia',
    'modelComponentPositions.feedback': 'Iritzia',
    'chart.duration': 'Iraupena',
    'componentPartsFilters.requiredParamsMissing': 'Iragazkietarako beharrezko parametroa falta da',
    'componentPartsFilter.fetchFailed': 'Iragazkiak eskuratzeak huts egin du',
    'componentPartsFilter.fetchSuccessful': 'Iragazkiak arrakastaz eskuratu dira',
    'filter.requiredParamsMissing': 'Iragazki datuen parametroak falta dira',
    'filter.fetchFailed': 'Iragazki datuen eskuratzeak huts egin du',
    'filter.fetchSuccessfull': 'Iragazki datuen eskuratzea arrakastatsua',
    'unitStatistics.totWaterFiltered': 'Iragazitako ur guztira',
    'maintenance.tagoutHistory.filterBy': 'Iragazi Honengatik',
    'maintenance.unitService.filterBy': 'Iragazi Honengatik',
    'profile.filterBy': 'Iragazi',
    'modelVersion.filterBy': 'Iragazi',
    'alarms.filterBy': 'Iragazi',
    'eventCodes.filterBy': 'Iragazi',
    'componentParts.filterBy': 'Iragazi',
    'maintenance.telemetryParts.filterBy': 'Iragazi',
    'layout.noticeBoard': 'IRAGARKI TAULA',
    'commandPanel.iotCommands': 'IoT komandoak',
    'modelVersionShadow.iotEndPointMissing': 'IoT amaiera-puntua falta da',
    'sensorTable.pt01-pt02': 'ilters - Presio aldea aurre-iragazkien artean',
    'command.prefilterbackflush': 'ilter back flush - Aurre-iragazkia atzera flusha',
    'taskList.seeAlltasks': 'Ikusi zeregin guztiak',
    'checklistDetail.viewServiceRecord': 'Ikusi zerbitzu-erregistroa',
    'unitupload.unitupload.viewUnit': 'IKUSI UNITATEA',
    'deviceList.viewOnMap': 'IKUSI MAPAN',
    'modelVersion.viewSummary': 'Ikusi Laburpena',
    'partService.viewLess': 'IKUSI GUTXIAGO',
    'processFlow.allEventsLog': 'Ikusi gertaera erregistro guztiak',
    'partService.viewMore': 'IKUSI GEHIAGO',
    'calibration.view': 'IKUSI',
    'checklistTasksTemplates.view': 'IKUSI',
    'checklistTemplateTasks.view': 'IKUSI',
    'checklistManagement.view': 'IKUSI',
    'tasksManagement.view': 'IKUSI',
    'componentParts.view': 'Ikusi',
    'approvedComponents.view': 'IKUSI',
    'modelVersion.units.view': 'IKUSI',
    'maintenance.unitService.view': 'Ikusi',
    'unitupload.hasBeenUploaded': 'igo da',
    'componentParts.uploadJson': 'Igo .JSON Fitxategia',
    'appwidget.id': 'Identifikazioa',
    'eventCodes.nextActionDetails': 'Hurrengo Ekintzaren Xehetasunak',
    'deviceTile.yellowAlert': 'Horri abisua',
    'approvedComponents.supplierName': 'Hornitzailearen izena',
    'approvedComponents.supplierAddress': 'Hornitzailearen helbidea',
    'checklistDetail.assignedTo': 'Honi esleitua',
    'tasksManagement.assignedTo': 'Honi esleitua',
    'checklistDetail.assignedBy': 'Honen bidez esleitua',
    'tasksManagement.assignedBy': 'Honen bidez esleitua',
    'unitupload.unitupload.approxtime': 'Honek gutxi gorabehera 15 segundo beharko ditu',
    'usermanagment.contactNameMandatory': 'Hobetsitako Harremanetarako Metodoa beharrezkoa da',
    'usermanagement.preferredContactMethod': 'Hobetsitako Harremanetarako Metodoa',
    'settings.language': 'Hizkuntza',
    'componentParts.historicalTelemetryData': 'Historikoko Telemetria Datuak',
    'unit.cityException': 'Hiria derrigorrezko eremua da',
    'modelVersion.units.installationCity': 'Hiria',
    'unit.installationCity': 'Hiria',
    'unitStatistics.monthToDate': 'Hilabetea gaur arte',
    'unit.countryException': 'Herrialdea derrigorrezko eremua da',
    'organisationManagement.country': 'Herrialdea',
    'modelVersion.units.installationCountry': 'Herrialdea',
    'unit.installationCountry': 'Herrialdea',
    'organisationManagement.town': 'Herria',
    'modelVersion.units.installationStreetAddress': 'Helbidea',
    'unit.installationAddress': 'Helbidea',
    'checklistTemplateTasks.selectTaskFromTaskTemplate': 'Hautatu zeregina zeregin txantiloi batetik',
    'tasksManagement.selectTask': 'Hautatu zeregin txantiloiak',
    'componentParts.selectUnitSerialNumber': 'Hautatu Unitatearen Serie Zenbakia',
    'unitreports.selectReportTypes': 'Hautatu txosten mota',
    'calibration.selectSensor': 'Hautatu Sentsorea',
    'modelVersionThreshold.selectSensor': 'Hautatu Sentsorea',
    'eventCodes.selectSensor': 'Hautatu sentsorea',
    'profile.widgetselection': 'Hautatu profil honek sarbidea izango dituen widgetak',
    'unitConfigurationComponentParts.selectPart': 'Hautatu Osagaia',
    'profile.selectPage': 'Hautatu orria',
    'profile.selectAllPages': 'Hautatu Orri Guztiak',
    'tasksManagement.selectAlarms': 'Hautatu oraingo alarmak honentzat',
    'componentParts.selectApprovedComponent': 'Hautatu Onartutako Osagaia',
    'faQuestions.selectFAQCategory': 'Hautatu Ohiko Galderen Kategoria',
    'organisationManagement.selectCountry': 'Hautatu herrialdea',
    'tasksManagement.selectTask': 'Hautatu gutxienez zeregin bat',
    'organisationManagement.userMandatory': 'Hautatu gutxienez 1 erabiltzaile erakunde honentzat',
    'eventCodes.selectEventCategory': 'Hautatu gertaera-kategoria',
    'modelVersionThreshold.selectFlowSequence': 'Hautatu Fluxu-sekuentzia',
    'eventCodes.selectFlowSequence': 'Hautatu fluxu-sekuentzia',
    'modelVersionManagement.selectSetting': 'Hautatu ezarpena',
    'componentParts.selectModleComponentPosition': 'Hautatu Ereduaren Osagaiaren Kokapena',
    'model.componentpositions.selectModelComponentPosition': 'Hautatu eredu osagaiaren kokapena',
    'modelVersionManagement.selectOrganisation': 'Hautatu Erakundea',
    'checklistDetail.selectFromChecklistTemplates': 'Hautatu egiaztapen-zerrenden txantiloietatik',
    'unitreports.selecttimeinterval': 'Hautatu denbora-tartea',
    'unitreports.selectall': 'Hautatu denak',
    'unitreports.selectdatapoints': 'Hautatu datu-puntuak',
    'unitreports.selectedateandtimerange': 'Hautatu data eta ordu-tartea',
    'common.selectOne': 'Hautatu bat',
    'modelVersionManagement.settingValue': 'Hautatu balioa',
    'chart.selectoreditchart': 'Hautatu / editatu grafikoaren aldagaiak',
    'profile.selectWidget': 'Hautatu',
    'usermanagment.select': 'Hautatu',
    'checklistTemplateTasks.select': 'Hautatu',
    'model.versionSelected': 'Hautatu',
    'schedule.select': 'Hautatu',
    'unitStatistics.select': 'Hautatu',
    'command.initialise': 'hasieratu',
    'schedule.pastStartTime': 'Hasiera Ordua hurrengo 15 minututik hauta daiteke',
    'schedule.startDateMandatory': 'Hasiera Data Ordua derrigorrezkoa da',
    'schedule.startDateTime': 'Hasiera Data Ordua',
    'modelVersion.servicePlanStartDateShouldBeGreaterThanOrEqualToTodaysDate':
      'Hasiera data egungo data baino handiagoa edo berdina izan behar da',
    'modelVersionServicePlan.startDateTime': 'Hasiera Data',
    'modelVersion.startDateTime': 'Hasiera Data',
    'login.login': 'Hasi Saioa',
    'login.logInButton': 'Hasi Saioa',
    'command.start': 'Hasi',
    'profileSettings.contactNumber': 'Harremanetarako zenbakia',
    'command.hardwarefault': 'Hardware akatsa',
    'maintenance.unitService.allServicedUsers': 'Guztiek Zerbitzatuta',
    'componentParts.allUnits': 'Guztiak',
    'datatrend.analysis.missingMetric': 'Gutxienez neurri bat eman behar da.',
    'thresholdTable.min': 'Gutxienekoa',
    'datatrend.analysis.minimum': 'Gutxienekoa',
    'profile.minimumWidget': 'Gutxieneko beharrezko profil-widgeta',
    'globalSettingsManagement.minValue': 'Gutxieneko Balioa',
    'organisationManagement.parentMandatory': 'Guraso taldea derrigorrezko eremua da',
    'organisationManagement.parentGroup': 'Guraso taldea',
    'chart.chart': 'Grafikoa',
    'deviceTile.redAlert': 'Gorri abisua',
    'unitServiceDetail.saveServiceRecord': 'Gorde zerbitzu erregistroa',
    'eventAction.saveDownloadLink': 'Gorde Esteka',
    'organisationManagement.saveOrganisation': 'Gorde Erakundea',
    'profile.profileSave': 'Gorde Aldaketak',
    'calibrationEquation.saveCalibrationEquation': 'GORDE ALDAKETAK',
    'calibration.save': 'GORDE ALDAKETAK',
    'modelVersionManagement.saveModelVersion': 'GORDE ALDAKETAK',
    'checklistTemplateManagement.saveChanges': 'Gorde Aldaketak',
    'taskTemplateManagement.saveChanges': 'Gorde aldaketak',
    'eventCodes.save': 'GORDE ALDAKETAK',
    'model.saveModel': 'GORDE ALDAKETAK',
    'modelVersionManagement.saveModelVersionThreshold': 'Gorde',
    'globalSettingsManagement.save': 'Gorde',
    'tasksManagement.save': 'Gorde',
    'componentParts.save': 'Gorde',
    'schedule.save': 'Gorde',
    'modelComponentTypes.save': 'GORDE',
    'model.componentpositions.save': 'GORDE',
    'model.telemetryLinks.save': 'Gorde',
    'unitModel.save': 'Gorde',
    'partService.save': 'GORDE',
    'unitStatistics.save': 'Gorde',
    save: 'Gorde',
    'chart.upperthreshold': 'Goiko Atalasea',
    'modelComponentPositions.false': 'Gezurra',
    'processFlow.eventLog': 'Gertaeren erregistroa',
    'tabNavigation.eventLog': 'Gertaeren erregistroa',
    'componentParts.eventDate': 'Gertaeraren Data',
    'eventCodes.requiredParamsMissing': 'Gertaera-kodearentzako beharrezko datuak falta dira',
    'eventCodes.creationFailed': 'Gertaera-kodearen sorrerak huts egin du',
    'eventCodes.updationFailed': 'Gertaera-kodearen eguneraketak huts egin du',
    'eventCodes.fetchFailed': 'Gertaera-kodeak eskuratzeak huts egin du',
    'eventCodes.fetchSuccessfull': 'Gertaera-kodeak arrakastaz eskuratu dira',
    'eventCodes.eventCodes': 'Gertaera-Kodeak',
    'eventCodes.deletionFailed': 'Gertaera-kodea ezabatzeko huts egin du',
    'eventCodes.createdSuccessfully': 'Gertaera-kodea arrakastaz sortu da',
    'eventCodes.deletionSuccessfully': 'Gertaera-kodea arrakastaz ezabatu da',
    'eventCodes.updatedSuccessfully': 'Gertaera-kodea arrakastaz eguneratu da',
    'eventCodes.eventCodeActive': 'Gertaera-kodea aktiboa da',
    'alarms.eventCode': 'Gertaera-kodea',
    'eventCodes.eventCode': 'Gertaera-Kodea',
    'eventCodes.eventCodeTypeMandatory': 'Gertaera-kode mota derrigorrezko eremua da',
    'eventCodes.eventCodeType': 'Gertaera-Kode Mota',
    'alarms.allEvenCodes': 'Gertaera-kode guztiak',
    'eventCodes.eventCategoryMandatory': 'Gertaera-kategoria derrigorrezko eremua da',
    'eventCodes.eventCategory': 'Gertaera-Kategoria',
    'eventAction.creationFailed': 'Gertaera-ekintzaren sorrerak huts egin du',
    'eventActionActivity.creationFailed': 'Gertaera-ekintzaren jarduera sorrerak huts egin du',
    'eventActionActivity.createdSuccessfully': 'Gertaera-ekintzaren jarduera arrakastaz sortu da',
    'eventAction.updationFailed': 'Gertaera-ekintzaren eguneraketak huts egin du',
    'eventAction.ifExistsFailed': 'Gertaera-ekintzaren egoera kargatzeko huts egin du',
    'eventAction.ifExistsSuccessfull': 'Gertaera-ekintzaren egoera arrakastaz kargatu da',
    'eventAction.fetchFailed': 'Gertaera-ekintza eskuratzeak huts egin du',
    'eventAction.createdSuccessfully': 'Gertaera-ekintza arrakastaz sortu da',
    'eventAction.fetchSuccessfull': 'Gertaera-ekintza arrakastaz eskuratu da',
    'eventAction.updatedSuccessfully': 'Gertaera-ekintza arrakastaz eguneratu da',
    'componentParts.eventType': 'Gertaera Mota',
    'thresholdTable.event': 'Gertaera',
    'command.stop': 'Gelditu',
    'settings.addwidget': 'Gehitu Widget',
    'organisationManagement.addUnitsToOrganisation': 'Gehitu unitateak zure erakundeari',
    'unitupload.unitupload.addunit': 'GEHITU UNITATEA',
    'deviceList.addUnit': 'Gehitu unitate berria',
    'unitupload.addUnit': 'Gehitu Unitate Berria',
    'unitListing.addNewUnit': 'GEHITU UNITATE BERRIA',
    'model.telemetryLinks.addNewTelemetryLink': 'Gehitu Telemetria Esteka Berria',
    'profile.addProfile': 'Gehitu Profila',
    'profile.addNewProfile': 'GEHITU PROFIL BERRIA',
    'eventAction.reportAddCustomDownload': 'Gehitu pertsonalizatutako txostenaren esteka',
    'modelComponentTypes.addNewComponentType': 'Gehitu osagai mota berria',
    'componentParts.addNewPart': 'Gehitu Osagai Berria',
    'schedule.addNew': 'Gehitu Ordutegi Berria',
    'approvedComponents.addNewApprovedComponent': 'Gehitu onartutako osagai berria',
    'faqCategorys.addFAQCategory': 'Gehitu Ohiko Galderen Kategoria Berria',
    'calibration.addNew': 'GEHITU KALIBRAKETA BERRIAK',
    'eventAction.addAComment': 'Gehitu iruzkin bat',
    'unit.addAddressFromGps': 'Gehitu helbidea GPS-tik',
    'chart.addchart': 'Gehitu Grafikoa',
    'faQuestions.addFAQuestion': 'Gehitu galdera berria',
    'globalSettingsManagement.addNewGlobalSettings': 'Gehitu ezarpen global berriak',
    'modelComponentPositions.addNewModelComponentPosition': 'Gehitu eredu osagaiaren kokapen berria',
    'organisationRegions.addOrganisationRegion': 'Gehitu Erakundearen Eskualdea',
    'organisation.addNewOrganisation': 'Gehitu erakunde berria',
    'organisationManagement.addUsersToOrganisation': 'Gehitu erabiltzaileak zure erakundeari',
    'usermanagement.addUser': 'Gehitu Erabiltzailea',
    'usermanagement.addnewuser': 'GEHITU ERABILTZAILE BERRIA',
    'usermanagement.linkaddnewuser': 'Gehitu erabiltzaile berria',
    'chart.addanotherchart': 'Gehitu beste Grafiko bat',
    'calibrationEquation.addNew': 'GEHITU BERRIA',
    'modelVersion.addNew': 'Gehitu Berria',
    'globalSettingsManagement.addNew': 'GEHITU BERRIA',
    'checklistTaskTemplateList.addNew': 'GEHITU BERRIA',
    'checklistTemplateTasks.addNew': 'GEHITU BERRIA',
    'tasksManagement.addNew': 'Gehitu Berria',
    'eventCodes.addNew': 'GEHITU BERRIA',
    'componentParts.addNew': 'Gehitu Berria',
    'modelComponentTypes.addNew': 'GEHITU BERRIA',
    'model.componentpositions.addNew': 'GEHITU BERRIA',
    'model.telemetryLinks.addNew': 'GEHITU BERRIA',
    'approvedComponent.addNew': 'GEHITU BERRIA',
    'modelComponentPositions.addNew': 'GEHITU BERRIA',
    'partService.addNew': 'GEHITU BERRIA',
    'calibrationEquation.constant.add': 'GEHITU',
    'modelVersion.addNewModelVersion': 'Gehitu',
    'usermanagement.add': 'Gehitu',
    'checklistTemplateTasks.add': 'GEHITU',
    'globalSettingsManagement.max': 'Gehienezkoa',
    'globalSettingsManagement.maxValuemustbeGrater': 'Gehienezko balioak Minimoaren balioa baino handiagoa izan behar du',
    'globalSettingsManagement.maxValue': 'Gehienezko Balioa',
    'thresholdTable.max': 'Gehienekoa',
    'datatrend.analysis.maximum': 'Gehienekoa',
    'accountSettings.clearCookies': 'GARBITU COOKIEAK',
    'profile.searchclear': 'GARBITU',
    'globalSettings.normalprocessingtimebeforeflush': 'Garbiketa aurretiko Prozesamendu Denbora Normala',
    'faQuestions.questionRequired': 'Galdera derrigorrezko eremua da',
    'faQuestions.question': 'Galdera',
    'modelVersion.units.deviceSerialNumber': 'Gailuaren Serie Zenbakia',
    'model.componentpositions.unitShdowUpdatedWithLatestSettings': 'Gailuaren itzala azken ereduaren konfigurazioarekin eguneratuko da',
    'modelVersion.allFlowSequence': 'Fluxu-sekuentzia guztiak',
    'eventCodes.allFlowSequence': 'Fluxu-Sekuentzia Guztiak',
    'modelVersionThreshold.FlowSequenceMandotory': 'Fluxu-sekuentzia derrigorrezko eremua da',
    'eventCodes.flowsequenceMandatory': 'Fluxu-Sekuentzia derrigorrezko eremua da',
    'modelVersion.flowSequence': 'Fluxu-sekuentzia',
    'globalSettingsManagement.flowSequence': 'Fluxu-sekuentzia',
    'eventCodes.flowSequence': 'Fluxu-Sekuentzia',
    'command.flush': 'Flusha',
    'unitupload.invalidfileformat': 'Fitxategiaren formatua ez da baliozkoa. Mesedez, igo baliozko fitxategi bat',
    'profile.requiredParamsMissing': 'Falta diren parametroak',
    'organisation.requiredParamsMissing': 'Falta diren parametroak',
    'modelVersion.requiredParamsMissing': 'Falta diren parametroak',
    'calibrationEquation.requiredParamsMissing': 'Falta diren parametroak',
    'calibration.requiredParamsMissing': 'Falta diren parametroak',
    'calibration.comment.requiredParamsMissing': 'Falta diren parametroak',
    'unitComponent.requiredParamsMissing': 'Falta diren parametroak',
    'model.requiredParamsMissing': 'Falta diren parametroak',
    'profile.EnableMFA': 'Faktore anitzeko autentifikazioa (MFA)',
    'approvedComponents.manufacturerName': 'Fabrikatzailearen izena',
    'approvedComponents.manufacturerAddress': 'Fabrikatzailearen helbidea',
    'globalSettings.prefilterf04volume': 'F04 Aurre-iragazkiaren Bolumena',
    'globalSettings.prefilterf010203volume': 'F010203 Aurre-iragazkiaren Bolumena',
    'deviceSchematic.diagramView': 'Ezkutatu sentsoreak',
    'profile.errorInFetching': 'Ezinezkoa izan da datuak berreskuratzea',
    'profile.invalidData': 'Ezinezkoa da gordetzea. Datu formatu baliogabea!',
    'datatrend.analysis.unableToFetchData': 'Ezin izan da zehaztutako denbora-tartearen telemetria zerrenda berreskuratu.',
    'task.comment.creationFailed': 'Ezin izan da iruzkina gehitu',
    'checklist.servicePlanMissingFromModelVersion':
      'Ezin izan da hurrengo zerbitzu-plana sortu, ez baitago eskuragarri zerbitzu-egiaztapen-zerrendarik',
    'organisationManagement.youCannotMoveUnitsToSameModelVersion': 'Ezin duzu unitateak eredu bertsio berean mugitu',
    'unitComponent.datafetchfailed': 'Ezin dira unitateen osagaien datuak berreskuratu',
    'modelVersion.units.fetchFailed': 'Ezin dira unitateak berreskuratu ereduen bertsiorako',
    'login.unableToFetchProfiles': 'Ezin dira profilak eskuratu',
    'calibration.comment.fetchFailed': 'Ezin dira kalibraketaren iruzkinen datuak berreskuratu',
    'calibration.result.fetchFailed': 'Ezin dira kalibraketaren emaitzen datuak berreskuratu',
    'organisation.units.fetchFailed': 'Ezin dira erakundearen unitateak berreskuratu',
    'organisation.users.fetchFailed': 'Ezin dira erakundearen erabiltzaileak berreskuratu',
    'login.unableToFetchOrganisations': 'Ezin dira erakundeak eskuratu',
    'dailySummaryReport.unableToExport': 'Ezin dira datuak jaitsi',
    'unit.unableToSave': 'Ezin dira datuak gorde, Saiatu berriro',
    'maintenance.unitService.unableToExport': 'Ezin dira datuak esportatu, mesedez egiaztatu zure iragazkiak',
    'login.unableToFetchData': 'Ezin dira datuak eskuratu',
    'forgotpassword.invalidEmail': 'Ezin da zure posta elektronikoa balioztatu. Mesedez, jarri harremanetan zure administratzailearekin',
    'forgotpassword.unabletoChangePassword': 'Ezin da zure pasahitza aldatu. Mesedez, jarri harremanetan zure administratzailearekin.',
    'maintenance.tagout.failed': 'Ezin da unitatea etiketu. Mesedez, jarri harremanetan zure administratzailearekin',
    'eventAction.unableToDownloadReport': 'Ezin da txostena jaitsi',
    'maintenance.telemetryParts.unableToExport': 'Ezin da txostena esportatu',
    'login.unableToLoginContactAdmin': 'Ezin da saioa hasi! Mesedez, jarri harremanetan administratzailearekin',
    'command.unableToSendCommand': 'Ezin da komandoa bidali.',
    'command.unitTaggedOut': 'Ezin da komandoa bidali unitate hau kanpoan etiketu delako',
    'calibrationEquation.fetchFailed': 'Ezin da kalibratzeko ekuazioaren datuak berreskuratu',
    'calibration.fetchFailed': 'Ezin da kalibraketaren datuak berreskuratu',
    'calibration.constant.fetchFailed': 'Ezin da kalibraketaren datuak berreskuratu',
    'calibration.comment.creationFailed': 'Ezin da iruzkina gehitu',
    'calibrationEquation.constant.calibrationexists': 'Ezin da ezabatu, konstante hau oraindik erabiltzen ari da...',
    'model.componentposition.isUsed': 'Ezin da ezabatu osagaiaren kokapena erabiltzen ari da',
    'maintenance.tagoutHistory.fetchfailed': 'Ezin da etiketaren historia eskuratu',
    'model.fetchFailed': 'Ezin da Ereduaren datuak berreskuratu',
    'modelVersion.fetchFailed': 'Ezin da Eredu bertsioaren datuak berreskuratu',
    'organisation.fetchFailed': 'Ezin da erakundearen datuak berreskuratu',
    'profile.fetchFailed': 'Ezin da datuak berreskuratu',
    'accountSettingsTab.settings': 'Ezarpenak',
    'settings.settings': 'Ezarpenak',
    'modelVersion.settings': 'Ezarpenak',
    'modelVersionManagement.globalSettingRequired': 'Ezarpena derrigorrezko eremua da',
    'modelVersionGlobalSettings.requiredParamsMissing': 'Ezarpen globalerako beharrezko datuak falta dira',
    'modelVersionGlobalSettings.creationFailed': 'Ezarpen globalaren sorrerak huts egin du',
    'generalGlobalSettings.creationFailed': 'Ezarpen globalaren sorrerak huts egin du',
    'modelVersionGlobalSettings.deletetionFailed': 'Ezarpen globalaren ezabaketak huts egin du',
    'generalGlobalSettings.deletetionFailed': 'Ezarpen globalaren ezabaketak huts egin du',
    'modelVersionGlobalSettings.fetchFailed': 'Ezarpen globalaren eskuratzeak huts egin du',
    'modelVersionGlobalSettings.updationFailed': 'Ezarpen globalaren eguneraketak huts egin du',
    'generalGlobalSettings.updationFailed': 'Ezarpen globalaren eguneraketak huts egin du',
    'modelVersionShadow.globalSettingMissing': 'Ezarpen globalak falta dira',
    'generalglobalsettings.datafetchfailed': 'Ezarpen globalak eskuratzeak huts egin du',
    'AdminControlsTabs.globalSettings': 'Ezarpen globalak',
    'modelVersion.globalSettings': 'Ezarpen Globalak',
    'globalSettingsManagement.globalSettings': 'Ezarpen Globalak',
    'modelVersionGlobalSettings.createdSuccessfully': 'Ezarpen globala arrakastaz sortu da',
    'generalGlobalSettings.createdSuccessfully': 'Ezarpen globala arrakastaz sortu da',
    'modelVersionGlobalSettings.deletedSuccessfully': 'Ezarpen globala arrakastaz ezabatu da',
    'generalGlobalSettings.deletedSuccessfully': 'Ezarpen globala arrakastaz ezabatu da',
    'modelVersionGlobalSettings.fetchSuccessfull': 'Ezarpen globala arrakastaz eskuratu da',
    'generalglobalsettings.datafetchsucccessfull': 'Ezarpen globala arrakastaz eskuratu da',
    'modelVersionGlobalSettings.updatedSuccessfully': 'Ezarpen globala arrakastaz eguneratu da',
    'generalGlobalSettings.updatedSuccessfully': 'Ezarpen globala arrakastaz eguneratu da',
    'globalSettingsManagement.globalSetting': 'Ezarpen Globala',
    'calibration.delete': 'EZABATU KALIBRAKETA',
    'chart.removeChart': 'Ezabatu Grafikoa',
    'globalSettingsManagement.delete': 'Ezabatu',
    'unitupload.delete': 'EZABATU',
    'checklistDetail.delete': 'Ezabatu',
    'tasksManagement.delete': 'Ezabatu',
    'eventCodes.delete': 'Ezabatu',
    'model.delete': 'Ezabatu',
    'modelComponentTypes.deletionFailed': 'Ezabaketa huts egin du',
    'approvedComponents.deletionFailed': 'Ezabaketa huts egin du',
    'modelComponentPositions.deletionFailed': 'Ezabaketa huts egin du',
    'unauthorised.youAreNotAuthorised': 'Ez zaude baimenduta orri hau ikusteko',
    'notifications.noAccess': 'Ez duzu oharren taularako sarbiderik. Mesedez, jarri harremanetan zure administratzailearekin.',
    'maintenance.tagout.NoAccess': 'Ez duzu mantentze-lanetarako sarbiderik. Mesedez, jarri harremanetan zure administratzailearekin.',
    'command.accessDenied': 'Ez duzu komando honen sarbiderik. Mesedez, jarri harremanetan zure administratzailearekin.',
    'TasksAndChecklists.NoAccess':
      'Ez duzu egiaztapen-zerrenda eta zereginen sarbiderik. Mesedez, jarri harremanetan zure administratzailearekin.',
    'maintenance.tagout.noPermission': 'Ez duzu baimenik unitateak etiketatzeko. Mesedez, jarri harremanetan zure administratzailearekin.',
    'AdminControls.NoAccess': 'Ez duzu administrazio-kontrolerako sarbiderik. Mesedez, jarri harremanetan zure administratzailearekin.',
    'partService.noData': 'Ez dago zereginik zerbitzu erregistroarekin lotuta',
    'deviceHeader.notAvailable': 'Ez dago eskuragarri',
    'maintenance.unitService.noData': 'Ez dago daturik eskuragarri',
    'dailySummaryReport.noData': 'Ez dago daturik eskuragarri',
    'maintenance.telemetryParts.noData': 'Ez dago daturik eskuragarri',
    'modelVersion.modelMandatory': 'Ez da eredurik esleitu',
    'datatrend.analysis.invalidTimestamp': 'Ez da baliozko denbora-marka.',
    'common.no': 'Ez',
    'calibration.no': 'Ez',
    'calibration.calibrationRequired.no': 'Ez',
    'componentParts.no': 'Ez',
    'approvedComponents.no': 'Ez',
    'command.run': 'Exekutatu',
    'sidebar.home': 'Etxea',
    'maintenance.tagout.isTaggedoutBy': 'Etiketatu kanpoan honengatik',
    'maintenance.tagoutHistory.tagoutUser': 'Etiketatu Kanpoan Honengatik',
    'maintenance.tagoutHistory.allTaggedOutUsers': 'Etiketatu Kanpoan dauden Erabiltzaile Guztiak',
    'maintenance.tagoutHistory.tagoutDate': 'Etiketatu Kanpo Data',
    'maintenance.tagoutHistory.taginUser': 'Etiketatu Barruan Honengatik',
    'maintenance.tagoutHistory.taginDate': 'Etiketatu Barruan Data',
    'maintenance.tagoutHistory.fetchSuccessful': 'Etiketaren historia arrakastaz eskuratu da',
    'maintenance.tagoutHistory.taggoutStatus': 'Etiketaren Egoera',
    'maintenance.tagout.reason': 'Etiketaren arrazoi',
    'maintenance.tagout.tagInUnit': 'ETIKETA UNITATEAN',
    'maintenance.tagout.tagOutUnit': 'ETIKETA KANPOKO UNITATEA',
    'maintenance.tagoutHistory': 'Etiketa Kanpo Historia',
    'maintenance.tagout': 'Etiketa Kanpo',
    'maintenance.tagoutHistory.statusTaggedOut': 'Etiketa Kanpo',
    'maintenance.tagoutHistory.statusAll': 'Etiketa Guztiak',
    'maintenance.tagoutHistory.statusTaggedIn': 'Etiketa Barruan',
    'unitStatistics.statisticType': 'Estatistika Mota',
    'unitStatistics.creationFailed': 'Estatistika ezarpenen sorrerak huts egin du',
    'unitStatistics.updationFailed': 'Estatistika ezarpenen eguneraketak huts egin du',
    'unitStatistics.createdSuccessfully': 'Estatistika ezarpenak arrakastaz sortu dira',
    'unitStatistics.updatedSuccessfully': 'Estatistika ezarpenak arrakastaz eguneratu dira',
    'common.export': 'Esportatu',
    'maintenance.unitService.export': 'Esportatu',
    'maintenance.telemetryParts.export': 'Esportatu',
    'alarms.allAssignee': 'Esleitutako guztiak',
    'componentParts.unitAllocated': 'Esleituta',
    'calibration.assignee': 'Esleitua',
    'alarms.assignee': 'Esleitua',
    'eventAction.assignee': 'Esleitua',
    'checklistManagement.assignTo': 'Esleitu honi',
    'tasksManagement.assignTo': 'Esleitu honi',
    'modelComponentTypes.fetchSuccessfull': 'Eskuratzea arrakastatsua',
    'approvedComponents.fetchSuccessfull': 'Eskuratzea arrakastatsua',
    'modelComponentPositions.fetchSuccessfull': 'Eskuratzea arrakastatsua',
    'organisationManagement.region': 'Eskualdea',
    'unit.region': 'Eskualdea',
    'model.schematicDiagram': 'Eskema Diagrama',
    'login.missingRequiredFields': 'Eskatze gorputzean derrigorrezko eremuak falta dira',
    'modelVersion.units.allocateUnits': 'ESKAINI UNITATEAK',
    'forgotpassword.requiredParamsMissing': 'Eskaera baliogabea',
    'tasksManagement.nonServiceTask': 'ervice task - Ez-zerbitzu zeregina',
    'checklistManagement.nonServiceChecklist': 'ervice checklist - Ez-zerbitzu-egiaztapen-zerrenda',
    'login.error': 'Errorea saioa hastean',
    'user.passwordUpdateFailed': 'Errorea pasahitza eguneratzean',
    'passwordUpdate.errorUpdatePassword': 'Errorea pasahitza eguneratzean',
    'login.errorInMFAAttempt': 'Errorea kodea autentikatzen saiatzean. Mesedez, saiatu berriro',
    'login.errorInLoginAttempt': 'Errorea emandako kredentzialekin saioa hasten saiatzean',
    'unauthorised.error401': 'Errorea 401',
    'calibration.error': 'Errorea',
    'sensorTable.ct01': 'Erresistentzia transmititzailea (+ tenperatura)',
    'calibration.reporter': 'Erreportaria',
    'usermanagement.repeatPassword': 'Errepikatu pasahitza',
    'common.creationFailed': 'Erregistroaren sorrera huts egin du',
    'common.deletionFailed': 'Erregistroaren ezabaketa huts egin du',
    'common.fetchFailed': 'Erregistroaren eskuratzea huts egin du',
    'common.updationFailed': 'Erregistroaren eguneraketa huts egin du',
    'profile.fetchSuccessfull': 'Erregistroak arrakastaz eskuratuta',
    'organisation.fetchSuccessfull': 'Erregistroak arrakastaz eskuratuta',
    'modelVersion.fetchSuccessfull': 'Erregistroak arrakastaz eskuratuta',
    'calibrationEquation.fetchSuccessfull': 'Erregistroak arrakastaz eskuratuta',
    'calibration.fetchSuccessfull': 'Erregistroak arrakastaz eskuratuta',
    'unitComponent.datafetchsucccessfull': 'Erregistroak arrakastaz eskuratuta',
    'model.fetchSuccessfull': 'Erregistroak arrakastaz eskuratu dira',
    'common.isRefered': 'Erregistroa beste leku batzuetan erreferentziatzen da, ezin da ezabatu',
    'common.createdSuccessfully': 'Erregistroa arrakastaz sortu da',
    'common.deletionSuccessfull': 'Erregistroa arrakastaz ezabatu da',
    'common.fetchSuccessfull': 'Erregistroa arrakastaz eskuratu da',
    'common.updatedSuccessfully': 'Erregistroa arrakastaz eguneratu da',
    'model.creationFailed': 'Ereduaren sorrerak huts egin du',
    'componentParts.modelComponentPositionMandatory':
      'Ereduaren Osagaiaren Kokapena derrigorrezkoa da Unitatearen Serie Zenbakia ematen bada',
    'unitConfigurationComponentParts.modelComponentPositionMandatory': 'Ereduaren Osagaiaren Kokapena derrigorrezkoa da',
    'componentParts.modelComponentPosition': 'Ereduaren Osagaiaren Kokapena',
    'modelComponentTypes.creationFailed': 'Ereduaren osagai motaren sorrerak huts egin du',
    'modelComponentTypes.duplicateCode': 'Ereduaren osagai motaren izen bikoiztua',
    'modelComponentTypes.updationFailed': 'Ereduaren osagai motaren eguneraketak huts egin du',
    'modelComponentTypes.createdSuccessfully': 'Ereduaren osagai mota arrakastaz sortu da',
    'modelComponentTypes.updatedSuccessfully': 'Ereduaren osagai mota arrakastaz eguneratu da',
    'model.nameMandatory': 'Ereduaren izena derrigorrezko eremua da',
    'model.modelName': 'Ereduaren izena',
    'model.updationFailed': 'Ereduaren eguneraketak huts egin du',
    'model.description': 'Ereduaren deskribapena',
    'model.duplicate.failed': 'Ereduaren bikoizketa huts egin du',
    'model.duplicate.successfull_withoutTelemetryLink':
      'Ereduaren bikoizketa arrakastatsua. Hala ere, telemetria estekaren sorrerak huts egin du.',
    'model.duplicate.successfull': 'Ereduaren bikoizketa arrakastatsua',
    'modelVersion.configureModelVersion': 'Ereduaren Bertsioaren Konfigurazioa',
    'modelVersion.modelVersionName': 'Ereduaren Bertsioaren Izena',
    'modelVersion.duplicate.failed': 'Ereduaren bertsioaren bikoizketa huts egin du',
    'modelVersion.duplicate.successfull': 'Ereduaren bertsioaren bikoizketa arrakastatsua',
    'modelVersion.modelVersion': 'Ereduaren Bertsioa',
    'modelVersionShadow.modelUnitMissing': 'Ereduan unitateak falta dira',
    'AdminControlsTabs.manageModel': 'Ereduak',
    'approvedComponents.modelRequired': 'Eredua derrigorrezkoa da',
    'model.createdSuccessfully': 'Eredua arrakastaz sortu da',
    'model.updatedSuccessfully': 'Eredua arrakastaz gorde da',
    'model.active': 'Eredua aktiboa da',
    'modelVersion.modelName': 'Eredua',
    'model.model': 'Eredua',
    'componentParts.model': 'Eredua',
    'approvedComponents.model': 'Eredua',
    'maintenance.telemetryParts.model': 'Eredua',
    'modelComponentPositions.creationFailed': 'Eredu osagaiaren kokapenaren sorrerak huts egin du',
    'modelComponentPositions.duplicateCode': 'Eredu osagaiaren kokapenaren izen bikoiztua',
    'modelComponentPositions.updationFailed': 'Eredu osagaiaren kokapenaren eguneraketak huts egin du',
    'model.componentpositions.modelComponentPositionRequired': 'Eredu osagaiaren kokapena beharrezkoa da',
    'modelComponentPositions.createdSuccessfully': 'Eredu osagaiaren kokapena arrakastaz sortu da',
    'modelComponentPositions.updatedSuccessfully': 'Eredu osagaiaren kokapena arrakastaz eguneratu da',
    'componentParts.allModels': 'Eredu Guztiak',
    'modelVersion.creationFailed': 'Eredu bertsioaren sorrerak huts egin du',
    'modelVersion.updationFailed': 'Eredu bertsioaren eguneraketak huts egin du',
    'modelVersion.createdSuccessfully': 'Eredu bertsioa arrakastaz sortu da',
    'modelVersion.updatedSuccessfully': 'Eredu bertsioa arrakastaz gorde da',
    'modelVersionServicePlan.duplicateName': 'Eredu berdina duen zerbitzu-plana dagoeneko existitzen da',
    'modelComponentPositions.derived': 'Eratorria',
    'faQuestions.answerRequired': 'Erantzuna derrigorrezko eremua da',
    'faQuestions.answer': 'Erantzuna',
    'common.showLess': 'ERAMAN GUTXIAGO',
    'checklistTaskTemplateList.showLess': 'ERAMAN GUTXIAGO',
    'checklistTaskTemplateList.showAll': 'ERAMAN DENA',
    'deviceSchematic.sensorView': 'Erakutsi sentsoreak',
    'globalSettingsManagement.showCommand': 'Erakutsi komandoa',
    'modelComponentTypes.showAll': 'ERAKUTSI GUZTIAK',
    'model.componentpositions.showAll': 'ERAKUTSI GUZTIAK',
    'modelVersion.showAll': 'ERAKUTSI GUZTIAK',
    'approvedComponents.showAll': 'ERAKUTSI GUZTIAK',
    'modelVersion.units.showAll': 'ERAKUTSI GUZTIAK',
    'modelComponentPositions.showAll': 'ERAKUTSI GUZTIAK',
    'partService.showAll': 'ERAKUTSI GUZTIAK',
    'modelVersion.showAll': 'Erakutsi Guztia',
    'modelVersion.showLess': 'Erakutsi Gutxiago',
    'componentParts.showLess': 'Erakutsi Gutxiago',
    'modelComponentTypes.showLess': 'ERAKUTSI GUTXIAGO',
    'model.componentpositions.showLess': 'ERAKUTSI GUTXIAGO',
    'modelVersion.showLess': 'ERAKUTSI GUTXIAGO',
    'approvedComponents.showLess': 'ERAKUTSI GUTXIAGO',
    'modelVersion.units.showLess': 'ERAKUTSI GUTXIAGO',
    'modelComponentPositions.showLess': 'ERAKUTSI GUTXIAGO',
    'partService.showLess': 'ERAKUTSI GUTXIAGO',
    'checklistManagement.showAll': 'Erakutsi Denak',
    'tasksManagement.showAll': 'Erakutsi Denak',
    'componentParts.showAll': 'Erakutsi Denak',
    'common.showAll': 'ERAKUTSI DENA',
    'globalSettingsManagement.showAll': 'Erakutsi',
    'checklistTask.showAll': 'Erakutsi',
    'pagination.showing': 'Erakusten',
    'eventAction.noActivityToShow': 'Erakusteko jarduerarik ez',
    'modelVersion.organisationFetchFailed': 'Erakundeen eskuratzeak huts egin du',
    'modelVersion.organisationFetchSuccessfull': 'Erakundeen erregistroak arrakastaz eskuratuta',
    'organisation.creationFailed': 'Erakundearen sorkuntza huts egin du',
    'organisationManagement.nameMandatory': 'Erakundearen izena derrigorrezko eremua da',
    'organisation.organisationName': 'Erakundearen izena',
    'organisationManagement.organisationName': 'Erakundearen izena',
    'organisationRegions.organisationRegions': 'Erakundearen Eskualdeak',
    'organisation.updationFailed': 'Erakundearen eguneraketa huts egin du',
    'AdminControlsTabs.manageOrganisations': 'Erakundeak',
    'organisation.organisation': 'Erakundeak',
    'organisation.inActiveError': 'Erakundea ezin da inaktibo gisa markatu, erabiltzaile aktiboak dituelako',
    'organisation.createdSuccessfully': 'Erakundea arrakastaz sortuta',
    'organisation.updatedSuccessfully': 'Erakundea arrakastaz gordea',
    'organisationManagement.organisationActive': 'Erakundea aktiboa da',
    'settings.organisation': 'Erakundea',
    'modelVersionManagement.Organisation': 'Erakundea',
    'componentParts.organisation': 'Erakundea',
    'units.unitsinoperation': 'Eragiketan dauden unitateak',
    'modelVersion.thresholdOpLow': 'Eragiketa baxua',
    'modelVersion.thresholdOpHigh': 'Eragiketa altua',
    'usermanagement.userList': 'Erabiltzaileen Zerrenda',
    'user.creationFailed': 'Erabiltzailearen sorrerak huts egin du',
    'userprofile.fetchFailed': 'Erabiltzailearen profilaren eskuratzeak huts egin du',
    'userprofile.fetchSuccessfull': 'Erabiltzailearen profilaren eskuratzea arrakastatsua izan da',
    'userprofile.updatefailed': 'Erabiltzailearen profilaren eguneraketak huts egin du',
    'user.emailAlreadyExist': 'Erabiltzailearen posta elektronikoa dagoeneko existitzen da',
    'user.updationFailed': 'Erabiltzailearen eguneraketak huts egin du',
    'user.fetchFailed': 'Erabiltzailearen datuen eskuratzeak huts egin du',
    'modelVersionShadow.accessDenied': 'Erabiltzaileak ez du baimenik erakundearen ezarpenetara sartzeko',
    'AdminControlsTabs.manageUsers': 'Erabiltzaileak',
    'profile.userCount': 'Erabiltzaileak',
    'organisation.users': 'Erabiltzaileak',
    'organisationManagement.users': 'Erabiltzaileak',
    'usermanagement.users': 'Erabiltzaileak',
    'user.couldNotbeLocated': 'Erabiltzailea ezin izan da aurkitu',
    'login.userNotConfirmedException': 'Erabiltzailea ez da baieztatuta, Mesedez, jarri harremanetan administratzailearekin',
    'user.createdSuccessfully': 'Erabiltzailea arrakastaz sortu da',
    'user.updatedSuccessfully': 'Erabiltzailea arrakastaz eguneratu da',
    'unitServiceDetail.user': 'Erabiltzailea',
    'profileSettings.emailAddress': 'Erabiltzaile-izena/Helbide elektronikoa',
    'settings.userManagement': 'Erabiltzaile kudeaketa',
    'notifications.userName': 'Erabiltzaile Izena',
    'generalGlobalSettings.parameterInUse': 'Erabileran dagoen parametroa',
    'checklistDetail.dueDateTime': 'Epea eta ordua',
    'tasksManagement.dueDateTime': 'Epea eta ordua',
    'checklistTasks.dueDate': 'Epea',
    'checklistManagement.duedate': 'Epea',
    'tasksManagement.duedate': 'Epea',
    'tasksManagement.dueDate': 'Epea',
    'sensorTable.tb03': 'embrane turbidity transmitter - Membranaren ondorengo turbiditate transmititzailea',
    'sensorTable.tb02': 'embrane Turbidity transmitter - Membrana aurreko turbiditate transmititzailea',
    'sensorTable.pt03': 'embrane pressure transmitter - Membranaren ondorengo presio transmititzailea',
    'sensorTable.pt02': 'embrane pressure transmitter - Membrana aurreko presio transmititzailea',
    'calibration.results': 'Emaitzak',
    'calibrationEquation.nameMandatory': 'Ekuazioaren izena derrigorrezko eremua da',
    'calibrationEquation.active': 'Ekuazioa aktiboa da',
    'calibrationEquation.constant.headerInfo': 'Ekuazioa aktiboa da',
    'unitStatistics.totWaterProduced': 'Ekoiztutako ur guztira',
    'modelVersionServicePlan.schedulePlanFailed': 'Egutegiak sortzeak huts egin du',
    'modelVersionServicePlan.scheduleEvery': 'Egutegia Noizean Behin',
    'modelVersion.scheduleEvery': 'Egutegia Noizean Behin',
    'modelVersionServicePlan.schedulePlanSuccessfull': 'Egutegia arrakastaz sortu da unitate guztientzat',
    'tabNavigation.schedule': 'Egutegia',
    'modelVersion.servicePlanSchedulePeriodValidate': 'Egutegi Aldia 0 baino handiagoa izan behar da',
    'modelVersionServicePlan.scheduleByPeriod': 'Egutegi Aldia',
    'modelVersion.scheduleByPeriod': 'Egutegi Aldia',
    'modelVersion.servicePlanSchedulePeriodRequired': 'Egutegi Aldia',
    'modelVersionManagement.updateUnitShadow': 'EGUNERATU UNITATEEN ITZALA',
    'chart.updateChart': 'Eguneratu Grafikoa',
    'usermanagement.updateUser': 'Eguneratu Erabiltzailea',
    'calibrationEquation.constant.update': 'EGUNERATU',
    'eventLog.update': 'Eguneratu',
    'schedule.day': 'Eguna',
    'chart.messageDownloadSize': 'Egun bat (24 ordu) datuak aldi berean soilik ikusi daitezke',
    'alarms.allStatus': 'Egoera guztiak',
    'filters.allstatus': 'Egoera Guztiak',
    'approvedComponents.hasState': 'Egoera du',
    'calibration.status': 'Egoera',
    'alarms.status': 'Egoera',
    'appwidget.status': 'Egoera',
    'usermanagement.status': 'Egoera',
    'eventAction.status': 'Egoera',
    'checklistTasks.status': 'Egoera',
    'checklistManagement.status': 'Egoera',
    'tasksManagement.status': 'Egoera',
    'model.componentpositions.state': 'Egoera',
    'modelComponentPositions.state': 'Egoera',
    'maintenance.telemetryParts.status': 'Egoera',
    'unitListing.status': 'Egoera',
    'checklistTaskTemplateList.checklistTemplates': 'Egiaztapen-zerrenden Txantiloiak',
    'checklistTemplate.creationFailed': 'Egiaztapen-zerrendaren txantiloiaren sorrerak huts egin du',
    'checklistTemplate.updationFailed': 'Egiaztapen-zerrendaren txantiloiaren eguneraketak huts egin du',
    'modelVersion.servicePlanCheckListTemplateRequired': 'Egiaztapen-zerrendaren txantiloia beharrezkoa da',
    'checklistTemplate.createdSuccessfully': 'Egiaztapen-zerrendaren txantiloia arrakastaz sortu da',
    'checklistTemplate.updatedSuccessfully': 'Egiaztapen-zerrendaren txantiloia arrakastaz eguneratu da',
    'checklistTemplate.checklistTemplate': 'Egiaztapen-zerrendaren Txantiloia',
    'modelVersionServicePlan.checklistName': 'Egiaztapen-zerrendaren Txantiloia',
    'modelVersion.checklistName': 'Egiaztapen-zerrendaren Txantiloia',
    'checklistTemplate.newChecklistTemplate': 'Egiaztapen-zerrendaren Txantiloi Berria',
    'checklist.creationFailed': 'Egiaztapen-zerrendaren sorrerak huts egin du',
    'checklistTemplateManagement.nameMandatory': 'Egiaztapen-zerrendaren Izena derrigorrezko eremua da',
    'checklist.updationFailed': 'Egiaztapen-zerrendaren eguneraketak huts egin du',
    'checklistDetail.checklistComplete': 'EGIAZTAPEN-ZERRENDA OSATU DA',
    'checklistDetail.newChecklist': 'Egiaztapen-zerrenda Berria',
    'checklist.createdSuccessfully': 'Egiaztapen-zerrenda arrakastaz sortu da',
    'checklist.deletedSuccessfully': 'Egiaztapen-zerrenda arrakastaz ezabatu da',
    'checklist.updatedSuccessfully': 'Egiaztapen-zerrenda arrakastaz eguneratu da',
    'checklistDetail.checklist': 'Egiaztapen-zerrenda',
    'unitServiceDetail.checklist': 'Egiaztapen-zerrenda',
    'maintenance.unitService.checklist': 'Egiaztapen-zerrenda',
    'AdminControlsTabs.checklistsTasks': 'Egiaztapen zerrendak eta zereginak',
    'modelComponentPositions.true': 'Egia',
    'unitListing.anyStatus': 'Edozein egoera',
    'unitServiceDetail.editServiceRecord': 'Editatu Zerbitzu Erregistroa',
    'profile.profileEdit': 'Editatu Profila',
    'componentParts.editPart': 'Editatu Osagaia',
    'calibration.editCalibration': 'Editatu Kalibraketa',
    'organisation.editOrganisation': 'Editatu erakundearen xehetasunak',
    'usermanagement.edituserdetails': 'Editatu erabiltzailearen xehetasunak',
    'notifications.editNotificationUsers': 'Editatu erabiltzaileak jakinarazpen motarako',
    'profileSettings.edit': 'Editatu',
    'profile.Edit': 'EDITATU',
    'organisation.edit': 'EDITATU',
    'modelVersion.edit': 'Editatu',
    'modelVersion.editModelVersion': 'Editatu',
    'globalSettingsManagement.edit': 'Editatu',
    'usermanagement.edit': 'EDITATU',
    'checklistTemplate.edit': 'Editatu',
    'taskTemplateManagement.edit': 'Editatu',
    'checklistDetail.edit': 'Editatu',
    'tasksManagement.edit': 'Editatu',
    'eventCodes.edit': 'Editatu',
    'model.edit': 'Editatu',
    'model.editModel': 'Editatu',
    'modelComponentTypes.edit': 'Editatu',
    'approvedComponents.edit': 'Editatu',
    'modelComponentPositions.edit': 'Editatu',
    'unitServiceDetail.edit': 'Editatu',
    'partService.edit': 'EDITATU',
    'eventAction.resolved': 'Ebatzita',
    'globalSettings.ditmeasurementdrop': 'DIT Neurketaren Jaitsiera',
    'globalSettings.ditmeasurementduration': 'DIT Neurketaren Iraupena',
    'globalSettings.ditmeasurementstartpressuremin': 'DIT Neurketaren Hasierako Gutxieneko Presioa',
    'globalSettings.ditmeasurementstartpressuremax': 'DIT Neurketaren Hasierako Gehienezko Presioa',
    'globalSettings.ditmeasurementtimeout': 'DIT Neurketaren Denbora-muga',
    'globalSettings.ditdrainagetimer': 'DIT Hoditeriaren Tenporizadorea',
    'globalSettings.ditdrainagetargetpressure': 'DIT Hoditeriaren Helburu Presioa',
    'globalSettings.ditdrainagemaxpressure': 'DIT Hoditeriaren Gehienezko Presioa',
    'globalSettings.ditdrainagetimeout': 'DIT Hoditeriaren Denbora-muga',
    'globalSettings.ditrewettingvolume': 'DIT Berhezitzeko Bolumena',
    'sequence.diagram': 'Diagrama ikuspegia',
    'calibrationEquation.constant.description': 'Deskribapenak',
    'taskTemplateManagement.description': 'Deskribapena / Helburua',
    'common.description': 'Deskribapena',
    'calibrationEquation.description': 'Deskribapena',
    'calibration.calibrationConstantsDescription': 'Deskribapena',
    'modelVersion.description': 'Deskribapena',
    'globalSettingsManagement.description': 'Deskribapena',
    'appwidget.description': 'Deskribapena',
    'checklistTemplateManagement.description': 'Deskribapena',
    'checklistDetail.description': 'Deskribapena',
    'eventCodes.description': 'Deskribapena',
    'modelComponentTypes.description': 'Deskribapena',
    'model.componentpositions.positionDescription': 'Deskribapena',
    'model.telemetryLinks.description': 'Deskribapena',
    'modelComponentPositions.description': 'Deskribapena',
    'maintenance.tagoutHistory.description': 'Deskribapena',
    'modelVersionServicePlan.description': 'Deskribapena',
    'modelVersion.description': 'Deskribapena',
    'eventAction.downloadedReportLink': 'Deskargatutako Esteka',
    'dailySummaryReport.downloadReport': 'DESKARGATU TXOSTENA',
    'unitreport.timerangenotvalid': 'Denbora-tartea ez da baliozkoa',
    'unitListing.dataNotAvaialable': 'Daturik ez dago eskuragarri',
    'checklistTemplateTasks.noData': 'Daturik ez',
    'tabNavigation.dataTrendAnalysis': 'Datuen joeraren azterketa',
    'eventAction.datatrendAnalysis': 'Datuen joera azterketa',
    'generalGlobalSettings.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'checklistTemplate.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'checklist.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'modelComponentTypes.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'approvedComponents.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'modelComponentPositions.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'unitService.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'unitStatistics.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'telemetryParts.fetchFailed': 'Datuen eskuratzeak huts egin du',
    'generalGlobalSettings.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'checklistTemplate.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'checklist.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'unitService.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'unitStatistics.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'telemetryParts.fetchSuccessfull': 'Datuak arrakastaz eskuratu dira',
    'approvedComponents.dataSheetURL': 'Datu-orria URLa',
    'unitreport.returnedemptydata': 'Datu hutsak',
    'eventAction.dates': 'Datak',
    'tasksManagement.dates': 'Datak',
    'taskManagement.dates': 'Datak',
    'chart.datetimefrom': 'Data/Ordua noiztik',
    'chart.datetimeto': 'Data/Ordua arte',
    'unitServiceDetail.dateTime': 'Data eta ordua',
    'eventCodes.duplicateCode': 'Dagoeneko existitzen da kode berdina duen gertaera-kode bat',
    'modelVersionGlobalSettings.duplicateName': 'Dagoeneko existitzen da izen berbera duen ezarpen global bat',
    'modelVersionThreshold.duplicateName': 'Dagoeneko existitzen da ezarpen berbera duen atalase bat',
    'model.duplicateName': 'Dagoeneko badago eredu izen bat',
    'modelComponentPositions.ccp': 'CCP',
    'calibration.finalize': 'BUKATU',
    'globalSettings.ballvalvedelay': 'Bola-balbularen itxiera itxaroteko segundu kopurua',
    'unitListing.pendingAlarms': 'Bitartean dauden alarmak',
    'forgotpassword.resetMyPassord': 'BIRZAHAR EZAZU NIRE PASAHITZA',
    'organisationManagement.searchInfo': 'Bilatu unitateak unitatearen izenaren arabera',
    'eventCodes.searchLabelCode': 'Bilatu Kodea',
    'eventCodes.searchLabelName': 'Bilatu Izena',
    'common.searchBy': 'Bilatu Honekin',
    'common.search': 'Bilatu',
    'profile.search': 'Bilatu',
    'organisationManagement.userSearch': 'Bilatu',
    'modelVersion.search': 'Bilatu',
    'globalSettingsManagement.search': 'Bilatu',
    'alarms.search': 'Bilatu',
    'usermanagement.search': 'Bilatu',
    'checklistTemplateManagement.Search': 'Bilatu',
    'checklistTemplateTasks.search': 'Bilatu',
    'tasksManagement.search': 'Bilatu',
    'eventCodes.search': 'Bilatu',
    'componentParts.search': 'Bilatu',
    'modelComponentTypes.search': 'Bilatu',
    'model.componentpositions.search': 'Bilatu',
    'approvedComponent.search': 'Bilatu',
    'modelVersion.units.search': 'Bilatu',
    'modelComponentPositions.search': 'Bilatu',
    'maintenance.telemetryParts.search': 'Bilatu',
    'dailySummaryReport.timerangenotvalid': 'Bilaketa denbora-tartea ez da baliozkoa',
    'taskTemplate.duplicateName': 'Bikoiztutako zereginen txantiloiaren izena',
    'task.duplicateName': 'Bikoiztutako Zereginaren Izena',
    'checklistTemplate.duplicateName': 'Bikoiztutako txantiloiaren izena',
    'checklist.duplicateName': 'Bikoiztutako txantiloiaren izena',
    'componentParts.duplicateSerialNumber': 'Bikoiztutako Serie Zenbakia',
    'model.duplicateGobalSettings': 'Bikoiztu Ezarpen Globalak',
    'model.duplicateOptions': 'Bikoiztu aukerak',
    'model.duplicateThresholds': 'Bikoiztu Atalaseak',
    'modelVersion.duplicate': 'Bikoiztu',
    'modelVersion.duplicateModelVersion': 'Bikoiztu',
    'globalSettingsManagement.duplicate': 'Bikoiztu',
    'model.duplicate': 'Bikoiztu',
    'model.duplicateModel': 'Bikoiztu',
    'globalSettings.flushsecondmembraneffvolume': 'Bigarren Membranaren FF Bolumena Garbitu',
    'forgotpassword.resendCode': 'BIDALI KODEA BERRIRO',
    'mFA.resendCode': 'BIDALI KODEA BERRIRO',
    'modelVersion.nameMandatory': 'Bertsioaren izena derrigorrezko eremua da',
    'modelVersionManagement.versionName': 'Bertsioaren Izena',
    'modelVersionManagement.description': 'Bertsioaren Deskribapena',
    'model.versions': 'Bertsioak',
    'model.modelversion': 'Bertsioak',
    'modelVersionManagement.active': 'Bertsioa aktiboa da',
    'modelVersion.version': 'Bertsioa',
    'modelVersion.duplicateName': 'Bertsio izen bat dagoeneko existitzen da eredu honentzat',
    'accountSettings.resetCache': 'BERREZARRI KAXA',
    'unitServiceDetail.warrantyHours': 'Berme Orduak',
    'partService.warrantyHours': 'Berme Orduak',
    'unitServiceDetail.warrantyCost': 'Berme Kostua',
    'partService.warrantyCost': 'Berme Kostua',
    'unit.warrantyStartDate': 'Berme hasiera data',
    'unit.warrantyError': 'Berme amaiera data hasiera data baino handiagoa izan behar da',
    'unit.warrantyEndDate': 'Berme amaiera data',
    'sensorTable.realTimeSensor': 'Benetako denbora sensorea',
    'tasksManagement.actualEffortMandatory': 'Benetako ahalegina beharrezkoa da',
    'tasksManagement.actualEffort': 'Benetako ahalegin',
    'chart.lowerthreshold': 'Beheko Atalasea',
    'taskTemplate.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'task.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'scheduler.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'telemetryLinks.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'dailySummaryReport.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'unitStatistics.requiredParamsMissing': 'Beharrezko parametroak falta dira',
    'common.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'generalGlobalSettings.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'unitreport.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'modelVersionShadow.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'eventAction.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'checklistTemplate.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'checklist.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'componentParts.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'modelComponentTypes.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'approvedComponents.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'modelComponentPositions.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'maintenance.tagout.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'unitService.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'telemetryParts.requiredParamsMissing': 'Beharrezko parametroa falta da',
    'modelVersionServicePlan.requiredParamsMissing': 'Beharrezko Parametroa falta da',
    'eventAction.commentsRequired': 'Beharrezko iruzkina',
    'modelVersionThreshold.requiredParamsMissing': 'Beharrezko datuak falta dira',
    'user.requiredParamsMissing': 'Beharrezko datuak falta dira',
    'calibration.low': 'Baxua',
    'modelVersion.thresholdLow': 'Baxua',
    'thresholdTable.low': 'Baxua',
    'datatrend.analysis.average': 'Batez bestekoa',
    'command.none': 'Bat ere ez',
    'unitListing.none': 'Bat ere ez',
    'maintenance.tagoutHistory.inDateTime': 'Barruan Data Ordua',
    'forgotpassword.codeVerificationFailed': 'Balioztapen kodea huts egin du. Mesedez, jarri harremanetan zure administratzailearekin',
    'forgotpassword.codeVerificationCodeSendFailed': 'Balioztapen kodea ezin izan da bidali. Mesedez, saiatu berriro geroago',
    'forgotpassword.verificationSent': 'Balioztapen kodea bidali da',
    'mfa.verificationSentEnterCode': 'Balioztapen kode bat zure mugikorrera bidali da',
    'forgotpassword.invalidConfirmationCode': 'Balioztapen kode baliogabea',
    'forgotpassword.codeRequirement': 'Balioztapen kode baliogabea',
    'mFa.codeRequirement': 'Balioztapen kode baliogabea',
    'modelVersion.servicePlanStartDateRequired': 'Baliozko hasiera data beharrezkoa da',
    'datatrend.analysis.invalidLimit': 'Baliozko ez diren puntu plotak! Tartea 1 eta 10000 artean egon behar da.',
    'eventAction.invalidEventCode': 'Baliozko ez den gertaera-kodea',
    'unitreport.invalidDateRange': 'Baliozko ez den data-tartea! Tartea 90 egun baino gutxiagoa izan behar da',
    'datatrend.analysis.invalidTimestampRange': 'Baliozko ez den data-tartea! Tartea 24 ordu edo gutxiago izan behar da.',
    'modelVersionManagement.ValueMustBeWithinRange': 'Balioa gutxienezko eta gehienezko tartean egon behar da',
    'modelVersionManagement.ValueRequired': 'Balioa derrigorrezko eremua da',
    'calibration.calibrationConstantsValue': 'Balioa',
    'modelVersion.value': 'Balioa',
    'thresholdTable.value': 'Balioa',
    'componentParts.telemetryValue': 'Balioa',
    'maintenance.telemetryParts.value': 'Balioa',
    'globalSettings.closevalvedelaytimer': 'Balbula erabat itxita dagoela ziurtatzeko itxaroteko segundu kopurua',
    'globalSettings.standalonemembraneffvolume': 'Bakarkako Membranaren FF Bolumena',
    'passwordUpdate.confirmPassword': 'Baieztatu pasahitza',
    'general.ConfirmPassword': 'Baieztatu pasahitza',
    'maintenance.tagout.confirm': 'BAIEZTATU',
    'mFa.confirm': 'Baieztatu',
    'common.yes': 'Bai',
    'calibration.yes': 'Bai',
    'calibration.calibrationRequired.yes': 'Bai',
    'componentParts.yes': 'Bai',
    'approvedComponents.yes': 'Bai',
    'dashboard.lastLoggedIn': 'Azken saioa hemen:',
    'unitStatistics.lastUpdated': 'Azken eguneraketa',
    'deviceList.warnings': 'Azken 24 orduko abisuak',
    'deviceTile.warnings': 'Azken 24 orduko abisuak',
    'unitListing.warnings': 'Azken 24 orduetako oharrak',
    'unitStatistics.last24Hours': 'Azken 24 orduak',
    'unit.suburbLocationException': 'Auzoa/Kokapena derrigorrezko eremua da',
    'modelVersion.units.installationSuburbLocation': 'Auzoa/Kokapena',
    'unit.installationSuburbLocation': 'Auzoa/Kokapena',
    'login.expiredCodeException': 'Autentifikazio kodea iraungi da, Mesedez, saiatu berriro saioa hasten',
    'login.errorCodeMismatchException': 'Autentifikazio kodea ezin izan da balioztatu, Mesedez, saiatu berriro',
    'layout.tagline': 'Australian Water Source-n sinesten dugu edateko ur garbian...',
    'checklistDetail.progress': 'Aurrerapena',
    'command.membraneforwardflush': 'Aurrerapen flusha',
    'chart.prevNext': 'Aurrekoa/Hurrengoa',
    'chart.prevNextDay': 'Aurreko/Hurrengo Eguna',
    'unitStatistics.yesterday': 'Atzo',
    'command.membranebackflush': 'Atzerako flusha',
    'calibration.backToCalibrations': 'ATZERA',
    'checklistTemplateManagement.back': 'ATZERA',
    'taskTemplateManagement.back': 'ATZERA',
    'checklistDetail.back': 'ATZERA',
    'eventCodes.back': 'Atzera',
    'componentParts.back': 'ATZERA',
    'schedule.back': 'ATZERA',
    'modelComponentTypes.back': 'ATZERA',
    'approvedComponents.back': 'ATZERA',
    'unitServiceDetail.back': 'ATZERA',
    'modelVersionThreshold.creationFailed': 'Atalasearen sorrerak huts egin du',
    'modelVersionThreshold.deletetionFailed': 'Atalasearen ezabaketak huts egin du',
    'modelVersionThreshold.updationFailed': 'Atalasearen eguneraketak huts egin du',
    'modelVersionThreshold.fetchFailed': 'Atalasearen datuak eskuratzeak huts egin du',
    'tabNavigation.thresholds': 'Atalaseak',
    'sequenceThresholds.thresholds': 'ATALASEAK',
    'modelVersion.threasholds': 'Atalaseak',
    'modelVersionThreshold.createdSuccessfully': 'Atalasea arrakastaz sortu da',
    'modelVersionThreshold.deletedSuccessfully': 'Atalasea arrakastaz ezabatu da',
    'modelVersionThreshold.fetchSuccessfull': 'Atalasea arrakastaz eskuratu da',
    'modelVersionThreshold.updatedSuccessfully': 'Atalasea arrakastaz eguneratu da',
    'eventCodes.threshold': 'Atalasea',
    'sequenceThresholds.allThresholds': 'Atalase guztiak',
    'unitStatistics.weekToDate': 'Astea gaur arte',
    'maintenance.tagout.reasonIsRequired': 'Arrazoia beharrezkoa da',
    'unitupload.dragndrop': 'Arrastatu eta jaregin fitxategia hemen',
    'unitServiceDetail.nonWarrantyHours': 'arranty Hours - Ez-Berme Orduak',
    'partService.nonWarrantyHours': 'arranty Hours - Ez-Berme Orduak',
    'unitServiceDetail.nonWarrantyCost': 'arranty Cost - Ez-Berme Kostua',
    'partService.nonWarrantyCost': 'arranty Cost - Ez-Berme Kostua',
    'modelComponentTypes.deletionSuccessful': 'Arrakastaz ezabatuta',
    'approvedComponents.deletionSuccessful': 'Arrakastaz ezabatuta',
    'modelComponentPositions.deletionSuccessfull': 'Arrakastaz ezabatuta',
    'unitupload.browse': 'ARBELATU FITXATEGIA',
    'schedule.by': 'Arabera',
    'settings.appwidgets': 'Aplikazioen widgetak',
    'settings.applicationusers': 'Aplikazio erabiltzaileak',
    'modelVersionServicePlan.applyScheduleModelVersionThreshold': 'APLIKATU EGUTEGIAK',
    'unitListing.sortBySerialNumber': 'Antolatu: Serie Zenbakia',
    'deviceList.sortByName': 'Antolatu: Izena',
    'deviceList.sortByStatus': 'Antolatu: Egoera',
    'unitListing.sortByName': 'Antolatu : Izena',
    'sidebar.logout': 'Amaitu saioa',
    'schedule.end': 'Amaitu',
    'schedule.finalize': 'Amaitu',
    'unitServiceDetail.finalize': 'Amaitu',
    'schedule.pastEndTime': 'Amaiera Ordua hurrengo 15 minututik hauta daiteke',
    'schedule.endDateLimit': 'Amaiera Data hasiera datatik 12 aste barru bakarrik hauta daiteke',
    'schedule.endDateGreater': 'Amaiera data hasiera data baino handiagoa izan behar da',
    'schedule.endDateMandatory': 'Amaiera Data derrigorrezkoa da',
    'schedule.endDate': 'Amaiera Data',
    'calibration.high': 'Altua',
    'modelVersion.thresholdHigh': 'Altua',
    'thresholdTable.high': 'Altua',
    'notifications.numberOfAlerts': 'Alerta kopurua duzu. Mesedez, egiaztatu zure oharren taula.',
    'model.dateModified': 'Aldatze data',
    'deviceHeader.switchUnitTimezone': 'Aldatu unitatearen ordu-eremura',
    'deviceHeader.switchLocalTimezone': 'Aldatu tokiko ordu-eremura',
    'sidebar.switchProfiles': 'Aldatu profilak',
    'toggleSwitch.lableView': 'Aldatu etiketa ikuspegira',
    'sidebar.switchOrganisations': 'Aldatu erakundeak',
    'common.change': 'ALDATU',
    'calibrationEquation.change': 'ALDATU',
    'calibrationEquation.constant.change': 'ALDATU',
    'calibration.changeCalibration': 'Aldatu',
    'modelVersion.change': 'Aldatu',
    'globalSettingsManagement.change': 'Aldatu',
    'checklistTemplateManagement.change': 'Aldatu',
    'taskTemplateManagement.change': 'Aldatu',
    'checklistDetail.change': 'Aldatu',
    'componentParts.change': 'Aldatu',
    'modelComponentTypes.change': 'ALDATU',
    'model.telemetryLinks.change': 'Aldatu',
    'approvedComponents.change': 'ALDATU',
    'modelComponentPositions.change': 'Aldatu',
    'modelVersion.dateModified': 'Aldaketa data',
    'alarms.requiredParamsMissing': 'Alarmetarako beharrezko datuak falta dira',
    'alarms.filter.fetchFailed': 'Alarmen iragazkiaren eskuratzeak huts egin du',
    'alarms.filter.fetchSuccessfull': 'Alarmen iragazkiaren eskuratzea arrakastatsua izan da',
    'alarms.filter.requiredParamsMissing': 'Alarmen iragazkiarako beharrezko datuak falta dira',
    'alarms.fetchFailed': 'Alarmen eskuratzeak huts egin du',
    'deviceList.newAlarms': 'Alarmak berriak',
    'deviceTile.newAlarms': 'Alarmak berriak',
    'alarms.fetchSuccessfull': 'Alarmak arrakastaz eskuratu dira',
    'alarms.alarms': 'Alarmak',
    'alarms.alarmSearchName': 'Alarma izena',
    'unitListing.newAlarms': 'Alarma berriak',
    'contentTemplate.newAlarm': 'Alarma berria',
    'command.alarm': 'Alarma',
    'eventAction.alarm': 'Alarma',
    'calibration.postCalResults': 'al Results - Ondorengo-Kal Emaitzak',
    'calibration.preCalResults': 'al Results - Aurre-Kal Emaitzak',
    'calibration.postCalError': 'al Error - Ondorengo-Kal Errorea',
    'calibration.preCalError': 'al Error - Aurre-Kal Errorea',
    'componentParts.isActive': 'Aktiboa da',
    'globalSettingsManagement.active': 'Aktiboa',
    'checklistTask.showActive': 'Aktiboa',
    'tasksManagement.active': 'Aktiboa',
    'componentParts.active': 'Aktiboa',
    'model.componentpositions.active': 'Aktiboa',
    'approvedComponents.active': 'Aktiboa',
    'modelComponentPositions.active': 'Aktiboa',
    'profile.profileActive': 'Aktibo',
    'organisation.active': 'Aktibo',
    'componentParts.isFaulty': 'Akastuna da',
    'componentParts.faulty': 'Akastuna',
    'globalSettings.airscourflushfirstmembranepercentage': 'Airearen Xukadura Lehen Membranaren Ehunekoa',
    'globalSettings.airscourflushsecondmembranepercentage': 'Aire Garbiketaren Lehen Membranaren Ehunekoa',
    'globalSettings.airscourstandalonepercentage': 'Aire Garbiketaren Bakarkako Ehunekoa',
    'forgotpassword.forgotyourpassword': 'Ahaztu duzu zure pasahitza?',
    'forgotpassword.iForgotMyPassword': 'AHAZTU DUT NIRE PASAHITZA',
    'taskTemplateManagement.effort': 'Ahalegin',
    'AdminControls.adminControls': 'Administrazio-kontrolak',
    'sidebar.adminControls': 'Administrazio kontroleak',
    'unitreports.messageDownloadSize': '48 ordu baino gehiagoko datuekin txostenak sortzeko denbora gehiago beharko da',
    'globalSettings.staticerrortank2': '2. Tangaren Errore Estaticoa',
    'usermanagement.selectunits': '2. Hautatu unitate bat edo gehiago',
    'notifications.selectUser': '2. Hautatu erabiltzaile bat edo gehiago profilarentzat',
    'sensorTable.lt02': '2. edukiontziaren analogiko maila transmititzailea',
    'globalSettings.staticerrortank1': '1. Tangaren Errore Estaticoa',
    'usermanagement.selectprofilethisuser': '1. Hautatu profil bat edo gehiago erabiltzaile honentzat',
    'notifications.selectProfile': '1. Hautatu profil bat',
    'sensorTable.lt01': '1. edukiontziaren analogiko maila transmititzailea',
    'sensorTable.lsl01': '1. edukiontzi, maila baxuko etengailua',
    'pagination.next': '>',
    'unitupload.back': '< ITZULI UNITATEETARA',
    'profile.back': '< ITZULI PROFILAKETARA',
    'pagination.prev': '<',
    'calibrationEquation.constant.addNew': '+ GEHITU BERRIA',
    'calibration.addResults': '+ GEHITU BERRIA',
    'unitreports.notes': '* Telemetria txostenaren datuak UTC data ordutan bakarrik daude eskuragarri',
    '': '(hutsik)',
    'pagination.of': '-ren',
    'deviceHeader.back': '',
    'sensorTable.tk01': '',
    'sensorTable.tk02': '',
    'checklist.checklistUpdateAndServicePlanChecklistScheduled': '',
    'Checklist has been updated and Service plan checklist scheduled successfully': '',
    'checklistTemplateManagement.serviceChecklistTemplateIsInUse': '',
    'Checklist template used in model version service plan must be service checklist template.': '',
    'checklistTemplateManagement.confirmServiceTask': '',
    'Tasks attached to the checklist will be converted to service tasks. Do you want to continue?': '',
    'checklistTemplateManagement.confirmUnitTask': '',
    'forgotpassword.verificatiionEmailSentEnterCode': '',
    'A verification code has been sent to your inbox. Please enter the verification code below.': '',
    'login.forgotPasswordSuccess': '',
    'Your password has been successfully reset. You can now use your new password to log in to your account.': '',
    'unitStatistics.fetchFailed': '',
    'modelVersionServicePlan.confirmApplySchedule': '',
    'Do you wish to create the service schedules to all units for this version from the starting date?': '',
    null: ''
  }
};

export default languageObject;
